<template>
    <b-container fluid>
        <b-row>
            <b-col cols="2">
                <router-link to="/dashboard/domains" style="text-decoration: none;">
                    <h6 style="margin-top: 20px;"><i class="fas fa-chevron-left"></i> {{ Lang.dashboard.domains.domains }}</h6>
                </router-link>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4">
                <h2>
                    {{ domain.domain.domain }}
                </h2>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p>
                    {{ domain.domain._id }}
                </p>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col>
                <b-tabs>
                    <b-tab v-bind:title="Lang.dashboard.domains.proxy">
                        <br>
                        <p>
                            <b>How to setup:</b><br>
                            1. Create a CNAME DNS record pointing towards <b>reverseproxy.stacket.net</b><br>
                            2. Add your subdomain below and select which service you want to target.<br>
                            Done!<br>
                            <span style="font-style: italic;">
                                Note: Currently it only supports the default service port, but we will add support for port selection later!
                            </span>
                        </p>
                        <b-row style="margin-bottom: 10px;">
                            <b-col style="text-align: left;">
                                <b>
                                    Subdomain(s)
                                </b>
                            </b-col>
                            <b-col style="text-align: right;">
                                <b>
                                    Service
                                </b>
                            </b-col>
                            <b-col cols="2"></b-col>
                        </b-row>
                        <b-list-group style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; margin-bottom: 25px;" v-if="domain.domain.proxypass.length != 0">
                            <b-list-group-item v-for="(proxy, index) of domain.domain.proxypass" v-bind:key="index">
                                <b-row align-v="center">
                                    <b-col style="text-align: left;">
                                        <span style="font-weight: 500;">{{ proxy.subdomain }}</span>.{{ domain.domain.domain }}
                                    </b-col>
                                    <b-col style="text-align: right;">
                                        {{ services.find(s => s._id.toString() == proxy.service).name }} ({{ proxy.service }})
                                    </b-col>
                                    <b-col cols="1" style="text-align: right;">
                                        <b-button size="sm" variant="danger" @click="removeProxyPass(proxy.subdomain)">Delete</b-button>
                                    </b-col>
                                </b-row>                  
                            </b-list-group-item>
                        </b-list-group>
                        <b-row v-else style="margin-bottom: 25px;">
                            <b-col>
                                None added
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <h5>
                                    Add subdomain:
                                </h5>
                            </b-col>
                        </b-row>
                        <b-row style="margin-bottom: 10px;">
                            <b-col cols="4">
                                <b>
                                    Subdomain
                                </b>
                            </b-col>
                            <b-col>
                                <b>
                                    Service
                                </b>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b-input-group size="md" v-bind:append="'.' + domain.domain.domain">
                                    <b-form-input v-model="newPass.subdomain"></b-form-input>
                                </b-input-group>
                            </b-col>
                            <b-col>
                                <b-form-select style="width: 80%; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;" v-model="newPass.service">
                                    <template v-slot:first>
                                        <b-form-select-option :value="''" disabled>{{ Lang.dashboard.networks.selectService }}</b-form-select-option>
                                    </template>
                                    <b-form-select-option v-for="service in services" v-bind:key="service._id" :value="service._id">
                                        {{ service.name }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-col>
                            <b-col cols="1" style="text-align: right;">
                                <b-button variant="primary" @click="addProxyPass()">Add</b-button>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab v-bind:title="Lang.dashboard.domains.dns">
                        <b-alert variant="danger" style="margin-top: 25px;" show v-if="domain.domain.registered == false">
                            {{ Lang.dashboard.domains.dnsnotavailable }}
                        </b-alert>
                        <span v-else>
                            <br>
                            <span v-if="dns.editing == false">
                                <b-row>
                                    <b-col>
                                        <h5>
                                            Add DNS record:
                                        </h5>
                                    </b-col>
                                </b-row>
                                <b-row style="margin-bottom: 10px;">
                                    <b-col cols="2">
                                        <b>
                                            Type
                                        </b>
                                    </b-col>
                                    <b-col cols="3">
                                        <b>
                                            Name
                                        </b>
                                    </b-col>
                                    <b-col cols="1">
                                        <b>
                                            TTL
                                        </b>
                                    </b-col>
                                    <b-col cols="1">
                                        <b>
                                            Proxied
                                        </b>
                                    </b-col>
                                    <b-col>
                                        <b>
                                            Content
                                        </b>
                                    </b-col>
                                    <b-col cols="1"></b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="2">
                                        <b-form-select style="width: 100%; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;" v-model="newDNSRecord.type">
                                            <template v-slot:first>
                                                <b-form-select-option :value="''" disabled>Select a DNS type</b-form-select-option>
                                            </template>
                                            <b-form-select-option v-for="type in dns.types" v-bind:key="type" :value="type">
                                                {{ type }}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </b-col>
                                    <b-col cols="3">
                                        <b-input-group size="md" v-bind:append="'.' + domain.domain.domain">
                                            <b-form-input v-model="newDNSRecord.name"></b-form-input>
                                        </b-input-group>
                                    </b-col>
                                    <b-col cols="1">
                                        <b-input-group size="md">
                                            <b-form-input v-model="newDNSRecord.ttl"></b-form-input>
                                        </b-input-group>
                                    </b-col>
                                    <b-col cols="1">
                                        <b-form-select size="md" v-model="newDNSRecord.proxied" :options="dns.proxied"></b-form-select>
                                    </b-col>
                                    <b-col>
                                        <b-input-group size="md">
                                            <b-form-input v-model="newDNSRecord.content"></b-form-input>
                                        </b-input-group>
                                    </b-col>
                                    <b-col cols="1" style="text-align: right;">
                                        <b-button variant="primary" @click="addDNSRecord()">Add</b-button>
                                    </b-col>
                                </b-row>
                                <br>
                                <b-row style="margin-bottom: 10px;">
                                    <b-col cols="1" style="text-align: left;">
                                        <b>
                                            Type
                                        </b>
                                    </b-col>
                                    <b-col style="text-align: left;">
                                        <b>
                                            Name
                                        </b>
                                    </b-col>
                                    <b-col style="text-align: left;">
                                        <b>
                                            Priority / TTL
                                        </b>
                                    </b-col>
                                    <b-col style="text-align: left;">
                                        <b>
                                            Proxied
                                        </b>
                                    </b-col>
                                    <b-col cols="3" style="text-align: right;">
                                        <b>
                                            Content
                                        </b>
                                    </b-col>
                                    <b-col cols="2"></b-col>
                                </b-row>
                                <b-list-group style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; margin-bottom: 25px;" v-if="dns_records.length != 0">
                                    <b-list-group-item v-for="(record, index) of dns_records" v-bind:key="index">
                                        <b-row align-v="center">
                                            <b-col cols="1" style="text-align: left;">
                                                <span style="font-weight: 500;"><strong>{{ record.type }}</strong></span>
                                            </b-col>
                                            <b-col style="text-align: left;">
                                                <span style="font-weight: 500;">{{ record.name }}</span>
                                            </b-col>
                                            <b-col style="text-align: left;">
                                                <span style="font-weight: 500;"><b-badge variant="primary" v-if="record.priority != null">{{ record.priority }}</b-badge><b-badge variant="primary" v-else>0</b-badge> <strong>/</strong> <b-badge variant="success">{{ record.ttl }}</b-badge></span>
                                            </b-col>
                                            <b-col style="text-align: left;">
                                                <span style="font-weight: 500;"><b-badge variant="danger" v-if="record.proxied == false || record.proxied == null">False</b-badge><b-badge variant="success" v-else>True</b-badge></span>
                                            </b-col>
                                            <b-col cols="3" style="text-align: right;">
                                                <span style="font-weight: 500;">{{ truncateText(record.content.toString(), 30) }}</span>
                                            </b-col>
                                            <b-col cols="2" style="text-align: right;">
                                                <p>
                                                <b-button size="sm" variant="warning" @click="editDNSRecord(record.id)">Edit</b-button> <b-button size="sm" variant="danger" @click="removeDNSRecord(record.id)">Delete</b-button>
                                                </p>
                                            </b-col>
                                        </b-row>                  
                                    </b-list-group-item>
                                </b-list-group>
                                <b-row v-else style="margin-bottom: 25px;">
                                    <b-col>
                                        None added
                                    </b-col>
                                </b-row>
                            </span>
                            <span v-else>
                                <b-row>
                                    <b-col>
                                        <h5>
                                            Edit DNS record:
                                        </h5>
                                        <p>
                                            {{ dns.currentEdit.id }}
                                        </p>
                                    </b-col>
                                </b-row>
                                <b-row style="margin-bottom: 10px;">
                                    <b-col cols="2">
                                        <b>
                                            Type
                                        </b>
                                    </b-col>
                                    <b-col cols="3">
                                        <b>
                                            Name
                                        </b>
                                    </b-col>
                                    <b-col>
                                        <b>
                                            TTL
                                        </b>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="2">
                                        <b-form-select style="width: 100%; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;" v-model="dns.currentEdit.type">
                                            <template v-slot:first>
                                                <b-form-select-option :value="''" disabled>Select a DNS type</b-form-select-option>
                                            </template>
                                            <b-form-select-option v-for="type in dns.types" v-bind:key="type" :value="type">
                                                {{ type }}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </b-col>
                                    <b-col cols="3">
                                        <b-input-group size="md" v-bind:append="'.' + domain.domain.domain">
                                            <b-form-input v-model="dns.currentEdit.name"></b-form-input>
                                        </b-input-group>
                                    </b-col>
                                    <b-col cols="1">
                                        <b-input-group size="md">
                                            <b-form-input v-model="dns.currentEdit.ttl"></b-form-input>
                                        </b-input-group>
                                    </b-col>
                                </b-row>
                                <br>
                                <b-row style="margin-bottom: 10px;">
                                    <b-col cols="1">
                                        <b>
                                            Proxied
                                        </b>
                                    </b-col>
                                    <b-col>
                                        <b>
                                            Content
                                        </b>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="1">
                                        <b-form-select size="md" v-model="dns.currentEdit.proxied" :options="dns.proxied"></b-form-select>
                                    </b-col>
                                    <b-col cols="5">
                                        <b-input-group size="md">
                                            <b-form-input v-model="dns.currentEdit.content"></b-form-input>
                                        </b-input-group>
                                    </b-col>
                                </b-row>
                                <br>
                                <b-row>
                                    <b-col style="text-align: left;">
                                        <b-button size="sm" variant="info" @click="cancelEdit()">Cancel</b-button>
                                    </b-col>
                                    <b-col style="text-align: right;">
                                        <b-button size="sm" variant="success" @click="saveDNSRecord()">Save</b-button>
                                    </b-col>
                                    <b-col cols="6"></b-col>
                                </b-row>
                            </span>
                            <br>
                        </span>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
    export default {
        name: "domain",
        components: {},
        data: () => {
            return {
                token: "",
                domain: {},
                dns_records: [],
                dns: {
                    editing: false,
                    currentEdit: {
                        id: "",
                        name: "",
                        type: "",
                        content: "",
                        ttl: "",
                        proxied: false
                    },
                    proxied: [
                        { value: false, text: 'False' },
                        { value: true, text: 'True' }
                    ],
                    types: [
                        'A',
                        'AAAA',
                        'CAA',
                        'CERT',
                        'CNAME',
                        'DNSKEY',
                        'DS',
                        'HTTPS',
                        'LOC',
                        'MX',
                        'NAPTR',
                        'NS',
                        'PTR',
                        'SMIMEA',
                        'SPF',
                        'SRV',
                        'SSHFP',
                        'SVCB',
                        'TLSA',
                        'TXT',
                        'URI'
                    ]
                },
                newPass: {
                    subdomain: "",
                    service: ""
                },
                newDNSRecord: {
                    name: "",
                    type: "",
                    content: "",
                    ttl: "1",
                    proxied: false
                }
            }
        },
        props: [
            "teams",
            "services"
        ],
        mounted() {
            this.token = localStorage.getItem("user-token");
            if(localStorage.getItem("user-token")){
                const token = localStorage.getItem("user-token");

                this.getDomain();
            }
        },
        methods: {
            getDomain() {
                this.$http.get(this.StacketConfig.api.domains + "/" + this.$route.params.page2, {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        })
                    }
                    this.domain = response.data;
                    if(this.domain.domain.verified == false){
                        this.$router.push("/dashboard/domains")
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Domain not verified!",
                            text: "The domain is not verified, come back when you have verified your domain!"
                        })
                    }
                    if(this.domain.domain.registered == true){
                        this.getDomainDNS();
                    }
                }).catch(err => {
                    if(err.data && err.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: err.data.error
                        });
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch domain!"
                    })
                });
            },
            getDomainDNS() {
                this.$http.get(this.StacketConfig.api.domains + "/" + this.$route.params.page2 + "/dns_records", {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error.message
                        })
                    }
                    this.dns_records = response.data;
                    if(this.domain.domain.verified == false){
                        this.$router.push("/dashboard/domains")
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Domain not verified!",
                            text: "The domain is not verified, come back when you have verified your domain!"
                        })
                    }
                }).catch(err => {
                    if(err.data && err.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: err.data.error.message
                        });
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch domain!"
                    })
                });
            },
            addProxyPass() {
                this.$http.post(this.StacketConfig.api.domains + "/" + this.$route.params.page2 + "/proxy", this.newPass, {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        })
                    }
                    this.getDomain();
                    this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Added",
                        text: response.data.message
                    })
                }).catch(err => {
                    if(err.data && err.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: err.data.error
                        });
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch domain!"
                    })
                });
            },
            removeProxyPass(subdomain) {
                this.$http.delete(this.StacketConfig.api.domains + "/" + this.$route.params.page2 + "/proxy/" + subdomain, {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        })
                    }
                    this.getDomain();
                    this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Deleted",
                        text: response.data.message
                    })
                }).catch(err => {
                    if(err.data && err.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: err.data.error
                        });
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch domain!"
                    })
                });
            },
            removeDNSRecord(identifier) {
                this.$http.delete(this.StacketConfig.api.domains + "/" + this.$route.params.page2 + "/dns_record/" + identifier, {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error.message
                        })
                    }
                    this.getDomainDNS();
                    this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Deleted",
                        text: response.data.message
                    })
                }).catch(err => {
                    if(err.data && err.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: err.data.error.message
                        });
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch domain!"
                    })
                });
            },
            addDNSRecord() {
                this.$http.post(this.StacketConfig.api.domains + "/" + this.$route.params.page2 + "/dns_record", this.newDNSRecord, {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error.message
                        })
                    }
                    this.getDomainDNS();
                    this.newDNSRecord = {
                        name: "",
                        type: "",
                        content: "",
                        ttl: "1",
                        proxied: false
                    };
                    this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Added",
                        text: response.data.message
                    })
                }).catch(err => {
                    console.log(err);
                    if(err.data && err.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: err.data.error.message
                        });
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch domain!"
                    })
                });
            },
            editDNSRecord(id) {
                var data = {};
                var i;
                for(i = 0;i < this.dns_records.length;i++) {
                    if (this.dns_records[i].id == id) {
                        this.dns.editing = true;
                        this.dns.currentEdit = {
                            id: id,
                            name: this.replaceDomain(this.dns_records[i].name),
                            type: this.dns_records[i].type,
                            content: this.dns_records[i].content,
                            ttl: this.dns_records[i].ttl,
                            proxied: this.dns_records[i].proxied
                        };
                        return;
                    }
                }
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "ERROR",
                    text: "Could not fetch that record!"
                });
            },
            saveDNSRecord() {
                this.$http.put(this.StacketConfig.api.domains + "/" + this.$route.params.page2 + "/dns_record/" + this.dns.currentEdit.id, this.dns.currentEdit, {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        console.log("OOGA BOOGA 2");
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        })
                    }
                    this.getDomainDNS();
                    this.dns.editing = false;
                    this.dns.currentEdit = {
                        id: "",
                        name: "",
                        type: "",
                        content: "",
                        ttl: "",
                        proxied: false
                    };
                    this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Saved",
                        text: "DNS record saved!"
                    })
                }).catch(err => {
                    if(err.data && err.data.error){
                        console.log("OOGA BOOGA");
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: err.data.error
                        });
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch domain!"
                    })
                });
            },
            cancelEdit() {
                if (this.dns.editing != true) return;
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Canceled",
                    text: "Canceled editing of DNS record."
                });
                this.dns.editing = false;
                this.dns.currentEdit = {
                    id: "",
                    name: "",
                    type: "",
                    content: "",
                    ttl: "",
                    proxied: false
                };
                this.getDomainDNS();
            },
            replaceDomain(str) {
                var s = str.replace(`${this.domain.domain.domain}`, "");
                var f = s.replace(".", "");
                if (f == "" || f == " ") return "@";
                return f;
            },
            truncateText(str, length) {
                if (length == null) length = 50;
                let ending = '...';
                if (str.length > length) return str.substring(0, length - ending.length) + ending;
                return str;
            }
        }
    };
</script>