<template>
    <b-container v-if="!this.$route.params.page3" fluid style="color: rgb(73, 80, 87); font-family: 'Roboto'; margin-top: -50px;">
        <b-row class="mt-5">
            <b-col>
                <h1 style="color: #4886FD;">{{ Lang.dashboard.service.subusers.title }}</h1>
            </b-col>
            <b-col cols="2" style="text-align: right;">
                <b-button @click="newSub()" variant="primary">{{ Lang.dashboard.service.subusers.new }}</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col><hr></b-col>
        </b-row>
        <b-row>
            <b-col cols="3" style="min-width: 280px;" v-for="(subuser, index) in subusers" v-bind:key="index">
                <b-card-group deck>
                    <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="shadow-xl serviceCard">
                        <b-card-body style="margin-bottom: -15px;">
                            <b-row style="margin-bottom: 20px;">
                                <b-col>
                                    <center>
                                        <img v-bind:src="subuser.image" style="width: 50px; border-radius: 100%;" v-b-tooltip.hover v-bind:title="subuser.fullname">
                                    </center>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <center>
                                        <h6>
                                            {{ subuser.fullname }}
                                        </h6>
                                    </center>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -10px;">
                            <b-row>
                                <b-col>
                                    <h6 style="text-align: middle; font-size: 13px">
                                        {{ subuser.email }}
                                    </h6>
                                </b-col>
                            </b-row>
                        </b-card-footer>
                        <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -20px;">
                            <b-row>
                                <b-col style="text-align: center;" @click="remove(subuser._id)">
                                    <i v-b-tooltip.hover v-bind:title="Lang.dashboard.service.subusers.remove" class="fas fa-unlink trashIcon" style="color:lightgray;"></i>
                                </b-col>
                                <b-col style="text-align: center;" @click="editSub(subuser._id)">
                                    <i v-b-tooltip.hover v-bind:title="Lang.dashboard.service.subusers.edit" class="fas fa-pencil-alt editIcon" style="color:lightgray;"></i>
                                </b-col>
                            </b-row>
                        </b-card-footer>
                    </b-card>
                </b-card-group>
            </b-col>
        </b-row>
    </b-container>
    <b-container v-else-if="this.$route.params.page3" fluid style="color: rgb(73, 80, 87); font-family: 'Roboto'; margin-top: -50px;">
        <b-row class="mt-5">
            <b-col>
                <h1 v-if="this.$route.params.page3 != 'new'" style="color: #4886FD;">{{ subusers.find(usr => usr._id == this.$route.params.page3).fullname }}</h1>
                <h1 v-else-if="this.$route.params.page3 == 'new'" style="color: #4886FD;">{{ Lang.dashboard.service.subusers.new }}</h1>
            </b-col>
            <b-col cols="2" style="text-align: right;">
                <b-button :to="'/dashboard/'+this.$route.params.page+'/subusers'" variant="danger">{{ Lang.dashboard.service.subusers.back }}</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col><hr></b-col>
        </b-row>
        <b-row>
            <b-col cols="5" v-b-tooltip.hover title="This can be found under account settings">
                <b-input-group size="lg" v-bind:prepend="Lang.dashboard.service.subusers.userId + ':'">
                    <b-form-input v-model="newUser"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col></b-col>
            <b-col cols="2" style="text-align: right;">
                <b-button v-if="this.$route.params.page3 != 'new'" variant="primary" @click="addSubuser()">{{ Lang.dashboard.service.subusers.update }}</b-button>
                <b-button v-else-if="this.$route.params.page3 == 'new'" variant="primary" @click="addSubuser()">{{ Lang.dashboard.service.subusers.add }}</b-button>
            </b-col>
        </b-row>
        <b-form-checkbox-group id="checkbox-group-2" v-model="newPermissions" name="flavour-2">
            <b-row>
                <b-col>
                    <b-row>
                        <b-col cols="1"></b-col>
                        <b-col class="mt-4" style="min-width: 275px!important;">
                            <h2>{{ Lang.dashboard.service.subusers.power }}</h2>
                            <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                                <b-row>
                                    <b-col cols="3" style="min-width: 300px;">
                        
                                        <b-form-checkbox value="service.power.start">{{ Lang.dashboard.service.subusers.start }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.power.stop">{{ Lang.dashboard.service.subusers.stop }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.power.kill">{{ Lang.dashboard.service.subusers.kill }}</b-form-checkbox>
                                        <br>
                                        <br>
                                        <b-form-checkbox value="service.power.activate">{{ Lang.dashboard.service.subusers.activate }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.power.hibernate">{{ Lang.dashboard.service.subusers.hibernate }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.power.reactivate">{{ Lang.dashboard.service.subusers.reactivate }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.console.update">{{ Lang.dashboard.service.subusers.update }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.console.npminstall">{{ Lang.dashboard.service.subusers.npminstall }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.console.command">{{ Lang.dashboard.service.subusers.commands }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.console.read">{{ Lang.dashboard.service.subusers.console }}</b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="1"></b-col>
                        <b-col class="mt-4" style="min-width: 275px!important;">
                            <h2>{{ Lang.dashboard.service.subusers.files }}</h2>
                            <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                                <b-row>
                                    <b-col cols="3" style="min-width: 300px;">
                                        <b-form-checkbox value="service.files.read">{{ Lang.dashboard.service.subusers.readFiles }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.files.write">{{ Lang.dashboard.service.subusers.writeFiles }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.files.download">{{ Lang.dashboard.service.subusers.downloadFiles }}</b-form-checkbox>
                                        <br>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="1"></b-col>
                        <b-col class="mt-4" style="height: 50%;">
                            <h2>{{ Lang.dashboard.service.subusers.ftp }}</h2>
                            <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px; height: 88%; max-height: 88%;">
                                <b-row>
                                    <b-col cols="3" style="min-width: 300px;">
                                        <b-form-checkbox value="service.ftp.read">{{ Lang.dashboard.service.subusers.ftpLogin }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.ftp.add">{{ Lang.dashboard.service.subusers.ftpAdd }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.ftp.remove">{{ Lang.dashboard.service.subusers.ftpRemove }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.ftp.edit">{{ Lang.dashboard.service.subusers.ftpEdit }}</b-form-checkbox>
                                        <br>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                </b-col>
                <b-col>
                    <b-row>
                        <b-col class="mt-4" style="min-width: 275px!important;">
                            <h2>{{ Lang.dashboard.service.subusers.service }}</h2>
                            <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                                <b-row>
                                    <b-col cols="3" style="min-width: 300px;">
                                        <b-form-checkbox value="service.usage">{{ Lang.dashboard.service.subusers.ressources }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.delete">{{ Lang.dashboard.service.subusers.delete }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.settings.version">{{ Lang.dashboard.service.subusers.changeVersion }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.settings.package">{{ Lang.dashboard.service.subusers.changePackage }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.settings.name">{{ Lang.dashboard.service.subusers.rename }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.settings.autorestart">{{ Lang.dashboard.service.subusers.restart }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.settings.firmware">{{ Lang.dashboard.service.subusers.firmware }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.settings.environment">{{ Lang.dashboard.service.subusers.environment }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.allocations.add">{{ Lang.dashboard.service.subusers.addAllocations }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.allocations.remove">{{ Lang.dashboard.service.subusers.removeAllocations }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.allocations.edit">{{ Lang.dashboard.service.subusers.editAllocations }}</b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                    <b-row>
                        <b-col class="mt-4" style="height: 50%;">
                            <h2>{{ Lang.dashboard.service.subusers.subusers }}</h2>
                            <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px; height: 88%; max-height: 88%;">
                                <b-row>
                                    <b-col cols="3" style="min-width: 300px;">
                                        <b-form-checkbox value="service.subusers.add">{{ Lang.dashboard.service.subusers.addUser }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.subusers.remove">{{ Lang.dashboard.service.subusers.removeUser }}</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.subusers.edit">{{ Lang.dashboard.service.subusers.editUser }}</b-form-checkbox>
                                        <br>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                    <b-row>
                        <b-col class="mt-4" style="height: 50%;">
                            <h2>{{ Lang.dashboard.service.subusers.danger }}</h2>
                            <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px; height: 88%; max-height: 88%;">
                                <b-row>
                                    <b-col style="min-width: 300px;">
                                        <b-form-checkbox value="*">{{ Lang.dashboard.service.subusers.fullControl }}</b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-form-checkbox-group>

    </b-container>
</template>

<style lang="scss">
    .editIcon {
        cursor: pointer;
        color: lightgray!important;
        transition: 0.15s;
    }
    .editIcon:hover {
        color: rgb(72, 134, 253)!important;
    }
</style>

<script>

export default {
    name: "Service Subusers",
    components: {
        
    },
    props: [
        "service"
    ],
    data: () => {
        return {
            token: "",
            subusers: [],
            newPermissions: [],
            newUser: "",
            state: "new"
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        this.getSubusers();
    },
    methods: {
        newSub(){
            this.state = "new";
            this.$router.push('/dashboard/'+this.$route.params.page+'/subusers/new');
            this.newPermissions = [
                //Power
                "service.power.start",
                "service.power.stop",
                "service.power.kill",
                "service.power.activate",
                "service.power.hibernate",
                "service.power.reactivate",
                //Console
                "service.console.update",
                "service.console.npminstall",
                "service.console.command",
                //Files:
                "service.files.read",
                "service.files.write",
                //FTP
                "service.ftp.read",
                //Service
                "service.usage",
                "service.settings.version",
                "service.settings.environment"
            ]
        },
        editSub(id){
            this.state = "edit";
            this.$router.push('/dashboard/'+this.$route.params.page+'/subusers/' + id);
            this.newUser = id;
            this.newPermissions = this.subusers.find(usr => usr._id.toString() == id.toString()).permissions;
        },
        addSubuser(){
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/subusers", { user: this.newUser, permissions: this.newPermissions}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                if(this.state == "new"){
                    this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Success",
                        text: "Subuser Added"
                    });
                } else if(this.state == "edit"){
                    this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Success",
                        text: "Subuser Edited"
                    });
                }
                this.$router.push('/dashboard/'+this.$route.params.page+'/subusers');
                this.getSubusers();
                try {
                    this.$parent.$parent.getServices();
                } catch(e){}
            }).catch(err => {
                if(this.state == "new"){
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not add subuser!"
                    })
                } else if(this.state == "edit"){
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not edit subuser!"
                    })
                }
            });
        },
        remove(id){
            this.$http.delete(this.StacketConfig.api.services + "/" + this.$route.params.page + "/subusers/" + id, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success",
                    text: "Subuser removed"
                });
                this.getSubusers();
                try {
                    this.$parent.$parent.getServices();
                } catch(e){}
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not remove subusers!"
                })
            });
        },
        getSubusers() {
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page + "/subusers", {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.subusers = response.data;
                console.log(this.subusers)
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not receive subusers!"
                })
            });
        }
    }
}  
</script>