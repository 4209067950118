<template>
    <div style="height: 100%; background-color: #f6f7fb;">
        <b-container fluid style="height: 100%;">
            <frontPageHeader></frontPageHeader>
            <b-row class="justify-content-center" style="background-image: linear-gradient(#026DC9, #5AADFC); min-height: 500px; padding-top: 100px; color: white;">
                <b-col cols="5" class="mt-5">
                    <h1 class="textShadow"><strong>
                        {{ Lang.frontdisks.title }}
                    </strong></h1>
                    <h5 style="margin-top: 15px;" class="textShadow">
                        {{ Lang.frontdisks.subtitle }}
                    </h5>
                    <b-button class="mt-5 shadow-md" :to="(isLoggedIn == true) ? '/dashboard' : '/register'" variant="light" style="width: 30%; line-height: 43px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                        {{ Lang.frontdisks.getStartedButton }}
                    </b-button>
                </b-col>
                <b-col cols="3" class="mt-4">
                    <img src="@/assets/images/disks.png" height=300px width=591px style="border-radius: 3px;" v-b-tooltip.hover title="Private Nodes" class="shadow-xl">
                </b-col>
            </b-row>
            <b-row class="justify-content-center" style="margin-top: 50px;">
                <h1 class="mt-5">{{ Lang.frontdisks.features.title }}</h1>
            </b-row>
            <b-row class="mt-5 mb-5">
                <b-col col="2"></b-col>
                <b-col style="text-align: center;">
                    <h3 style="color: #394047;"><i class="fas fa-folder" style="color: #3592E6;"></i> {{ Lang.frontdisks.features.ftp.title }}</h3>
                    <p style="font-size: 1.25rem;">{{ Lang.frontdisks.features.ftp.desc }}</p>
                </b-col>
                <b-col style="text-align: center;">
                    <h3 style="color: #394047;"><i class="fas fa-cubes" style="color: #3592E6;"></i> {{ Lang.frontdisks.features.scaling.title }}</h3>
                    <p style="font-size: 1.25rem;">{{ Lang.frontdisks.features.scaling.desc }}</p>
                </b-col>
                <b-col style="text-align: center;">
                    <h3 style="color: #394047;"><i class="fas fa-stream" style="color: #3592E6;"></i> {{ Lang.frontdisks.features.easy.title }}</h3>
                    <p style="font-size: 1.25rem;">{{ Lang.frontdisks.features.easy.desc }}</p>
                </b-col>
                <b-col col="2"></b-col>
            </b-row>
            <b-row style="background-color: #e8ecfa; padding-bottom: 25px;" class="justify-content-center">
                <b-col cols="10">
                    <b-row class="justify-content-center">
                        <h2 class="mt-5">{{ Lang.frontdisks.pricing.title }}</h2>
                    </b-row>
                    <b-row class="justify-content-center mt-3" style="margin-left: 5%; margin-right: 5%; padding-top: 25px;">
                        <b-col style="min-width: 220px; margin-right: 12.5px; margin-left: 12.5px" cols="10">
                            <b-input-group  prepend="5 GB" append="1000 GB">
                                <b-form-input type="range" min="5" step="5" max="1000" v-model="gb"></b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center">
                        <h2 class="mt-5">{{ Lang.frontdisks.pricing.size }}: {{ gb }}gb</h2>
                    </b-row>
                    <b-row class="justify-content-center">
                        <h4 class="mt-5" style="margin-top: 0px!important;">$ {{ getPrice(gb).toFixed(2) }} <span style="font-size: 15px;">/mo</span></h4>
                    </b-row>
                </b-col>
            </b-row>
            <frontPageFooter style="margin-top: 100px;"></frontPageFooter>
        </b-container>
    </div>
</template>

<script>
import frontPageHeader from "@/pages/frontpage/header.vue";
import frontPageFooter from "@/pages/frontpage/footer.vue";
export default {
    name: "Disks",
    components: {
        frontPageFooter,
        frontPageHeader,
    },
    data: () => {
        return {
            isLoggedIn: true,
            gb: 5,
        }
    },
    mounted() {
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.isLoggedIn = true;
                } else {
                    this.isLoggedIn = false;
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {
        getPrice(gb){
            var price = {
                "20": 0.20,
                "50": 0.15,
                "100": 0.12,
                "250": 0.10,
                "500": 0.08,
                "normal": 0.06
            }
            if(gb <= 20) return gb * price["20"];
            if(gb <= 50) return gb * price["50"];
            if(gb <= 100) return gb * price["100"];
            if(gb <= 250) return gb * price["250"];
            if(gb <= 500) return gb * price["500"];
            return gb * price["normal"];
        },
    },
    metaInfo() {
        return {
            meta: [
                {
                    name: "description",
                    content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                },
                {
                    property: "title",
                    content: "Stacket | Hosting done the right way",
                },
                {
                    name: "og:url",
                    content: "https://stacket.net"
                },
                {
                    property: "og:title",
                    content: "Stacket | Hosting done the right way",
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    property: "og:description",
                    content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                },
                {
                    name: "robots",
                    content: "index, follow",
                }
            ]
        };
    },
}
</script>

<style lang="scss">
    @mixin disable-selection {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none;   /* Safari */
        -khtml-user-select: none;    /* Konqueror HTML */
        -moz-user-select: none;      /* Firefox */
        -ms-user-select: none;       /* Internet Explorer/Edge */
        user-select: none;           /* Non-prefixed version, currently supported by Chrome and Opera */
    }

    .pricingBox {
        @include disable-selection;
        display: inline-block;
        text-align: center;

        margin-bottom: 50px;
        width: 225px; 
        height: 200px; 
        border-radius: 3px; 
        background-color: white;
        padding: 12.5px;
        h5 {
            display: inline-block;
            margin-left: 15px;
        }
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: 2px solid white;
        transition: all .2s ease-in-out;
    }

    .pricingBox:hover{
        transform: scale(1.05);
    }

</style>