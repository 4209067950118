<template>
    <div style="height: 100%; background-color: #f6f7fb;">
        <b-container fluid style="height: 100%;">
            <frontPageHeader></frontPageHeader>
            <b-row class="justify-content-center" style="background-image: linear-gradient(#026DC9, #5AADFC); min-height: 500px; padding-top: 100px; color: white;">
                <b-col cols="5" class="mt-5">
                    <h1 class="textShadow"><strong>
                        {{ Lang.privatenodes.title.header }}
                    </strong></h1>
                    <h5 style="margin-top: 15px;" class="textShadow">
                        {{ Lang.privatenodes.title.text }}
                    </h5>
                    <b-button class="mt-5 shadow-md" :to="(isLoggedIn == true) ? '/dashboard' : '/register'" variant="light" style="width: 30%; line-height: 43px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                        {{ Lang.privatenodes.title.button }}
                    </b-button>
                </b-col>
                <b-col cols="3" class="mt-4">
                    <img src="@/assets/images/privatenodes.png" height=300px width=591px style="border-radius: 3px;" v-b-tooltip.hover title="Private Nodes" class="shadow-xl">
                </b-col>
            </b-row>
            <b-row class="justify-content-center" style="margin-top: 50px;">
                <h1 class="mt-5">Powerful enterprise features for your company.</h1>
            </b-row>
            <b-row class="mt-5 mb-5">
                <b-col col="2"></b-col>
                <b-col style="text-align: center;">
                    <h3 style="color: #394047;">
                        <i class="fas fa-lock" style="color: #3592E6;"></i>
                        {{ Lang.privatenodes.features.networking.title }}
                    </h3>
                    <p style="font-size: 1.25rem;">
                        {{ Lang.privatenodes.features.networking.text }}
                    </p>
                </b-col>
                <b-col cols="2" style="text-align: center; min-width: 300px; margin-bottom: 25px;">
                    <h3 style="color: #394047;">
                        <i class="fas fa-cubes" style="color: #3592E6;"></i>
                        {{ Lang.privatenodes.features.scaling.title }}
                    </h3>
                    <p style="font-size: 1.25rem;">
                        {{ Lang.privatenodes.features.scaling.text }}
                    </p>
                </b-col>
                <b-col cols="2" style="text-align: center; min-width: 300px; margin-bottom: 25px;">
                    <h3 style="color: #394047;">
                        <i class="fas fa-stream" style="color: #3592E6;"></i>
                        {{ Lang.privatenodes.features.easy.title }}
                    </h3>
                    <p style="font-size: 1.25rem;">
                        {{ Lang.privatenodes.features.easy.text }}
                    </p>
                </b-col>
                <b-col col="2"></b-col>
            </b-row>
            <b-row style="background-color: #e8ecfa;" class="justify-content-center">
                <b-col cols="10">
                    <b-row class="justify-content-center">
                        <h2 class="mt-5">
                            {{ Lang.privatenodes.ressources.title }}
                        </h2>
                    </b-row>
                    <b-row class="justify-content-center" style="padding-top: 25px; margin-bottom: 50px;">
                        <b-col class="shadow" cols="2" v-for="(value, index) of Lang.privatenodes.ressources.specs" v-bind:key="index" style="margin-right: 12.5px; margin-left: 12.5px; background-color: white; text-align: center; border-radius: 10px; border-style: solid; border-color: #026DC9; border-width: 1px; padding: 10px;">
                            <h5>
                                {{ value.title }}
                            </h5>
                            <p>
                                {{ value.data }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center">
                        <h2 class="mt-5">
                            {{ Lang.privatenodes.packages.title }}
                        </h2>
                    </b-row>
                    <b-row class="justify-content-center mt-3" style="margin-left: 5%; margin-right: 5%; padding-top: 25px;">
                        <b-col style="min-width: 220px; margin-right: 12.5px; margin-left: 12.5px" cols="2" v-for="(price, index) of Lang.privatenodes.packages.prices.slice(0, 3)" :key="index">
                            <div class="pricingBox">
                                <h4>${{ price.monthly.toFixed(2) }}<small>/mo</small></h4>
                                <hr>
                                <h5>{{ price.cores }}</h5> <span style="font-size: 1.25rem;"> Cores </span>
                                <br>
                                <h5>{{ price.ram }} MB</h5> <span style="font-size: 1.25rem;"> RAM </span>
                                <br>
                                <h5>{{ price.disk }} GB</h5> <span style="font-size: 1.25rem;"> Disk </span>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center mb-3" style="margin-left: 5%; margin-right: 5%; margin-top: -15px;">
                        <b-col style="min-width: 220px; margin-right: 12.5px; margin-left: 12.5px" cols="2" v-for="(price, index) of Lang.privatenodes.packages.prices.slice(3, 6)" :key="index">
                            <div class="pricingBox">
                                <h4>${{ price.monthly.toFixed(2) }}<small>/mo</small></h4>
                                <hr>
                                <h5>{{ price.cores }}</h5> <span style="font-size: 1.25rem;"> Cores </span>
                                <br>
                                <h5>{{ price.ram }} MB</h5> <span style="font-size: 1.25rem;"> RAM </span>
                                <br>
                                <h5>{{ price.disk }} GB</h5> <span style="font-size: 1.25rem;"> Disk </span>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <frontPageFooter style="margin-top: 100px;"></frontPageFooter>
        </b-container>
    </div>
</template>

<script>
import frontPageHeader from "@/pages/frontpage/header.vue";
import frontPageFooter from "@/pages/frontpage/footer.vue";
export default {
    name: "Privat Nodes",
    components: {
        frontPageFooter,
        frontPageHeader,
    },
    data: () => {
        return {
            isLoggedIn: true,
        }
    },
    mounted() {
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.isLoggedIn = true;
                } else {
                    this.isLoggedIn = false;
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {

    },
    metaInfo() {
        return {
            meta: [
                {
                    name: "description",
                    content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                },
                {
                    property: "title",
                    content: "Stacket | Hosting done the right way",
                },
                {
                    name: "og:url",
                    content: "https://stacket.net"
                },
                {
                    property: "og:title",
                    content: "Stacket | Hosting done the right way",
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    property: "og:description",
                    content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                },
                {
                    name: "robots",
                    content: "index, follow",
                }
            ]
        };
    },
}
</script>

<style lang="scss">
    @mixin disable-selection {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none;   /* Safari */
        -khtml-user-select: none;    /* Konqueror HTML */
        -moz-user-select: none;      /* Firefox */
        -ms-user-select: none;       /* Internet Explorer/Edge */
        user-select: none;           /* Non-prefixed version, currently supported by Chrome and Opera */
    }

    .pricingBox {
        @include disable-selection;
        display: inline-block;
        text-align: center;

        margin-bottom: 50px;
        width: 225px; 
        height: 200px; 
        border-radius: 3px; 
        background-color: white;
        padding: 12.5px;
        h5 {
            display: inline-block;
            margin-left: 15px;
        }
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: 2px solid white;
        transition: all .2s ease-in-out;
    }

    .pricingBox:hover{
        transform: scale(1.05);
    }

</style>