<template>
    <div style="height: 100%; background-color: #f6f7fb;">
        <b-container fluid style="height: 100%;">
            <b-row>
                <b-col style="padding-left: 0; padding-right: 0;">
                    <b-navbar type="light" variant="light" style="border-bottom: lightgrey solid 1px;">
                        <b-navbar-brand>
                            <router-link to="/"><img src="@/assets/images/branding/brandingBlue.png" height=25px style="margin-bottom: 5px;"></router-link>
                        </b-navbar-brand>
                    </b-navbar>
                </b-col>
            </b-row>
            <b-row style="padding-left: 0; padding-right: 0; height: calc(100% - 59px);">
                <b-col cols="12">
                    <b-container style="margin-top: 50px; border-radius: 4px; border: 1px solid lightgray; min-height: 500px; padding: 50px; font-family: Roboto; color: rgb(73, 80, 87); background-color: white;">
                        <b-row v-if="down == 0" class="justify-content-md-center" style="margin-bottom: 25px; background-color: #03b66c; border-radius: 5px; padding: 15px; color: white;">
                            <b-col cols="1" style="text-align: right;">
                                <i class="fas fa-check-circle" style="font-size: 30px;"></i>
                            </b-col>
                            <b-col cols="3" align-self="center" style="font-weight: 500;">
                                ALL SYSTEMS OPERATIONAL
                            </b-col>
                        </b-row>
                        <b-row v-else class="justify-content-md-center" style="margin-bottom: 25px; background-color: #ff3d3d; border-radius: 5px; padding: 15px; color: white;">
                            <b-col cols="1" style="text-align: right;">
                                <i class="fas fa-exclamation-circle" style="font-size: 30px;"></i>
                            </b-col>
                            <b-col cols="3" align-self="center" style="font-weight: 500;">
                                {{ down }} SYSTEM{{ (down == 1) ? "" : "S" }} DOWN
                            </b-col>
                        </b-row>
                        <div v-for="(category, index) of categories" v-bind:key="index" style="margin-bottom: 30px;">
                            <b-row style="margin-bottom: 10px;">
                                <b-col>
                                    <h5>
                                        {{ category.title }}
                                        <img v-if="category.image" v-bind:src="category.image" style="height: 25px; margin-left: 10px;">
                                    </h5>
                                </b-col>
                            </b-row>
                            <span v-for="(system, index) of systems.filter(e => e.category == category.id).sort((a, b) => parseFloat(a.index) - parseFloat(b.index))" v-bind:key="index">
                                <b-row>
                                    <b-col>
                                        <i class="fas fa-check-circle" v-if="system.status == 'online'" style="color: #03b66c;"></i>
                                        <i class="fas fa-exclamation-circle" v-else style="color: #ff3d3d;"></i>
                                        {{ system.name }}
                                    </b-col>
                                    <b-col cols="2" style="text-align: right;">
                                        {{ system.ping.toFixed(3) }} ms
                                    </b-col>
                                </b-row>
                                <hr>
                            </span>
                        </div>
                    </b-container>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

export default {
    name: "Login",
    components: {
    },
    data: () => {
        return {
            locations: {
                "aad": require("@/assets/images/services/denmark.png"),
                "fsn": require("@/assets/images/services/germany.png"),
                "us": require("@/assets/images/services/united_states.png")
            },
            down: 0,
            categories: [
                {
                    title: "Systems",
                    id: "system"
                },
                {
                    title: "Nodes",
                    id: "aad_node",
                    image: require("@/assets/images/services/denmark.png")
                },
                {
                    title: "Nodes",
                    id: "fsn_node",
                    image: require("@/assets/images/services/germany.png")
                },
                {
                    title: "Nodes",
                    id: "us_node",
                    image: require("@/assets/images/services/united_states.png")
                }
            ],
            systems: []
        }
    },
    mounted() {
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.getStatus();
                } else {
                    localStorage.removeItem("user-token");
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {
        getStatus() {
            this.$http.get(this.StacketConfig.api.status + "/").then(response => {
                this.systems = response.body; 
            }).catch(err => {
                console.log(err);
            })
        }
    }
}
</script>

<style lang="scss">


</style>