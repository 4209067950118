<template>
    <div style="height: 100%; background-color: #f6f7fb;">
        <b-container fluid style="height: 100%;">
            <frontPageHeader></frontPageHeader>
            <b-row class="justify-content-center" style="background-image: linear-gradient(#026DC9, #5AADFC); min-height: 600px; padding-top: 100px; color: white;">
                <b-col cols="5" class="mt-5" style="min-width: 300px;">
                    <h1 class="textShadow"><strong>
                        Ping Tester
                    </strong></h1>
                    <h5 style="margin-top: 15px;" class="textShadow">
                        Mesure your ping to Stacket
                    </h5>
                    <b-button class="mt-5 shadow-md" @click="startPing" variant="light" style="min-width: 250px; width: 30%; line-height: 43px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                        <span v-if="testing == false">
                            Test your ping
                        </span>
                        <span v-else>
                            Measuring..
                        </span>
                    </b-button>
                </b-col>
                <b-col cols="3" class="mt-4" style="min-width: 300px; padding-top: 100px; text-align: center;">
                    <h3 v-if="tested == true" style="margin-left: -50px;">
                        Average: {{ avg.toFixed(2) }}ms <br>
                        Min: {{ min.toFixed(2) }}ms <br>
                        Max: {{ max.toFixed(2) }}ms 
                    </h3>
                    <h3 v-if="testing == true" style="margin-left: -50px;">
                        Ping: {{ ping.toFixed(2) }}ms
                    </h3>
                    <trend
                        :data="pings"
                        :gradient="['#ffff', '#ffff', '#ffff']"
                        auto-draw
                        smooth
                        gradientDirection="top"
                        :padding="8"
                        :radius="8"
                        :stroke-width="1"
                        stroke-linecap="butt"
                        >
                    </trend>
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-col cols="4" style="color: white; font-size: 12px; margin-top: -50px; text-align: center;">
                    Note that there has been removed 5ms from result to combat added latency added from the browser and web server (this has been proven to be more accurate by doing this)
                </b-col>
            </b-row>
            <frontPageFooter style="margin-top: 100px;"></frontPageFooter>
        </b-container>
    </div>
</template>

<script>
import frontPageHeader from "@/pages/frontpage/header.vue";
import frontPageFooter from "@/pages/frontpage/footer.vue";
import Vue from "vue"
import Trend from "vuetrend"

export default {
    name: "Ping",
    components: {
        frontPageFooter,
        frontPageHeader,
        Trend
    },
    data: () => {
        return {
            isLoggedIn: true,
            avg: 0,
            min: 0,
            max: 0,
            tested: false,
            testing: false,
            ping: 0,
            pings: [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
            ]
        }
    },
    mounted() {
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.isLoggedIn = true;
                } else {
                    this.isLoggedIn = false;
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {
        delay(ms) {
            return new Promise((resolve, reject) => setTimeout(resolve, ms))
        },
        async startPing(){
            this.tested = false;
            this.testing = true;
            this.pings = [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
            ];
            const timeStart = Date.now();
            var pingCount = 0;
            while ((Date.now() - timeStart) < 12000) {
                const pingStart = (performance.now() + performance.timeOrigin);
                try {
                    await fetch(`https://ping.stacket.net`, {
                        mode: "no-cors",
                        cache: "no-store"
                    });
                } catch (e) {}
                //Calculate latency
                //Note: Removing 5ms from result to combat added latency added from the browser and web server (Proven to be more accurate by doing this)
                this.ping = ((performance.now() + performance.timeOrigin)- pingStart) - 5;
                console.log("Ping: " + this.ping + "ms")
                if (pingCount > 1) {
                    if(this.pings[0] == 0){
                        this.pings.shift();
                    }
                    this.pings.push(this.ping);
                    this.min = Math.min.apply(null, this.pings);
                    this.max = Math.max.apply(null, this.pings);
                }
                pingCount++;
                await this.delay(Math.max(400 - this.ping, 10));
            }
            var sum = this.pings.reduce((a, b) => a + b, 0);
            this.avg = (sum / this.pings.length) || 0;
            this.tested = true;
            this.testing = false;
        }
    }
}
</script>

<style lang="scss">
    @mixin disable-selection {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none;   /* Safari */
        -khtml-user-select: none;    /* Konqueror HTML */
        -moz-user-select: none;      /* Firefox */
        -ms-user-select: none;       /* Internet Explorer/Edge */
        user-select: none;           /* Non-prefixed version, currently supported by Chrome and Opera */
    }

    .pricingBox {
        @include disable-selection;
        display: inline-block;
        text-align: center;

        margin-bottom: 50px;
        width: 225px; 
        height: 185px; 
        border-radius: 3px; 
        background-color: white;
        padding: 12.5px;
        h5 {
            display: inline-block;
            margin-left: 15px;
        }
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: 2px solid white;
        transition: all .2s ease-in-out;
    }

    .pricingBox:hover{
        transform: scale(1.05);
    }

</style>