<template>
    <div style="font-family: Roboto; color: rgb(73, 80, 87); width: 100%!important;">
        <b-modal id="coupon" title="Coupon Information" okTitle="Close" centered ok-only>
            <b>
                {{ Lang.dashboard.service.coupon.name }}:
            </b>
            {{ coupon.name }}
            <br>
            <b>
                {{ Lang.dashboard.service.coupon.package }}:
            </b>
            {{ coupon.packageInfo.memory / 1024 }} GB
            <br>
            <b>
                {{ Lang.dashboard.service.coupon.price }}:
            </b>
                {{ Lang.dashboard.order.priceFormat.replace("$v", ((coupon.price / 0.75)  * Lang.price).toFixed(2)) }}
            <br>
            <b>
                {{ Lang.dashboard.service.coupon.expires }}:
            </b>
            <span style="text-transform: capitalize;">
                <timeago :datetime="coupon.expires" :converterOptions="{ includeSeconds: true }" :auto-update="10"></timeago> ({{ formatdate(new Date(coupon.expires)) }})
            </span>
        </b-modal>
        <b-row align-h="end">
            <b-col class="ml-5">
                <i class="fas fa-circle" style="color:#2ead42!important;" v-if="service.status == 'online'"></i>
                <i class="fas fa-circle" style="color:#f13862!important;" v-else-if="service.status == 'offline'"></i>
                <i class="fas fa-circle" v-else-if="service.status == 'hibernating'"></i>
                <strong class="ml-3" style="color:#2ead42!important;" v-if="service.status == 'online'">{{ Lang.dashboard.service.online }}</strong>
                <strong class="ml-3" style="color:#f13862!important;" v-else-if="service.status == 'offline'">{{ Lang.dashboard.service.offline }}</strong>
                <strong class="ml-3" v-else-if="service.status == 'hibernating'">{{ Lang.dashboard.service.hibernating }}</strong>
            </b-col>
            <b-button class="mr-2" variant="success" v-on:click="$parent.startService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.start }}</b-button>
            <b-button class="mr-2" variant="danger" v-on:click="$parent.stopService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.stop }}</b-button>
            <b-button class="mr-2" variant="danger" v-on:click="$parent.killService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.kill }}</b-button>

            <b-button class="mr-2" variant="primary" v-if="service.type == 'vm'" disabled>{{ Lang.dashboard.service.controls.hibernate }}</b-button>
            <b-button class="mr-2" variant="primary" v-else v-on:click="$parent.hibernateService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.hibernate }}</b-button>
            
            <b-button class="mr-5" variant="warning" v-if="service.type == 'vm'" disabled>{{ Lang.dashboard.service.controls.reactivate }}</b-button>
            <b-button class="mr-5" variant="warning" v-else v-on:click="$parent.reactivateService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.reactivate }}</b-button>
        </b-row>
        <hr>
        <b-row>
            <b-col>
                <b-row>
                    <b-col cols="1"></b-col>
                    <b-col class="mt-4" style="min-width: 275px!important;">
                        <h2>{{ Lang.dashboard.service.overview.information }}</h2>
                        <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                            <b-row>
                                <b-col cols="3" style="min-width: 150px;">
                                    <span><strong>{{ Lang.dashboard.service.overview.id }}:</strong></span><br>
                                    <span><strong>{{ Lang.dashboard.service.overview.type }}:</strong></span><br>
                                    <span><strong>{{ Lang.dashboard.service.overview.version }}:</strong></span><br>
                                    <span><strong>{{ Lang.dashboard.service.overview.cpu }}:</strong></span><br>
                                    <span><strong>{{ Lang.dashboard.service.overview.memory }}:</strong></span><br>
                                    <span><strong>{{ Lang.dashboard.service.overview.disk }}:</strong></span><br>
                                    <span><strong>{{ Lang.dashboard.service.overview.estPrice }}:</strong></span><br>
                                    <span v-if="service.coupon"><strong>{{ Lang.dashboard.service.overview.coupon }}:</strong><br></span>
                                    <br>
                                    <span><strong>{{ Lang.dashboard.networks.ipAddress }}:</strong></span><br>
                                    <span><strong>{{ Lang.dashboard.networks.port }}:</strong></span><br>
                                    <span><strong>{{ service.type == "vm" ? Lang.dashboard.service.overview.datacenter : Lang.dashboard.service.overview.node }}:</strong></span><br>
                                </b-col>
                                <b-col style="min-width: 150px;">
                                    <span>{{ service._id }}</span><br>
                                    <span>{{ (types[service.type] != null) ? types[service.type] : "Unknown" }}</span><br>
                                    <span style="text-transform: capitalize;">{{ service.version.replace("-", " ").replace("node", "").replace("nginx", "").replace("fivem", "") }}</span><br>
                                    <!-- CPU -->
                                    <span v-if="service.package && packages[service.package].cpu">{{ packages[service.package].cpu }}<small> core{{ packages[service.package].cpu == 1 ? "" : "s"}}</small></span>
                                    <span v-else-if="service.cores">{{ service.cores }}<small> core{{ service.cores == 1 ? "" : "s"}}</small></span>
                                    <br>
                                    <!-- RAM -->
                                    <span v-if="service.package && packages[service.package]">{{ packages[service.package].memory }}<small>MB</small></span>
                                    <span v-else-if="service.ram">{{ service.ram }}<small>MB</small></span>
                                    <br>
                                    <!-- DISK -->
                                    <span v-if="service.package && packages[service.package]">{{ packages[service.package].disk }}<small>GB</small></span>
                                    <span v-if="service.disk">{{ service.disk }}<small>GB</small></span>
                                    <br>
                                    <!-- Price -->
                                    <span v-if="service.package && packages[service.package]">{{ Lang.dashboard.order.priceFormat.replace("$v", (packages[service.package].price * Lang.price).toFixed(2)) }}<small>{{ Lang.dashboard.order.mo }}</small></span>
                                    <span v-else-f="service.price">{{ Lang.dashboard.order.priceFormat.replace("$v", (service.price * Lang.price).toFixed(2)) }}<small>{{ Lang.dashboard.order.mo }}</small></span>
                                    <br>
                                    
                                    <span v-if="service.coupon" >
                                        <b-button @click="showCoupon" size="sm" variant="primary">Show</b-button><br>
                                    </span>
                                    <br>
                                    <span>{{ network.ip }}</span><br>
                                    <span>{{ (service.allocations.find(allo => allo.default == true) != null) ? service.allocations.find(allo => allo.default == true).public : "Unknown"}} <small><b-link :to="`/dashboard/${this.$route.params.page}/allocations`">{{ Lang.dashboard.sidebar.service.allocations }}</b-link></small></span><br>
                                    <span>{{ network.node.toUpperCase() }}</span><br>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <b-col cols="1"></b-col>
                </b-row>
                <b-row  v-if="service && service.type && service.type != 'vm'">
                    <b-col cols="1"></b-col>
                    <b-col class="mt-4" style="min-width: 275px!important;">
                        <h2>{{ Lang.dashboard.service.overview.resources }}</h2>
                        <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                            <b-row>
                                <b-col>
                                    <strong>{{ Lang.dashboard.service.overview.cpu }}</strong>
                                    <span style="margin-left: 5px; font-size: 13px;">{{ usage.cpu.usage.toFixed(2) }}%</span><br>
                                    <b-progress :max="100" :variant="(usage.cpu.usage >= 80) ? 'danger' : 'primary'">
                                        <b-progress-bar :value="usage.cpu.usage"></b-progress-bar>
                                    </b-progress><br>
                                    <strong>{{ Lang.dashboard.service.overview.memory }}</strong>
                                    <span style="margin-left: 5px; font-size: 13px;">{{ (usage.memory.usage * 1e-6).toFixed(0) }} / {{ packages[service.package].memory }} MB</span><br>
                                    <b-progress :max="usage.memory.total" :variant="(((usage.memory.usage / usage.disk.total) * 100) >= 80) ? 'danger' : 'primary'">
                                        <b-progress-bar :value="usage.memory.usage"></b-progress-bar>
                                    </b-progress><small v-if="(usage.memory.usage / usage.memory.total) * 100 >= 80">Need more? <b-link :to="`/dashboard/${this.$route.params.page}/resize`">Upgrade</b-link></small><br>
                                    <strong>{{ Lang.dashboard.service.overview.disk }}</strong>
                                    <span style="margin-left: 5px; font-size: 13px;">{{ (usage.disk.usage * 1e-9).toFixed(2) }} / {{ packages[service.package].disk }} GB</span><br>
                                    <b-progress :max="usage.disk.total" :variant="(((usage.disk.usage / usage.disk.total) * 100) >= 80) ? 'danger' : 'primary'">
                                        <b-progress-bar :value="usage.disk.usage"></b-progress-bar>
                                    </b-progress><small v-if="(usage.disk.usage / usage.disk.total) * 100 >= 80">Need more? <b-link :to="`/dashboard/${this.$route.params.page}/resize`">Upgrade</b-link></small>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <b-col cols="1"></b-col>
                </b-row>
            </b-col>
            <b-col>
                <b-row style="height: 100%;">
                    <b-col class="mt-4" style="height: 100%;">
                        <h2>{{ Lang.dashboard.sidebar.service.history }}</h2>
                        <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px; height: 88%; max-height: 88%;">
                            <b-row>
                                <b-col>
                                    <div v-for="(item, index) of this.$parent.history.slice(0, 13)" v-bind:key="index" class="mb-2">
                                        <b-row>
                                            <b-col>
                                                <i class="fas fa-check-circle" style="color:#2ead42!important;" v-if="item.progress == 100"></i>
                                                <i class="fas fa-spinner spinnerAnim" style="color:#f1db38!important;" v-else-if="item.status == 'running'"></i>
                                                <i class="fas fa-exclamation-circle" style="color:#f13862!important;" v-else-if="item.progress == 0 || item.status == 'error'"></i>
                                                <span class="ml-2" style="color:#2ead42!important; font-weight: 500;" v-if="item.progress > 0 && item.status == 'success' && !item.error">{{ item.message }}</span>
                                                <span class="ml-2" style="color:#f1db38!important; font-weight: 500;" v-else-if="item.status == 'running' && !item.error">{{ item.message }}</span>
                                                <span class="ml-2" style="color:#f13862!important; font-weight: 500;" v-else-if="item.progress == 0 || item.status == 'error'">{{ item.message }}</span>
                                                <!-- <b-tooltip :target="`success-${item.started}`" triggers="hover" v-if="item.progress > 0 && item.status == 'success' && !item.error">
                                                    {{ item.message }}
                                                </b-tooltip> -->
                                                <!-- <b-tooltip :target="`running-${item.started}`" triggers="hover" v-else-if="item.status == 'running' && !item.error">
                                                    {{ item.message }}
                                                </b-tooltip> -->
                                                <b-tooltip :target="`${item.started}`" triggers="hover" v-else-if="item.progress == 0 || item.status == 'error'">
                                                    {{ item.error.message }}
                                                </b-tooltip>
                                            </b-col>
                                            <b-col cols="4">
                                                <timeago :datetime="(item.finished != 0) ? item.finished : item.started" :converterOptions="{ includeSeconds: true }" :auto-update="15"></timeago>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row style="position: absolute; left: 50%; bottom: 20px; transform: translate(-50%, -50%); margin: 0 auto;">
                                <b-col style="text-align: center;">
                                    <router-link :to="`/dashboard/${this.$route.params.page}/history/1`" class="backToDashboard" style="color: rgb(199, 199, 199); text-decoration: none;">
                                        <span>View More</span>
                                    </router-link>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <b-col cols="1"></b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>
<script>

</script>
<style lang="scss">
    
</style>
<script>

export default {
    name: "Overview",
    components: {
        
    },
    props: [
        "packages",
        "network",
        "usage"
    ],
    data: () => {
        return {
            token: "",
            coupon: {
                _id: "",
                name: "",
                coupon: "",
                type: "",
                platform: "",
                version: "",
                package: "",
                price: 0,
                expires: 0,
                packageInfo: {
                    price: 0,
                    memory: "",
                    disk: 0
                }
            },
            types: {
                "minecraft": "Minecraft Hosting",
                "nodejs": "Node.js Hosting",
                "nginx": "Nginx Hosting",
                "nginx-php": "Nginx PHP Hosting",
                "csgo": "CS:GO Hosting",
                "mysql": "MySQL Hosting",
                "terraria": "Terraria Hosting",
                "deno": "Deno Hosting",
                "python": "Python Hosting",
                "mongodb": "MongoDB Hosting",
                "factorio": "Factorio Hosting",
                "fivem": "FiveM Hosting",
                "vps": "VPS Hosting"
            },
            service: {
                "_id": "",
                "name": "",
                "type": "",
                "version": "",
                "allocations": [
                    {
                        "type": "port",
                        "default": true,
                        "disabled": false,
                        "public": 25566,
                        "target": 25565,
                        "id": "",
                        "ip": null
                    }
                ],
                "networks": [],
                "node": "fsn2",
                "storage": "fsn2",
                "package": "pkg1",
                "docker": "",
                "status": "offline",
                "subusers": [],
                "owner": ""
            },
            access: {},
            packages: {
                "pkg1": {
                    price: 3.00, //$
                    memory: "1024", //Megabytes memory
                    disk: 10, //GB disk space
                },
                "pkg2": {
                    price: 6.00, //$
                    memory: "2048", //Megabytes memory
                    disk: 15, //GB disk space
                },
                "pkg3": {
                    price: 9.00, //$
                    memory: "3072", //Megabytes memory
                    disk: 20, //GB disk space
                },
                "pkg4": {
                    price: 12.00, //$
                    memory: "4096", //Megabytes memory
                    disk: 25, //GB disk space
                },
                "pkg5": {
                    price: 15.00, //$
                    memory: "5120", //Megabytes memory
                    disk: 30, //GB disk space
                },
                "pkg6": {
                    price: 18.00, //$
                    memory: "6144", //Megabytes memory
                    disk: 35, //GB disk space
                },
                "pkg7": {
                    price: 21.00, //$
                    memory: "7168", //Megabytes memory
                    disk: 40, //GB disk space
                },
                "pkg8": {
                    price: 24.00, //$
                    memory: "8192", //Megabytes memory
                    disk: 50, //GB disk space
                }
            },
            port: "",
            //timeAgo: null,
            interval: null
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        this.service = this.$parent.service.service;
        this.access = this.$parent.service.access;
        //this.packages = this.$parent.packages;
        if(this.$parent.service.service.status != 'hibernating' && this.$parent.service.service.type != "vm"){
            this.$parent.getActions();
            this.$parent.getUsage();
        }
        //TimeAgo.addLocale(en);
        //this.timeAgo = new TimeAgo('en-US');
    },
    methods: {
        formatdate(date){
            const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' }) 
            const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat .formatToParts(date );
            return `${day}. ${month} ${year }`;
        },
        showCoupon(){
            this.$http.get(this.StacketConfig.api.services + "/deal/" + this.service.coupon, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.coupon = response.data;
                this.$bvModal.show('coupon');
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch deal!"
                })
            });
        },
        getAction(id){
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page + "/actions/" + id, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                return response.data;
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch action!"
                })
            });
        },
        commandName(command){
            return command == 'start_service' ? 'Start Service' : command == 'stop_service' ? 'Stop Service' : command == 'kill_service' ? 'Kill Service' : command == 'die_service' ? 'Service stopped unexpectedly' : command == 'activate_service' ? 'Activate Service' : command == 'hibernate_service' ? 'Hibernate Service' : 'Unknown Command';
        }
    }
}  
</script>