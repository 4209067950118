<template>
    <div style="height: 100%; background-color: #f6f7fb; ">
        <b-container fluid style="height: 100%;">
            <frontPageHeader></frontPageHeader>
            <index v-if="!$route.params.id"></index>
            <guide v-else-if="$route.params.id"></guide>
            <frontPageFooter style="margin-top: 250px;"></frontPageFooter>
        </b-container>
    </div>
</template>

<script>
import frontPageHeader from "@/pages/frontpage/header.vue";
import frontPageFooter from "@/pages/frontpage/footer.vue";
import index from "@/pages/guides/index.vue";
import guide from "@/pages/guides/guide.vue";

export default {
    name: "Guides",
    components: {
        frontPageFooter,
        frontPageHeader,
        index,
        guide,
    },
    data: () => {
        return {
            isLoggedIn: true,
        }
    },
    mounted() {
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.isLoggedIn = true;
                } else {
                    this.isLoggedIn = false;
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {

    }
}
</script>

<style lang="scss">

.guide {
    cursor: pointer;
    transition: 0.25s;
}
.guide:hover {
    color: #0080ff;
}

#dashboardButton {
    a {
        color: rgb(230, 230, 230)!important;
    }
    a:hover {
        color: white!important;
    }
}

.textShadow {
    text-shadow: 0px 2px 10px rgba(0,0,0,.175)!important;
}
</style>