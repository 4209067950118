<template>
    <div v-if="folder.color" @click="gotoFolder()" class="folderCard" style="box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;" v-bind:style="'background: linear-gradient(0deg,' + folder.color.bottom + ' 0%, ' + folder.color.top + ' 100%);'">
        <b-row style="padding-top: 6%;">
            <b-col cols="4">
                <i class="fas fa-folder" style="margin-left: 15px; margin-right: 20px; font-size: 50px; opacity: 0.9; color: white;"></i>
            </b-col>
            <b-col>
                <div style="color: white; font-weight: bold; font-size: 17px;">
                    {{ (folder.name ? folder.name : Lang.dashboard.folders.name ) }}
                </div>
                <p style="color: white; font-weight: normal; font-size: 13px; margin-top: 0px;">
                    {{ (folder.items ? folder.items.length : folder.services.concat(folder.networks).concat(folder.disks).length ) }} {{ Lang.dashboard.components.folder.services }}
                </p>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss">
    .folderCard {
        cursor: pointer;
        height: 100%;
        min-width: 200px;
        min-height: 50px;
        border-radius: 10px;

        transition: 0.20s;
    }
    .folderCard:hover {
        transform: scale(1.025);
        background: linear-gradient(0deg, rgb(21, 106, 211) 0%, rgb(89, 152, 230) 100%);
    }
</style>

<script>

export default {
    name: "serviceCard",
    components: {
        
    },
    data: () => {
        return {
            token: "",
        }
    },
    props: [
        "folder",
        "services",
        "networks",
        "disks"
    ],
    mounted() {
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
        }
    },
    methods: {
       gotoFolder(){
            if(!this.folder._id) return;
            this.$router.replace('/dashboard/folders/' + this.folder._id);
       }
    }
}  

</script>