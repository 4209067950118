<template>
    <b-card-group deck v-if="node.team" class="mt-3">
        <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="serviceCard shadow-xl">
            <template v-slot:header>
                <router-link :to="'/dashboard/nodes/'+node._id" style="text-decoration: none;">
                    <h6 class="mb-0" style="color: #777676;">
                        <span style="color: #777676;">{{ node.name }}</span>
                    </h6>
                </router-link>
            </template>
            <b-card-body v-if="node.team.groups && node.team.groups.length != 0">
                <b-row style="margin-bottom: 5px;">
                    <b-col cols="4">
                        <center>
                            <img v-bind:src="node.team.image" style="width: 50px;" v-b-tooltip.hover v-bind:title="node.team.name">
                        </center>
                    </b-col>
                    <b-col cols="1">
                        <center style="height: 100%;">
                            <div style="height: 100%; border: #e2e2e4 solid 0.5px;"></div>
                        </center>
                    </b-col>
                    <b-col style="padding-top: 10px;">
                        <span v-html="convertName(node.team.groups[0].name || '')" v-if="node.team.groups[0]" v-b-tooltip.hover v-bind:title="node.team.groups[0].name" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #eaf8eb; font-weight: 500; font-size: 12px; color: #2ead42; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px;">
                            
                        </span>
                        <span v-html="convertName(node.team.groups[1].name || '')" v-if="node.team.groups[1]" v-b-tooltip.hover v-bind:title="node.team.groups[1].name" class="align-middle shadow-sm" style="text-transform: uppercase; text-align center; background-color: #f0f3fa; font-weight: 500; font-size: 12px; color: #477ed9; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                            
                        </span>
                        <span v-html="'+'+(node.team.groups.length - 2)" v-if="node.team.groups[2]" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #ffeff1; font-weight: 500; font-size: 13px; color: #f13862; padding: 9px; padding-top: 9px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                            
                        </span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <center>
                            <h6>
                                {{ Lang.dashboard.components.service.team }}
                            </h6>
                        </center>
                    </b-col>
                    <b-col>
                        <center>
                            <h6>
                                {{ Lang.dashboard.components.service.groups }}
                            </h6>
                        </center>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-else="">
                <b-row>
                    <b-col>
                        <b-row style="margin-bottom: 5px;">
                            <b-col>
                                <center>
                                    <img v-bind:src="node.team.image" style="width: 50px; border-radius: 100%;"  v-b-tooltip.hover v-bind:title="node.team.name">
                                </center>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <center>
                                    <h6>
                                        {{ Lang.dashboard.components.service.owner }}
                                    </h6>
                                </center>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            {{ Lang.dashboard.networks.ipAddress }}
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px">
                            {{ node.ip }}
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            {{ Lang.dashboard.networks.node }}
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px; text-transform: uppercase;">
                            {{ node.node }}
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            {{ Lang.dashboard.components.node.cpu }}
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px; text-transform: uppercase;">
                            {{ usage.cpu.toFixed(4) }}%
                        </h6>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            {{ Lang.dashboard.components.node.memory }}
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px; text-transform: uppercase;">
                            {{ (usage.memory.used / 1024).toFixed(3) }} / {{ (usage.memory.total / 1024).toFixed(3) }} GB
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
        </b-card>
    </b-card-group>
    <b-card-group deck v-else class="mt-3">
        <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="serviceCard shadow-xl">
            <template v-slot:header>
                <router-link :to="'/dashboard/nodes/'+node._id" style="text-decoration: none;">
                    <h6 class="mb-0" style="color: #777676;">
                        <span style="color: #777676;">{{ node.name }}</span>
                    </h6>
                </router-link>
            </template>
            <b-card-body v-if="node.subusers && node.subusers.length != 0">
                <b-row>
                    <b-col>
                        <b-row style="margin-bottom: 5px;">
                            <b-col cols="4">
                                <center>
                                    <img v-bind:src="node.owner.image" style="width: 50px; border-radius: 100%;"  v-b-tooltip.hover v-bind:title="node.owner.fullname">
                                </center>
                            </b-col>
                            <b-col cols="1">
                                <center style="height: 100%;">
                                    <div style="height: 100%; border: #e2e2e4 solid 0.5px; width: 0.5px;"></div>
                                </center>
                            </b-col>
                            <b-col style="padding-top: 10px;">
                                <span v-html="convertName(node.subusers[0].fullname || '')" v-if="node.subusers[0]" v-b-tooltip.hover v-bind:title="node.subusers[0].fullname" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #eaf8eb; font-weight: 500; font-size: 12px; color: #2ead42; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px;">
                            
                                </span>
                                <span v-html="convertName(node.subusers[1].fullname || '')" v-if="node.subusers[1]" v-b-tooltip.hover v-bind:title="node.subusers[1].fullname" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #f0f3fa; font-weight: 500; font-size: 12px; color: #477ed9; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                                    
                                </span>
                                <span v-html="'+'+(node.subusers.length - 2)" v-if="node.subusers[2]" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #ffeff1; font-weight: 500; font-size: 13px; color: #f13862; padding: 9px; padding-top: 9px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                                    
                                </span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <center>
                                    <h6>
                                        {{ Lang.dashboard.components.service.owner }}
                                    </h6>
                                </center>
                            </b-col>
                            <b-col>
                                <center>
                                    <h6>
                                        {{ Lang.dashboard.components.service.sharedPeople }}
                                    </h6>
                                </center>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-else="">
                <b-row>
                    <b-col>
                        <b-row style="margin-bottom: 5px;">
                            <b-col>
                                <center>
                                    <img v-bind:src="node.owner.image" style="width: 50px; border-radius: 100%;"  v-b-tooltip.hover v-bind:title="node.owner.fullname">
                                </center>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <center>
                                    <h6>
                                        {{ Lang.dashboard.components.service.owner }}
                                    </h6>
                                </center>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            {{ Lang.dashboard.networks.ipAddress }}
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px">
                            {{ node.ip }}
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            {{ Lang.dashboard.networks.node }}
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px; text-transform: uppercase;">
                            {{ node.node }}
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            {{ Lang.dashboard.components.node.cpu }}
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px; text-transform: uppercase;">
                            {{ usage.cpu.toFixed(4) }}%
                        </h6>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            {{ Lang.dashboard.components.node.memory }}
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px; text-transform: uppercase;">
                            {{ (usage.memory.used / 1024).toFixed(3) }} / {{ (usage.memory.total / 1024).toFixed(3) }} GB
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
        </b-card>
    </b-card-group>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {
        name: "nodeCard",
        components: {
            
        },
        data: () => {
            return {
                token: "",
                usage: {
                    "cpu": 0,
                    "memory": {
                        "total": 0,
                        "free": 0,
                        "used": 0
                    }
                }
            }
        },
        props: ['nodes', 'node'],
        mounted() {
            this.token = localStorage.getItem("user-token");
            if(localStorage.getItem("user-token")){
                const token = localStorage.getItem("user-token");
            }
            this.loadUsage();
        },
        cron: {
            method: 'loadUsage',
            time: 15000
        },
        methods: {
            loadUsage(){
                this.$http.get(this.StacketConfig.api.services + `/nodes/` + this.node._id, {headers: {"authorization": this.token}}).then(response => {
                    this.usage = response.data;
                }).catch(err => {
                    console.log(err);
                })
            },
            convertName(name) {
                var fullname = name+"";
                var split = fullname.split(" ");
                if(split.length == 1){
                    return split[0].charAt(0) + split[0].charAt(1);
                } else {
                    var firstName = split[0];
                    var lastName = split[split.length-1];

                    return firstName.charAt(0) + lastName.charAt(0);
                }

            },
        }
    }
</script>