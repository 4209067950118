<template>
    <div style="height: 100%; background-color: #f6f7fb;">
        <b-container fluid style="height: 100%;">
            <frontPageHeader></frontPageHeader>
            <b-row class="justify-content-center" style="background-image: linear-gradient(#026DC9, #5AADFC); min-height: 500px; padding-top: 100px; color: white;">
                <b-col cols="5" class="mt-5">
                    <h1 class="textShadow"><strong>
                        {{ Lang.services.nginx.title }}
                    </strong></h1>
                    <h5 style="margin-top: 15px;" class="textShadow">
                        {{ Lang.services.nginx.subtitle }}
                    </h5>
                    <b-button class="mt-5 shadow-md" :to="(isLoggedIn == true) ? '/dashboard' : '/register'" variant="light" style="width: 30%; line-height: 43px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                        {{ Lang.services.nginx.getStartedButton }}
                    </b-button>
                </b-col>
                <b-col cols="3" class="mt-4">
                    <img src="@/assets/images/minecraftServer.png" height=300px width=533px style="border-radius: 3px;" v-b-tooltip.hover title="Service Overview" class="shadow-xl">
                </b-col>
            </b-row>
            <b-row class="justify-content-center" style="margin-top: 50px;">
                <h1 class="mt-5">{{ Lang.services.nginx.features.title }}</h1>
            </b-row>
            <b-row class="mt-5 mb-5">
                <b-col col="2"></b-col>
                <b-col style="text-align: center;">
                    <h3 style="color: #394047;"><i class="fas fa-chart-line" style="color: #3592E6;"></i> {{ Lang.services.nginx.features.doLess.title }}</h3>
                    <p style="font-size: 1.25rem;">{{ Lang.services.nginx.features.doLess.desc }}</p>
                </b-col>
                <b-col style="text-align: center;">
                    <h3 style="color: #394047;"><i class="fas fa-cubes" style="color: #3592E6;"></i> {{ Lang.services.nginx.features.scaling.title }}</h3>
                    <p style="font-size: 1.25rem;">{{ Lang.services.nginx.features.scaling.desc }}</p>
                </b-col>
                <b-col style="text-align: center;">
                    <h3 style="color: #394047;"><i class="fas fa-stream" style="color: #3592E6;"></i> {{ Lang.services.nginx.features.easy.title }}</h3>
                    <p style="font-size: 1.25rem;">{{ Lang.services.nginx.features.easy.desc }}</p>
                </b-col>
                <b-col col="2"></b-col>
            </b-row>
            <b-row style="background-color: #e8ecfa;" class="justify-content-center">
                <b-col cols="10">
                    <b-row class="justify-content-center">
                        <h2 class="mt-5">{{ Lang.services.nginx.nodeSpecs.title }}</h2>
                    </b-row>
                    <b-row class="justify-content-center" style="padding-top: 25px; margin-bottom: 50px;">
                        <b-col class="shadow" cols="2" style="margin-right: 12.5px; margin-left: 12.5px; background-color: white; text-align: center; border-radius: 10px; border-style: solid; border-color: #026DC9; border-width: 1px; padding: 10px;">
                            <h5>
                                {{ Lang.services.nginx.nodeSpecs.cpu.title }}
                            </h5>
                            <p>
                                {{ Lang.services.nginx.nodeSpecs.cpu.data }}
                            </p>
                        </b-col>
                        <b-col class="shadow" cols="2" style="margin-right: 12.5px; margin-left: 12.5px; background-color: white; text-align: center; border-radius: 10px; border-style: solid; border-color: #026DC9; border-width: 1px; padding: 10px;">
                            <h5>
                                {{ Lang.services.nginx.nodeSpecs.memory.title }}
                            </h5>
                            <p>
                                {{ Lang.services.nginx.nodeSpecs.memory.data }}
                            </p>
                        </b-col>
                        <b-col class="shadow" cols="2" style="margin-right: 12.5px; margin-left: 12.5px; background-color: white; text-align: center; border-radius: 10px; border-style: solid; border-color: #026DC9; border-width: 1px; padding: 10px;">
                            <h5>
                                {{ Lang.services.nginx.nodeSpecs.internet.title }}
                            </h5>
                            <p>
                                {{ Lang.services.nginx.nodeSpecs.internet.data }}
                            </p>
                        </b-col>
                        <b-col class="shadow" cols="2" style="margin-right: 12.5px; margin-left: 12.5px; background-color: white; text-align: center; border-radius: 10px; border-style: solid; border-color: #026DC9; border-width: 1px; padding: 10px;">
                            <h5>
                                {{ Lang.services.nginx.nodeSpecs.ddos.title }}
                            </h5>
                            <p>
                                {{ Lang.services.nginx.nodeSpecs.ddos.data }}
                            </p>
                        </b-col>
                        <b-col class="shadow" cols="2" style="margin-right: 12.5px; margin-left: 12.5px; background-color: white; text-align: center; border-radius: 10px; border-style: solid; border-color: #026DC9; border-width: 1px; padding: 10px;">
                            <h5>
                                {{ Lang.services.nginx.nodeSpecs.drive.title }}
                            </h5>
                            <p>
                                {{ Lang.services.nginx.nodeSpecs.drive.data }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center">
                        <h2 class="mt-5">{{ Lang.services.nginx.packages.title }}</h2>
                    </b-row>
                    <b-row class="justify-content-center mb-3 mt-3" style="margin-left: 5%; margin-right: 5%; padding-top: 25px;">
                        <b-col style="min-width: 220px; margin-right: 12.5px; margin-left: 12.5px" cols="2" v-for="(price, index) of pricing[option]" :key="index">
                            <div class="pricingBox">
                                <h4>${{ (price.monthly / 0.75).toFixed(2) }}<small>/{{ Lang.services.nginx.packages.month }}</small></h4>
                                <p>${{ ((price.monthly / 0.75) / 720).toFixed(4) }}<small>/{{ Lang.services.nginx.packages.hour }}</small></p>
                                <hr>
                                <h5>{{ price.ram }} MB</h5> <span style="font-size: 1.25rem;"> {{ Lang.services.nginx.packages.ram }} </span>
                                <h5>{{ price.disk }} GB</h5> <span style="font-size: 1.25rem;"> {{ Lang.services.nginx.packages.disk }} </span>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <frontPageFooter style="margin-top: 100px;"></frontPageFooter>
        </b-container>
    </div>
</template>

<script>
import frontPageHeader from "@/pages/frontpage/header.vue";
import frontPageFooter from "@/pages/frontpage/footer.vue";
export default {
    name: "Nginx",
    components: {
        frontPageFooter,
        frontPageHeader,
    },
    data: () => {
        return {
            isLoggedIn: true,
            option: "budget",
            pricing: {
                budget: [
                    {
                        monthly: 1.00,
                        ram: 512,
                        disk: 5
                    },
                    {
                        monthly: 1.50,
                        ram: 1024,
                        disk: 10
                    },
                    {
                        monthly: 3.00,
                        ram: 2048,
                        disk: 15
                    },
                    {
                        monthly: 4.50,
                        ram: 3072,
                        disk: 20
                    },
                    {
                        monthly: 6.0,
                        ram: 4096,
                        disk: 25
                    },
                    {
                        monthly: 7.50,
                        ram: 5120,
                        disk: 30
                    },
                    {
                        monthly: 9.00,
                        ram: 6144,
                        disk: 35
                    },
                    {
                        monthly: 10.50,
                        ram: 7168,
                        disk: 40
                    },
                    {
                        monthly: 12.00,
                        ram: 8192,
                        disk: 50
                    },
                    {
                        monthly: 13.50,
                        ram: 9216,
                        disk: 50,
                    },
                    {
                        monthly: 15.0,
                        ram: 10240,
                        disk: 50,
                    }
                ]
            }
        }
    },
    mounted() {
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.isLoggedIn = true;
                } else {
                    this.isLoggedIn = false;
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {

    },
    metaInfo() {
        return {
            meta: [
                {
                    name: "description",
                    content: "At Stacket we offers high quality Nginx Server Hosting at low prices.",
                },
                {
                    property: "title",
                    content: "Stacket | Nginx Server Hosting",
                },
                {
                    name: "og:url",
                    content: "https://stacket.net"
                },
                {
                    property: "og:title",
                    content: "Stacket | Nginx Server Hosting",
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    property: "og:description",
                    content: "At Stacket we offers high quality Nginx Server Hosting at low prices.",
                },
                {
                    name: "robots",
                    content: "index, follow",
                }
            ]
        };
    },
}
</script>

<style lang="scss">
    @mixin disable-selection {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none;   /* Safari */
        -khtml-user-select: none;    /* Konqueror HTML */
        -moz-user-select: none;      /* Firefox */
        -ms-user-select: none;       /* Internet Explorer/Edge */
        user-select: none;           /* Non-prefixed version, currently supported by Chrome and Opera */
    }

    .pricingBox {
        @include disable-selection;
        display: inline-block;
        text-align: center;

        margin-bottom: 50px;
        width: 225px; 
        height: 185px; 
        border-radius: 3px; 
        background-color: white;
        padding: 12.5px;
        h5 {
            display: inline-block;
            margin-left: 15px;
        }
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: 2px solid white;
        transition: all .2s ease-in-out;
    }

    .pricingBox:hover{
        transform: scale(1.05);
    }

</style>