<template>
    <div>
        <b-row class="justify-content-center" style="background-image: linear-gradient(#026DC9, #5AADFC); min-height: 375px; padding-top: 100px; color: white;">
            <b-col cols="7" class="mt-5">
                <small class="textShadow">{{ guide._id }}</small>
                <h2 class="textShadow">{{ guide.title }}</h2>
                <p>
                    {{ guide.description }}
                </p>
                <small>
                    Last Updated {{ formatDate(guide.date) }}
                </small>
                <b-row style="margin-bottom: 10px; margin-top: 25px;">
                    <b-col cols="1" style="min-width: 65px;">
                        <img style="height: 50px; border-radius: 100%;" src="https://betaassets.stacket.net/alexander.png">
                    </b-col>
                    <b-col>
                        <b>
                            {{ guide.author.fullname }}
                        </b>
                        <p>
                            {{ guide.authorRole || guide.author.role }}
                        </p>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row style="margin-top: 50px;">
            <b-col cols="3" style="padding-left: 50px;">
                <h5>
                    <b>Content</b>
                </h5>
                <p v-for="(el, index) of TableOfContents" v-bind:key="index" class="tableContent">
                    <a v-if="el.id" v-bind:href="'#' + el.id" style="text-decoration: none;" class="tableContent">
                        {{ el.text }}
                    </a>
                </p>
            </b-col>
            <b-col cols="5" v-html="guide.content" >
            </b-col>
        </b-row>
    </div>
</template>
<style>
    @import '~github-markdown-css';
    pre {
        background-color: rgba(0, 0, 0, 0.04)!important;
    }
    .tableContent {
        color: gray;
        transition: 0.25s;
        font-weight: 400;
        cursor: pointer;
    }
    .tableContent:hover {
        color: black;
    }
</style>
<script>
    import { parse } from 'node-html-parser';

    export default {
        data: function() {
            return {
                TableOfContents: [],
                guide: {},
            }
        },
        mounted() {
            this.getGuide();
        },
        methods: {
            getTableOfContents(){
                try {
                    var parsedContent = parse(this.guide.content)
                    return parsedContent.childNodes.filter(el => el.tagName != null && el.tagName.toLowerCase().startsWith("h")).map(el => {
                        var obj = { text: el.text.trim() };
                        if(el.attributes.id) obj.id = el.attributes.id;
                        return obj;
                    })
                } catch (e){
                    return []
                }
            },
            getGuide(){
                this.$http.get(this.StacketConfig.api.guides + "/" + this.$route.params.id).then(response => {
                    this.guide = response.data;
                    this.TableOfContents = this.getTableOfContents();
                }).catch(err => {
                    console.log(err);
                })
            },
            formatDate(date){
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"];
                let dateObj = new Date(date);
                let month = monthNames[dateObj.getMonth()];
                let day = String(dateObj.getDate()).padStart(2, '0');
                let year = dateObj.getFullYear();
                return month  + '\n'+ day  + ', ' + year;
            }
        }
    }
</script>