<template>
    <b-container fluid style="color: rgb(73, 80, 87); font-family: 'Roboto';">
        <b-row class="mt-5">
            <b-col cols="1"></b-col>
            <b-col>
                <h1 style="color: #4886FD;">{{ Lang.dashboard.order.newService}}</h1>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>
        <b-row>
            <b-col><hr></b-col>
        </b-row>
        <b-row class="mt-3 mb-5">
            <b-col cols="1"></b-col>
            <b-col>
                <span v-if="newServiceStep == 1">
                    <span class="mt-5" style="margin-left: -35px; display: inline-block; width: 30px; height: 30px; border: 2px solid; border-radius: 50%; line-height: 1.2; padding: 4px; text-align: center; align-self: center; box-sizing: border-box;">
                    1
                    </span>
                    <h3 style="display: inline-block; margin-left: 15px;"> {{ Lang.dashboard.order.service}}</h3>

                    <div>
                            <div :class="(newService.type == 'minecraft') ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.type = 'minecraft'; getVersions(); getPackages(); nextStep();">
                                <img src="@/assets/images/services/minecraft.png">
                                <h5>Minecraft </h5>
                            </div>
                            
                            <div :class="(newService.type == 'terraria') ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.type = 'terraria'; getVersions(); getPackages(); nextStep();">
                                <img src="@/assets/images/services/terraria.png">
                                <h5>Terraria </h5>
                            </div>

                            <div :class="(newService.type == 'factorio') ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.type = 'factorio'; getVersions(); getPackages(); nextStep();">
                                <img src="@/assets/images/services/factorio.png">
                                <h5>Factorio </h5>
                            </div>


                            <div class="creationButtonDisabled" v-b-tooltip.hover title="Contact Support">
                                <img src="@/assets/images/services/csgo.png">
                                <h5>CS:GO</h5>
                            </div>
                            <div :class="(newService.type == 'rust') ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.type = 'rust'; getVersions(); getPackages(); nextStep();">
                                <img src="@/assets/images/services/rust.png">
                                <h5>RUST</h5>
                            </div>


                            <!-- <div :class="(newService.type == 'csgo') ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.type = 'csgo'; getVersions(); getPackages();">
                                <img src="@/assets/images/services/csgo.png">
                                <h5>CS:GO</h5>
                            </div> -->

                            <div :class="(newService.type == 'nodejs') ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.type = 'nodejs'; getVersions(); getPackages(); nextStep();">
                                <img src="@/assets/images/services/nodejs.png">
                                <h5>Node.js</h5>
                            </div>

                            <div :class="(newService.type == 'deno') ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.type = 'deno'; getVersions(); getPackages(); nextStep();">
                                <img src="@/assets/images/services/deno.png">
                                <h5>Deno</h5>
                            </div>

                            <div :class="(newService.type == 'python') ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.type = 'python'; getVersions(); getPackages(); nextStep();">
                                <img src="@/assets/images/services/python.png">
                                <h5>Python</h5>
                            </div>

                            <div :class="(newService.type == 'mysql') ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.type = 'mysql'; getVersions(); getPackages(); nextStep();">
                                <img src="@/assets/images/services/mysql.png">
                                <h5>MySQL</h5>
                            </div>

                            <div :class="(newService.type == 'mongodb') ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.type = 'mongodb'; getVersions(); getPackages(); nextStep();">
                                <img src="@/assets/images/services/mongodb.png">
                                <h5>MongoDB</h5>
                            </div>


                            <div :class="(newService.type == 'nginx') ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.type = 'nginx'; getVersions(); getPackages(); nextStep();">
                                <img src="@/assets/images/services/nginx.png">
                                <h5>Nginx</h5>
                            </div>

                            <div :class="(newService.type == 'nginx_php') ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.type = 'nginx_php'; getVersions(); getPackages(); nextStep();">
                                <img src="@/assets/images/services/nginx.png">
                                <h5>Nginx-PHP</h5>
                            </div>
                            <a href="https://suble.io/" target="_blank">
                                <div class="creationButton">
                                    <img src="@/assets/images/services/vps.png">
                                    <h5 style="color: rgb(73, 80, 87);">VPS </h5>
                                </div>
                            </a>
                        </div>
                </span>
                
                <span v-if="newServiceStep == 2">
                    <b-row>
                        <b-col>
                            <span class="mt-5" style="margin-left: -35px; display: inline-block; width: 30px; height: 30px; border: 2px solid; border-radius: 50%; line-height: 1.2; padding: 4px; text-align: center; align-self: center; box-sizing: border-box;">
                            2
                            </span>
                            <h3 style="display: inline-block; margin-left: 15px;"> {{ Lang.dashboard.order.version}}</h3>

                            <div>
                                <b-tabs content-class="mt-3" class="mt-3">
                                    <b-tab v-for="(versionList, platform) of versions[newService.type]" v-bind:title="platform.replace(/(^.)/, m => m.toUpperCase())" v-bind:key="platform" style="height: 400px; overflow-y: scroll;">
                                        <div>
                                            <div v-for="version in versionList" v-bind:key="version" :class="(newService.platform == platform && newService.version == version) ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.platform = platform; newService.version = version; nextStep();">
                                                <img v-bind:src="platforms[platform]">
                                                <h5>
                                                    <span v-if="platform == 'bungeecord' || platform == 'velocity' || platform == 'waterfall'">#</span>
                                                    {{ version.replace("-Release", "") }}
                                                </h5>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </b-col>
                    </b-row>
                </span>
                <span v-if="newServiceStep == 3">

                    <span class="mt-5" style="margin-left: -35px; display: inline-block; width: 30px; height: 30px; border: 2px solid; border-radius: 50%; line-height: 1.2; padding: 4px; text-align: center; align-self: center; box-sizing: border-box;">
                    3
                    </span>
                    <h3 style="display: inline-block; margin-left: 15px;"> {{ Lang.dashboard.order.location }}</h3>

                    <div>
                        <b-tabs content-class="mt-3" class="mt-3" v-model="activateLocationTab">
                            <b-tab v-bind:title="Lang.dashboard.order.northAmerica">
                                <div>
                                    <div :class="'creationButtonDisabled'" v-b-tooltip.hover v-bind:title="Lang.dashboard.order.outofstock">
                                        <img src="@/assets/images/services/united_states.png">
                                        <h5>Los Angeles</h5>
                                    </div>
                                    <div :class="'creationButtonDisabled'" v-b-tooltip.hover v-bind:title="Lang.dashboard.order.outofstock">
                                        <img src="@/assets/images/services/united_states.png">
                                        <h5>Missouri</h5>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab v-bind:title="Lang.dashboard.order.europe" selected>
                                <div>

                                    <div v-if="isinStock('aad')" @click="location = 'aad'; nextStep();" :class="(location.startsWith('aad')) ? 'creationButton selectedCreationButton' : 'creationButton'" v-b-tooltip.hover v-bind:title="Lang.dashboard.order.denmark">
                                        <img src="@/assets/images/services/denmark.png">
                                        <h5>Aalborg</h5>
                                    </div>

                                    <div v-else :class="'creationButtonDisabled'" v-b-tooltip.hover v-bind:title="Lang.dashboard.order.outofstock">
                                        <img src="@/assets/images/services/denmark.png">
                                        <h5>Aalborg</h5>
                                    </div>

                                    <div :class="'creationButtonDisabled'" v-b-tooltip.hover v-bind:title="Lang.dashboard.order.outofstock">
                                        <img src="@/assets/images/services/germany.png">
                                        <h5>Nuremberg</h5>
                                    </div>

                                    <div :class="'creationButtonDisabled'" v-b-tooltip.hover v-bind:title="Lang.dashboard.order.outofstock">
                                        <img src="@/assets/images/services/germany.png">
                                        <h5>Falkenstein</h5>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </span>
                <span v-if="newServiceStep == 4">
                    <span class="mt-5" style="margin-left: -35px; display: inline-block; width: 30px; height: 30px; border: 2px solid; border-radius: 50%; line-height: 1.2; padding: 4px; text-align: center; align-self: center; box-sizing: border-box;">
                        4
                        </span>
                        <h3 style="display: inline-block; margin-left: 15px;"> {{ Lang.dashboard.order.nodes }}</h3>
        
                    <div>
                        <span v-for="(node, index) of nodes" v-bind:key="index">
                            <span v-if="index.startsWith(location)">
                                <span v-if="privateNodes.find((searchnode) => searchnode.node.toString().toLowerCase() == index.toString().toLowerCase())">
                                    <div v-b-tooltip.hover v-bind:title=" Lang.dashboard.order.privateNode + ': '+ index.toString().toUpperCase()" style="border-bottom-width: 5px; border-bottom-color:#4886FD;" :class="(newService.node == index) ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.node = index; getPackages(); nextStep();">
                                        <img  v-bind:src="locations[location]">
                                        <h5>{{ privateNodes.find((searchnode) => searchnode.node.toString().toLowerCase() == index.toString().toLowerCase()).name }}</h5>
                                    </div>
                                </span>
                                <span v-else>
                                    <div v-if="node.status == 'sold out'" :class="'creationButtonDisabled'" v-b-tooltip.hover v-bind:title="Lang.dashboard.order.outofstock" v-bind:style="'border-bottom-width: 5px; border-bottom-color:' + ((node.status == 'low') ? '#66bb6a' :  (node.status == 'medium') ? '#ffa000' :  (node.status == 'high') ? '#d50000' : '#d50000') + ';'">
                                        <img  v-bind:src="locations[location]">
                                        <h5>{{ index.toString().toUpperCase() }}</h5>
                                    </div>
                                    <div v-else-if="node.note" v-b-tooltip.hover v-bind:title="(node.status == 'low') ? Lang.dashboard.order.usage.low :  (node.status == 'medium') ? Lang.dashboard.order.usage.medium :  (node.status == 'high') ? Lang.dashboard.order.usage.high : Lang.dashboard.order.outofstock" v-bind:style="'border-bottom-width: 5px; border-bottom-color:' + ((node.status == 'low') ? '#66bb6a' :  (node.status == 'medium') ? '#ffa000' :  (node.status == 'high') ? '#d50000' : '#d50000') + ';'" :class="(newService.node == index) ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.node = index; getPackages(); nextStep();">
                                        <img v-bind:src="locations[location]" style="float: left;">
                                        <h5 style="float: left; margin-bottom: 0;">
                                            {{ index.toString().toUpperCase() }}
                                        </h5>
                                        <b-badge v-if="node.note" variant="success" style="font-size: 10px; float: left; margin-left: 15px; margin-bottom: 0;">{{ node.note }}</b-badge>
                                    </div>
                                    <div v-else v-b-tooltip.hover v-bind:title="(node.status == 'low') ? Lang.dashboard.order.usage.low :  (node.status == 'medium') ? Lang.dashboard.order.usage.medium :  (node.status == 'high') ? Lang.dashboard.order.usage.high : Lang.dashboard.order.outofstock" v-bind:style="'border-bottom-width: 5px; border-bottom-color:' + ((node.status == 'low') ? '#66bb6a' :  (node.status == 'medium') ? '#ffa000' :  (node.status == 'high') ? '#d50000' : '#d50000') + ';'" :class="(newService.node == index) ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.node = index; getPackages(); nextStep();">
                                        <img v-bind:src="locations[location]">
                                        <h5>
                                            {{ index.toString().toUpperCase() }}
                                        </h5>
                                    </div>
                                </span>
                            </span>
                        </span>
                    </div>
                </span>
                <span v-if="newServiceStep == 5">
                    <b-row>
                        <b-col>
                            <span class="mt-5" style="margin-left: -35px; display: inline-block; width: 30px; height: 30px; border: 2px solid; border-radius: 50%; line-height: 1.2; padding: 4px; text-align: center; align-self: center; box-sizing: border-box;">
                            5
                            </span>
                            <h3 style="display: inline-block; margin-left: 15px;"> {{ Lang.dashboard.order.package }}</h3>
                            <div v-if="packages[newService.type].error">
                                <b-alert variant="danger" style="margin-top: 25px;" show>
                                    {{ packages[newService.type].error }}
                                </b-alert>
                                <b-button variant="primary" @click="newServiceStep = 4">Go Back</b-button>
                            </div>
                            <div v-else>
                                <div v-for="(pkg, index) of packages[newService.type]" v-bind:key="index" :class="(newService.package == index) ? 'packageButton selectedPackageButton' : 'packageButton'" @click="newService.package = index; newService.packageInfo = pkg; nextStep();">
                                    <h5>
                                        {{ Lang.dashboard.order.priceFormat.replace("$v", (pkg.price * Lang.price).toFixed(2)) }}<small>{{ Lang.dashboard.order.mo }}</small>
                                    </h5><br>
                                    <span class="ml-2">
                                        ${{ ((pkg.price * Lang.price) / 720).toFixed(4) }}<small>{{ Lang.dashboard.order.hr }}</small>
                                    </span>
                                    <hr>
                                    <span v-if="pkg.cpu">
                                        <h5>
                                            {{ pkg.cpu}} CORE{{ pkg.cpu == 1 ? "" : "S"}}
                                        </h5>
                                        <span style="font-size: 1.25rem;">
                                            CPU
                                        </span><br>
                                    </span>
                                    <h5>
                                        {{ pkg.memory }} MB
                                    </h5>
                                    <span style="font-size: 1.25rem;">
                                        RAM
                                    </span><br>
                                    <h5>
                                        {{ pkg.disk }} GB
                                    </h5>
                                    <span style="font-size: 1.25rem;">
                                        Disk
                                    </span>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </span>
                <span v-if="newServiceStep == 6">
                    <b-row>
                        <b-col>
                            <span class="mt-5" style="margin-left: -35px; display: inline-block; width: 30px; height: 30px; border: 2px solid; border-radius: 50%; line-height: 1.2; padding: 4px; text-align: center; align-self: center; box-sizing: border-box;">
                            6
                            </span>
                            <h3 style="display: inline-block; margin-left: 15px;"> {{ Lang.dashboard.order.ownership}}</h3>

                            <div>
                                <div :class="(newService.ownership.type == 'personal') ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.ownership.type = 'personal'">
                                    <img src="@/assets/images/services/user-solid.svg" class="svg">
                                    <h5>{{ Lang.dashboard.order.personal}}</h5>
                                </div>

                                <div :class="(newService.ownership.type == 'team') ? 'creationButton selectedCreationButton' : 'creationButton'" @click="newService.ownership.type = 'team'">
                                    <img src="@/assets/images/services/users-solid.svg" class="svg">
                                    <h5>{{ Lang.dashboard.order.team}}</h5>
                                </div>

                                <div v-if="newService.ownership.type == 'team'" style="width: 225px; margin-left: 275px;">
                                    <b-form-select style="width: 80%; margin-top: 5px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;" v-model="newService.ownership.team">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>{{ Lang.dashboard.order.selectTeam}}</b-form-select-option>
                                        </template>
                                        <b-form-select-option v-for="team in teams" v-bind:key="team" :value="team._id">
                                            {{ team.name }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row style="margin-top: 50px;">
                        <b-col>
                            <div style="text-align: center!important;">
                                <b-button variant="primary" @click="$parent.postService()" class="mt-3" style="width: 80%; line-height: 43px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                                    <i class="fas fa-shopping-cart" style="margin-right: 5px;"></i>
                                    {{ ordering }}
                                    {{ (ordering == true ? Lang.dashboard.order.ordering : Lang.dashboard.order.placeOrder) }}
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </span>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">
    .creationButton {
        display: inline-block;
        margin: 10px;
        width: 225px; 
        height: 75px; 
        border-radius: 3px; 
        background-color: white;
        padding: 12.5px;
        img, h5 {
            display: inline-block;
        }
        img {
            width: 50px;
            height: 50px;
            object-fit: contain;
        }
        h5 {
            margin-left: 15px;
        }
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: 2px solid white;
    }

    .creationButtonDisabled {
        display: inline-block;
        margin: 10px;
        width: 225px;
        height: 75px;
        border-radius: 3px; 
        background-color: white;
        opacity: 0.3;
        padding: 12.5px;
        img, h5 {
            display: inline-block;
        }
        img {
            width: 50px;
            height: 50px;
            object-fit: contain;
        }
        h5 {
            margin-left: 15px;
            color: gray;
        }
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: 2px solid lightgray;
    }

    .creationButton:hover {
        cursor: pointer;
        box-shadow: 0px 0px 10px rgb(106, 158, 255);
    }

    .selectedCreationButton {
        background-color: #4886FD;
        border-color: #4886FD;
        color: white;

        .svg {
            filter: invert(1) contrast(500%);
        }
    }

    .packageButton {
        display: inline-block;
        text-align: center;

        margin: 10px;
        width: 225px; 
        min-height: 175px; 
        border-radius: 3px; 
        background-color: white;
        padding: 12.5px;
        h5 {
            display: inline-block;
            margin-left: 15px;
        }
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: 2px solid white;
    }

    .selectedPackageButton {
        background-color: #4886FD;
        border-color: #4886FD;
        color: white;
    }

    .packageButton:hover {
        cursor: pointer;
        box-shadow: 0px 0px 10px rgb(106, 158, 255);
    }
</style>

<script>
//#d1e1ff
export default {
    name: "new_Service",
    props: [
        "newService",
        "teams",
        "newServiceStep",
        "nextStep",
        "ordering"
    ],
    components: {
        
    },
    data: () => {
        return {
            token: "",
            activateLocationTab: 1,
            snapshots: false,
            prereleases: false,
            privateNodes: [],
            location: "",
            plan: "budget",
            nodes: [],
            versions: {
                minecraft: {},
                nodejs: {},
                nginx: {},
                nginx_php: {},
                csgo: {},
                mysql: {},
                terraria: {},
                deno: {},
                python: {},
                mongodb: {},
                factorio: {},
                rust: {},
                vps: {}
            },
            packages: {
                minecraft: {},
                nodejs: {},
                nginx: {},
                nginx_php: {},
                csgo: {},
                mysql: {},
                terraria: {},
                deno: {},
                python: {},
                mongodb: {},
                factorio: {},
                rust: {},
                vps: {}
            },
            platforms: {
                "spigot": require("@/assets/images/services/versions/spigot.png"),
                "paper": require("@/assets/images/services/versions/paper.png"),
                "vanilla": require("@/assets/images/services/versions/vanilla.png"),
                "bungeecord": require("@/assets/images/services/versions/bungeecord.png"),
                "velocity": require("@/assets/images/services/versions/velocity.png"),
                "waterfall": require("@/assets/images/services/versions/paper.png"),
                "node": require("@/assets/images/services/versions/node.png"),
                "nginx": require("@/assets/images/services/versions/nginx.png"),
                "nginx-php": require("@/assets/images/services/versions/nginx.png"),
                "csgo": require("@/assets/images/services/versions/csgo.png"),
                "mysql": require("@/assets/images/services/versions/mysql.png"),
                "terraria": require("@/assets/images/services/versions/terraria.png"),
                "deno": require("@/assets/images/services/versions/deno.png"),
                "python": require("@/assets/images/services/versions/python.png"),
                "mongo": require("@/assets/images/services/versions/mongodb.png"),
                "factorio": require("@/assets/images/services/versions/factorio.png"),
                "rust": require("@/assets/images/services/versions/rust.png"),
                "ubuntu": require("@/assets/images/services/versions/ubuntu.png"),
                "vps": require("@/assets/images/services/vps.png"),
            },
            locations: {
                "aad": require("@/assets/images/services/denmark.png"),
                "fsn": require("@/assets/images/services/germany.png"),
                "mo": require("@/assets/images/services/united_states.png")
            }
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");

        if(!localStorage.getItem("user-token")){
            return window.location.replace("https://auth.stacket.net?redirect=https://stacket." + this.tld + "/dashboard&response_type=token");
        };

       // this.newService = this.newService;

        //this.getVersions();
        //this.getPackages();
        this.getNodes();
        this.getPrivateNodes();
    },
    computed: {

    },
    methods: {
        isinStock(region){
            for(var node in this.nodes ){
                if(node.toString().toLowerCase().startsWith(region.toString().toLowerCase())){
                    if(this.privateNodes.find(e => e.node == node)) return true;
                    else if(this.nodes[node].status != "sold out") return true;
                }
            }
            return false;
        },
        getPrivateNodes(){
            this.$http.get(this.StacketConfig.api.services + `/nodes`, {headers: {"authorization": this.token}}).then(response => {
                this.privateNodes = response.data;
            }).catch(err => {
                console.log(err);
            })
        },
        resetPackages(){
            this.packages = {
                minecraft: {},
                nodejs: {},
                nginx: {},
                nginx_php: {},
                csgo: {},
                mysql: {},
                terraria: {},
                deno: {},
                python: {},
                mongodb: {},
                vps: {},
            };
        },
        getVersions() {
            let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
            var Vue = this;
            var type = this.newService.type;
            type = type.replace("nginx_php", "nginx-php")
            var nd = this.newService.node.replace("eu-", "").replace("us-", "");
            if(nd == "") nd = "aad4"
            this.$http.get(this.StacketConfig.api.services + "/node/" + nd + "/" + type + "/versions", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data){
                    loader.hide();
                    Vue.versions[Vue.newService.type] = response.data;
                } else {
                    loader.hide();
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch versions.\nTry again later!"
                    })
                }
            });
        },
        getPackages(){
            let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
            var Vue = this;
            var type = this.newService.type;
            type = type.replace("nginx_php", "nginx-php")
            var nd = this.newService.node.replace("eu-", "").replace("us-", "");
            if(nd == "") nd = "aad4"
            this.$http.get(this.StacketConfig.api.services + "/node/" + nd + "/" + type + "/packages", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data){
                    loader.hide();
                    Vue.packages[Vue.newService.type] = response.data;
                } else {
                    loader.hide();
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch packages.\nTry again later!"
                    })
                }
            });
        },
        getNodes() {
            var Vue = this;
            this.$http.get(this.StacketConfig.api.services + "/nodeInfo", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data){
                    Vue.nodes = response.data;
                } else {
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch nodes.\nTry again later!"
                    })
                }
            });
        },
    }
}
</script>