<template>
    <b-row style="background-color: transparent;">
        <b-col>
            <b-row class="justify-content-center">
                <h2 class="mt-5">
                    {{ Lang.frontpage.reviews.title }}
                </h2>
            </b-row>
            <b-row class="mt-3 justify-content-center" style="text-align: center;">
                <b-col v-for="(review, index) of Lang.frontpage.reviews.list" v-bind:key="index" cols="2" class="reviews" style="min-width: 300px;">
                    <b-link router-tag="div" :href="review.link" target="_blank" style="color: #212529; text-decoration: none;">
                        <div style="color: orange; letter-spacing: 10px; font-size: 20px; margin-bottom: 10px;">
                            <i class="fas fa-star" v-if="review.stars >= 1"></i>
                            <i class="fas fa-star" v-if="review.stars >= 2"></i>
                            <i class="fas fa-star" v-if="review.stars >= 3"></i>
                            <i class="fas fa-star" v-if="review.stars >= 4"></i>
                            <i class="fas fa-star" v-if="review.stars >= 5"></i>
                        </div>
                        <p>
                            "{{ review.text }}"
                        </p>
                        <h6 >
                            {{ review.author }}
                        </h6>
                        <p>
                            <timeago v-bind:datetime="review.date"></timeago>
                        </p>
                    </b-link>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<style lang="scss" scoped>
    .fa-check {
        color: #28a745!important;
    }
    .fa-times {
        color: #dc3545!important;
    }
    .reviews {
        padding: 10px;
        background-color: rgba(204, 204, 204, 0.178);
        margin: 15px;
        -webkit-box-shadow: 5px 4px 5px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 5px 4px 5px 0px rgba(0,0,0,0.3);
        box-shadow: 5px 4px 5px 0px rgba(0,0,0,0.3);
        border-radius: 5px;
        transition: all .2s ease-in-out;
    }

    .reviews:hover {
        transform: scale(1.025);
        cursor: pointer;
    }
</style>

<script>
    export default {
        name: "howWeStackUp",
        components: {
            
        },
        data: () => {
            return {
            }
        },
        mounted() {
            
        },
        methods: {

        }
    }
</script>