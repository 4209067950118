<template>
    <div style="height: 100%; background-color: #f6f7fb; ">
        <b-container fluid style="height: 100%;">
            <frontPageHeader></frontPageHeader>
            <b-row class="justify-content-center" style="background-image: linear-gradient(#026DC9, #5AADFC); min-height: 300px; padding-top: 100px; color: white;">
                <b-col cols="5">
                    <h1 class="textShadow"><strong>
                        Terms of service
                    </strong></h1>
                    <h5 style="margin-top: 15px;" class="textShadow">
                        Last updated 28 Feb, 2020
                    </h5>
                </b-col>
                <b-col cols="2" class="mt-5"></b-col>
            </b-row>
            <b-row class="justify-content-center" style="margin-top: 75px;">
                <b-col cols="7">
                    <h1>
                        Overview
                    </h1>
                    <p>
                        This website is operated by Stacket. Throughout the site, the terms “we”, “us” and “our” refer to Stacket. By using Stacket.net you acknowledge that you accept these Terms of Service to their entirety and without reservation. You are also agreeing to our Terms of Service as well as its future modifications and you are responsible for compliance with any applicable laws.
                    </p>
                    <h1 style="margin-top: 50px;">
                        Privacy and Account information
                    </h1>
                    <p>
                        To use stacket.net, you need to be at least 13 years old.
                    </p>
                    <p>
                        Stacket collects information about your Discord account (ID, username, discriminator and email address) and login IPs, we reference this as “Personally Identifiable Information” (PII).
                    </p>
                    <p>
                        Stacket uses your PII to deliver, develop, and improve products, content, and services.
                    </p>
                    <p>
                        External websites and companies which connect to stacket.net websites and products may collect personally identifiable information about users when they visit their websites. Third-party websites may also collect your information as a requirement for their content. Stacket’s terms of service and privacy policy don’t apply on external websites and companies, instead, you must read and agree to the terms of service and privacy policy to those specific websites and companies.
                    </p>
                    <p>
                        Stacket will not sell your personal information to third parties.
                    </p>
                    <h1 style="margin-top: 50px;">
                        General Conditions
                    </h1>
                    <p>
                        We reserve the right to refuse service to anyone for any reason at any time.<br>
                        You understand that your content (this does not include PII), may be transferred encrypted and involve transmissions between our services around the world.
                    </p>
                    <p>
                        You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the Service is provided, without written permission from us.
                    </p>
                    <p>
                        We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.
                    </p>
                    <p>
                        Stacket abides by the Danish purchase law, which declares you have the right to refund up to 14 days after your transaction. When used, this right of withdrawal is waived.
                    </p>
                    <h1 style="margin-top: 50px;">
                        Limitations of Liability
                    </h1>
                    <p>
                        Any use of our services is at your own risk. Stacket, partners, and affiliates will never be held liable for any individual's profits or loss gained on our websites or with our services.
                    </p>
                    <p>
                        Stacket reserves the right to remove any user from our website without and for any reason. We reserve the right to terminate any account that shows suspicious or fraudulent activity or any account that breaks the current Terms of Service.
                    </p>
                    <h1 style="margin-top: 50px;">
                        Minecraft
                    </h1>
                    <p>
                        In order to use any of our Minecraft related services, you must agree to and comply with the Minecraft EULA (<a href="https://account.mojang.com/documents/minecraft_eula">https://account.mojang.com/documents/minecraft_eula</a>)
                    </p>
                    <h1 style="margin-top: 50px;">
                        Cookies
                    </h1>
                    <p style="margin-bottom: 50px;">
                        We only store cookie data that is essential for site operation, this may include token data. (Tokens are randomly generated on logins)
                    </p>
                </b-col>
            </b-row>

        </b-container>
    </div>
</template>

<script>
    import frontPageHeader from "@/pages/frontpage/header.vue";

export default {
    name: "Frontpage",
    components: {
        frontPageHeader
    },
}
</script>

<style lang="scss">
#dashboardButton {
    a {
        color: rgb(230, 230, 230)!important;
    }
    a:hover {
        color: white!important;
    }
}

.textShadow {
    text-shadow: 0px 2px 10px rgba(0,0,0,.175)!important;
}
</style>