<template>
    <div>
        <b-row class="justify-content-center" style="background-image: linear-gradient(#026DC9, #5AADFC); min-height: 375px; padding-top: 100px; color: white;">
            <b-col cols="7" class="mt-5">
                <h2 class="textShadow">
                    {{ Lang.guides.title }}
                </h2>
                <h5 class="textShadow">
                    {{ Lang.guides.text }}
                </h5>
                <b-col cols="6" style="margin-top: 50px; margin-left: -15px!important;">
                    <b-input-group>
                        <template v-slot:prepend>
                            <b-input-group-text >
                                <i class="fas fa-search"></i>
                            </b-input-group-text>
                        </template>
                        <b-form-input v-bind:placeholder="Lang.guides.search"></b-form-input>
                    </b-input-group>               
                </b-col>     
            </b-col>
        </b-row>
        <b-row class="justify-content-center" style="margin-bottom: 50px;">
            <b-col cols="5">
                <h5 class="mt-5">{{ guides.length }} {{ Lang.guides.found }}</h5>
            </b-col>
        </b-row>
        <b-row v-for="(guide, index) of guides" v-bind:key="index" class="justify-content-center guide" @click="$router.push('/guides/' + guide._id)">
            <b-col cols="5">
                <h6 style="color:#026DC9;">{{ Lang.guides.tutorial }}</h6>
                <h3 style="margin-bottom: 25px;">{{ guide.title }}</h3>
                <b-row style="margin-bottom: 10px; color: black;">
                    <b-col cols="1" style="min-width: 65px;">
                        <img style="height: 50px; border-radius: 100%;" v-bind:src="guide.author.image">
                    </b-col>
                    <b-col>
                        <b>
                            {{ guide.author.fullname }}
                        </b>
                        <p>
                            {{ guide.authorRole || guide.author.role }}
                        </p>
                    </b-col>
                </b-row>
                <p style="color: black;">
                    {{ guide.description }}
                </p>
                <p style="color: black;">
                    <small>
                        {{ Lang.guides.lastupdated }} {{ formatDate(guide.date) }}
                    </small>
                </p>
                <hr>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    export default {
        name: "Guides",
        data: () => {
            return {
                guides: [],
            }
        },
        mounted() {
            this.getGuides();
        },
        methods: {
            getGuides(){
                this.$http.get(this.StacketConfig.api.guides + "/").then(response => {
                    this.guides = response.data;
                }).catch(err => {
                    console.log(err);
                })
            },
            formatDate(date){
                const monthNames = this.Lang.guides.monthnames
                let dateObj = new Date(date);
                let month = monthNames[dateObj.getMonth()];
                let day = String(dateObj.getDate()).padStart(2, '0');
                let year = dateObj.getFullYear();
                return month  + '\n'+ day  + ', ' + year;
            }
        }
    }
</script>