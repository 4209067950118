<template>
    <div style="height: 100%; background-color: #f6f7fb; ">
        <b-modal id="deleteService" title="Delete Service" ok-only centered @ok="deleteService()" okTitle="Delete">
            <b-row style="margin-bottom: 25px;">
                <b-col cols="12">
                    <p style="text-align: center;">
                        Are you sure you want to delete your service?<br>
                        <b>{{ deleteServ.name }}</b> will be gone forever (a very long time)!
                    </p>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal id="renameService" title="Rename Service" ok-only centered @ok="renameService()" okTitle="Rename">
            <b-form-group
                label="What would you like to rename your service to?"
            >
                <b-form-input
                    id="networkName"
                    v-model="renameServicedata.name"
                    required
                    placeholder="Service name"
                />
            </b-form-group>
        </b-modal>
        <b-modal id="migrating" v-bind:title="Lang.dashboard.service.settings.general.migrating" ok-only centered @ok="okMigrate()"  v-bind:okTitle="Lang.dashboard.service.settings.general.migratemodal.close">
            <b-row style="margin-bottom: 25px;">
                <b-col cols="12">
                    <log-viewer :log="log" />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-progress :value="progress" max="100" show-progress></b-progress>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal id="migrateConfirm" v-bind:title="Lang.dashboard.service.settings.general.migrate" ok-only centered @ok="migrateNode()" v-bind:okTitle="Lang.dashboard.service.settings.general.migratemodal.confirm">
            <b-form-group v-bind:label="Lang.dashboard.service.settings.general.migratemodal.node">
                <b-form-select v-model="migrate" :options="nodes" v-bind:placeholder="Lang.dashboard.service.settings.general.migratemodal.placeholder"></b-form-select>
            </b-form-group>
        </b-modal>
        <b-modal id="newFolder" @ok="newFolderCreate" v-bind:title="Lang.dashboard.sidebar.modal.newFolder">
            <b-form-group
                v-bind:label="Lang.dashboard.sidebar.modal.folderName"
            >
            <b-form-input
                id="networkName"
                v-model="newFolder.name"
                required
                v-bind:placeholder="Lang.dashboard.sidebar.modal.enterName"
            />
        </b-form-group>
            <b-form-group
                v-bind:label="Lang.dashboard.folders.label"
            >
                <b-dropdown text="Select" class="m-md-2" style="background-color: #4886FD; border-color: #4886FD; border-radius: 4px;" variant="primary">
                    <span v-for="(service, key) in services" v-bind:key="key">
                        <b-dropdown-item v-if="(newFolder.services.find(e => e.toString() == service._id.toString()) != null) == false" @click="newFolder.services.push(service._id)">
                            <b>{{ Lang.dashboard.folders.service }} - </b> {{  service.name }}
                        </b-dropdown-item>
                    </span>
                    <span v-for="(network, key) in networks" v-bind:key="key">
                        <b-dropdown-item v-if="(newFolder.networks.find(e => e.toString() == network._id.toString()) != null) == false" @click="newFolder.networks.push(network._id)">
                            <b>{{ Lang.dashboard.folders.network }} - </b> {{ network.name }}
                        </b-dropdown-item>
                    </span>
                </b-dropdown>
            </b-form-group>
            <b-form-group
                v-bind:label="Lang.dashboard.folders.added + ' (' + (newFolder.services.length + newFolder.networks.length) + ')'"
            >
                <b-list-group>
                    <span v-for="(serviceId, key) in newFolder.services" v-bind:key="key">
                        <b-list-group-item @click="deleteNewFolderThing('services', serviceId)" class="newFolderListItem">
                            {{ services.find(s => s._id == serviceId).name }}
                            <span style="float: right; font-weight: bold;">
                                {{ Lang.dashboard.folders.service }}
                            </span>
                        </b-list-group-item>
                    </span>
                    <span v-for="(networkId, key) in newFolder.networks" v-bind:key="key">
                        <b-list-group-item @click="deleteNewFolderThing('networks', networkId)" class="newFolderListItem">
                            {{ networks.find(s => s._id == networkId).name }}
                            <span style="float: right; font-weight: bold;">
                                {{ Lang.dashboard.folders.network }}
                            </span>
                        </b-list-group-item>
                    </span>
                </b-list-group>
            </b-form-group>
            <b-form-group v-bind:label="Lang.dashboard.folders.color">
                <b-list-group>
                    <b-row>
                        <b-col cols="1">
                            <v-swatches show-fallback v-model="newFolder.color.top"></v-swatches>
                            <v-swatches show-fallback v-model="newFolder.color.bottom"></v-swatches>
                        </b-col>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <folderCard :folder="newFolder" :disks="disks" :services="services" :networks="networks"></folderCard>
                        </b-col>
                    </b-row>
                </b-list-group>
            </b-form-group>
        </b-modal>
        <b-container fluid style="height: 100%; position: absolute; z-index: 99; width: 100%; background-color: rgba(255, 255, 255, 0.7); padding: 0; margin: 0;" v-if="service.status == 'hibernating' && this.$route.params.page && this.$route.params.page != 'starred' && this.$route.params.page != 'shared' && this.$route.params.page != 'teams' && this.$route.params.page != 'disks' && this.$route.params.page != 'support' && this.$route.params.page !='teams' && this.$route.params.page !='networks' && this.$route.params.page != 'new' && this.$route.params.page != 'settings' && this.$route.params.page != 'folders'">
    
            <span v-if="service.owner == user._id && nomoney == true && this.activating == false && this.service || service.owner == user._id && user.balance == 0 && this.activating == false && this.service">
                <b-row align-h="center" style="margin-bottom: 25px; margin-top: 200px;">
                    <b-col cols="5" style="text-align: center;">
                        <i class="fas fa-exclamation" style="font-size: 50px; color: #4886FD; background-color: rgba(0,0,0,0.05); border-radius: 100%; padding: 30px; padding-left: 50px; padding-right: 50px; padding-top: 30px!important;"></i>
                    </b-col>
                </b-row>
                <b-row align-h="center">
                    <b-col cols="5" style="text-align: center;">
                        <h3>Insufficient funds</h3>
                        <p>It looks like you don't have enough money to activate your service,<br>would you like to add more?</p>
                        <br>
                        <b-button variant="primary" to="/dashboard/settings#billing">Go to Billing</b-button>
                        <router-link to="/dashboard" style="text-decoration: none;">
                            <h6 style="margin-top: 20px;"><i class="fas fa-chevron-left"></i> {{ Lang.dashboard.sidebar.myDash }}</h6>
                        </router-link>
                    </b-col>
                </b-row>
            </span>
            <div v-else-if="this.activating == false && this.service" style="border-radius: 4px; padding: 20px; position: absolute; left: 0; right: 0; bottom: 0; top: 0; margin: auto; height: 25%; width: 25%; text-align: center;">
                <span>
                    <h1>Hibernation</h1>
                    <h6>This service must be activated before it is accessible.</h6>
                    <b-button variant="primary" style="margin-top: 15px;" v-on:click="activateService()" v-if="this.activating == false">Activate</b-button>
                    <h2 v-else style="color: #4886FD;"><i class="fas fa-spinner spinnerAnim"></i></h2>
                    <router-link to="/dashboard" style="text-decoration: none;">
                        <h6 style="margin-top: 20px;"><i class="fas fa-chevron-left"></i> {{ Lang.dashboard.sidebar.myDash }}</h6>
                    </router-link>
                </span>
            </div>
            <div v-else-if="this.activating == true && this.service" style="border-radius: 4px; padding: 20px; position: absolute; left: 0; right: 0; bottom: 0; top: 0; margin: auto; height: 25%; width: 25%; text-align: center;">
                <h1>Activating...</h1>
                <h6>{{ ActivateMessage }}</h6>
                <h2 style="color: #4886FD;"><i class="fas fa-spinner spinnerAnim"></i></h2>
                <router-link to="/dashboard" style="text-decoration: none;">
                    <h6 style="margin-top: 20px;"><i class="fas fa-chevron-left"></i> {{ Lang.dashboard.sidebar.myDash }}</h6>
                </router-link>
            </div>
        </b-container>
        <b-container fluid style="height: 100%;">
            <b-row>
                <b-col style="padding-left: 0; padding-right: 0;">
                    <b-navbar type="light" variant="light" style="box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.15); z-index: 50;">
                        <b-navbar-brand style="margin-left: 75px;">
                            <router-link to="/"><img src="@/assets/images/branding/brandingBlue.png" style="margin-bottom: 5px;" height=25px></router-link>
                        </b-navbar-brand>
                        <b-navbar-nav class="ml-auto">
                            <b-nav-item right style="margin-right: 25px;" v-bind:href="promotion.link">
                                {{ promotion.text }}
                            </b-nav-item>
                            <b-nav-item-dropdown right style="margin-right: 10px;">
                                <template v-slot:button-content>
                                    {{ user.fullname }}
                                </template>
                                <b-dropdown-item to="/dashboard/settings">{{ Lang.dashboard.sidebar.settings }}</b-dropdown-item>
                                <b-dropdown-item @click="logout">{{ Lang.dashboard.sidebar.logout }}</b-dropdown-item>
                            </b-nav-item-dropdown>
                            <!--<b-nav-item right>
                                <i class="fas fa-th"></i>
                            </b-nav-item>-->
                        </b-navbar-nav>
                    </b-navbar>
                </b-col>
            </b-row>
            <b-row style="padding-left: 0; padding-right: 0; height: calc(100% - 59px);">
                <b-col cols="2" style="box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.15); background-color: white; min-width: 190px;">
                    <div style="text-align: center!important; margin-top: 30px;">
                        <div v-if="!this.$route.params.page || this.$route.params.page == 'starred' || this.$route.params.page == 'domains' || this.$route.params.page == 'shared' || this.$route.params.page == 'teams' && !this.$route.params.page2 || this.$route.params.page == 'disks' || this.$route.params.page == 'support' || this.$route.params.page == 'networks' || this.$route.params.page == 'nodes' || this.$route.params.page == 'settings' || this.$route.params.page == 'folders'">
                            <b-button to="/dashboard/new" variant="primary" style="margin-left: 10%; width: 70%; line-height: 43px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                                <i class="fas fa-plus-circle" style="margin-right: 5px;"></i>
                                {{ Lang.dashboard.sidebar.newService }}
                            </b-button>
                            <i class="fas fa-folder-plus folderButton" v-b-modal.newFolder></i>
                        </div>
                        <div v-else-if="this.$route.params.page != 'new' && this.$route.params.page == 'teams' && !this.$route.params.page2">
                            <router-link to="/dashboard" class="backToDashboard" style="color: rgb(199, 199, 199); text-decoration: none;">
                                <h6><i class="fas fa-chevron-left"></i> {{ Lang.dashboard.sidebar.myDash }}</h6>
                            </router-link>
                            <b-form-select v-on:change="changeService" style="width: 80%; margin-top: 5px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;" :options="this.serviceOptions" v-model="selected" v-if="this.$route.params.page != 'staff'">
                                <template v-slot:first>
                                    <b-form-select-option :value="null" disabled>Select a Service</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                        <div v-else>
                            <router-link to="/dashboard" class="backToDashboard" style="color: rgb(199, 199, 199); text-decoration: none;">
                                <h6><i class="fas fa-chevron-left"></i> {{ Lang.dashboard.sidebar.myDash }}</h6>
                            </router-link>
                        </div>
                    </div>
                    <div id="overview" style="margin-top: 20px; width: 100%!important; font-size: 20px;" v-if="!this.$route.params.page || this.$route.params.page == 'starred' || this.$route.params.page == 'domains' || this.$route.params.page == 'shared' || this.$route.params.page == 'teams' && !this.$route.params.page2 || this.$route.params.page == 'disks' || this.$route.params.page == 'support' || this.$route.params.page == 'networks' && this.$route.params.page != 'new' || this.$route.params.page == 'settings' || this.$route.params.page == 'nodes' || this.$route.params.page == 'folders'">
                        <router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard" v-if="!this.$route.params.page || this.$route.params.page == 'folders'">
                            <i class="fas fa-cube" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.myDash }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard" v-else="">
                            <i class="fas fa-cube" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.myDash }}
                        </router-link>


                        <router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/teams" v-if="this.$route.params.page == 'teams'">
                            <i class="fas fa-users" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.teams }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/teams" v-else="">
                            <i class="fas fa-users" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.teams }}
                        </router-link>

                        <router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/networks" v-if="this.$route.params.page == 'networks'">
                            <i class="fas fa-network-wired" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.networks }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/networks" v-else="">
                            <i class="fas fa-network-wired" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.networks }}
                        </router-link>

                        <router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/nodes" v-if="this.$route.params.page == 'nodes'">
                            <i class="fas fa-th" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.nodes }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/nodes" v-else="">
                            <i class="fas fa-th" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.nodes }}
                        </router-link>

                        <router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/domains" v-if="this.$route.params.page == 'domains'">
                            <i class="fas fa-globe" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.domains }} <!--<b-badge style="margin-left: 10px;" variant="primary">{{ Lang.dashboard.sidebar.new }}</b-badge>-->
                        </router-link>
                        <router-link tag="div" class="optionNotSelected" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/domains" v-else="">
                            <i class="fas fa-globe" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.domains }} <!--<b-badge style="margin-left: 10px;" variant="primary">{{ Lang.dashboard.sidebar.new }}</b-badge>-->
                        </router-link>


                        <!--<div style="opacity: 0.4; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/images" v-if="this.$route.params.page == 'images'">
                            <i class="far fa-bookmark" style="color: #4886FD; margin-right: 10px;"></i>Images
                        </div>
                        <div class="optionNotSelected" style="opacity: 0.4; color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/images" v-else="">
                            <i class="far fa-bookmark" style="color: #d6d6d6; margin-right: 10px;"></i>Images
                        </div>-->


                        <!--<div style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/backups" v-if="this.$route.params.page == 'backups'">
                            <i class="far fa-file-archive" style="color: #4886FD; margin-right: 10px;"></i>Backups
                        </div>
                        <div class="optionNotSelected" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/backups" v-else="">
                            <i class="far fa-file-archive" style="color: #d6d6d6; margin-right: 10px;"></i>Backups
                        </div>-->


                        <!--<router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/support" v-if="this.$route.params.page == 'support'">
                            <i class="far fa-envelope-open" style="color: #4886FD; margin-right: 10px;"></i>Support
                        </router-link>-->

                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/guides">
                            <i class="fas fa-map-signs" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.guides }}
                        </router-link>

                        <a class="optionNotSelected" tag="div" style="text-decoration: none;" href="https://discord.gg/dNVbXBK" target="_blank">
                            <div style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;">
                                <i class="far fa-envelope-open" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.support }}
                            </div>
                        </a>

                        <router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/settings" v-if="this.$route.params.page == 'settings'">
                            <i class="fas fa-cog" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.settings }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/settings" v-else="">
                            <i class="fas fa-cog" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.settings }}
                        </router-link>

                        <span v-if="user.role == 'developer' || user.role == 'supporter'">
                            <router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/staff/nodes" v-if="this.$route.params.page == 'staff'">
                                <i class="fas fa-tools" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.staff }}
                            </router-link>
                            <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/staff/nodes" v-else>
                                <i class="fas fa-tools" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.staff }}
                            </router-link>
                        </span>
                    </div>
                    <div id="staff" v-else-if="this.$route.params.page == 'staff'">
                        <!-- Nodes -->
                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/staff/nodes'" v-if="this.$route.params.page2 == 'nodes'">
                            <i class="fas fa-network-wired" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.staff.nodes }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/staff/nodes'" v-else>
                            <i class="fas fa-network-wired" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.staff.nodes }}
                        </router-link>

                        <!-- Services -->
                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/staff/services'" v-if="this.$route.params.page2 == 'services'">
                            <i class="fas fa-cube" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.staff.services }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/staff/services'" v-else>
                            <i class="fas fa-cube" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.staff.services }}
                        </router-link>
                    </div>
                    <div id="teams" v-else-if="this.$route.params.page == 'teams' && this.$route.params.page2">
                        <!-- Information -->
                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/teams/' + this.$route.params.page2" v-if="this.$route.params.page2 && !this.$route.params.page3">
                            <i class="fas fa-cube" style="color: #4886FD; margin-right: 10px;"></i>Information
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/teams/' + this.$route.params.page2" v-else>
                            <i class="fas fa-cube" style="color: #d6d6d6; margin-right: 10px;"></i>Information
                        </router-link>

                        <!-- Members -->
                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/teams/' + this.$route.params.page2 + '/members'" v-if="this.$route.params.page3 == 'members'">
                            <i class="fas fa-users" style="color: #4886FD; margin-right: 10px;"></i>Members
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/teams/' + this.$route.params.page2 + '/members'" v-else>
                            <i class="fas fa-users" style="color: #d6d6d6; margin-right: 10px;"></i>Members
                        </router-link>

                        <!-- Groups -->
                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/teams/' + this.$route.params.page2 + '/groups'" v-if="this.$route.params.page3 == 'groups'">
                            <i class="fas fa-object-group" style="color: #4886FD; margin-right: 10px;"></i>Groups
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/teams/' + this.$route.params.page2 + '/groups'" v-else>
                            <i class="fas fa-object-group" style="color: #d6d6d6; margin-right: 10px;"></i>Groups
                        </router-link> 

                        <!-- Services -->
                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/teams/' + this.$route.params.page2 + '/services'" v-if="this.$route.params.page3 == 'services'">
                            <i class="fas fa-layer-group" style="color: #4886FD; margin-right: 10px;"></i>Services
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/teams/' + this.$route.params.page2 + '/services'" v-else>
                            <i class="fas fa-layer-group" style="color: #d6d6d6; margin-right: 10px;"></i>Services
                        </router-link>
                    </div>
                    <div id="overview" v-else-if="this.$route.params.page != 'new' && this.$route.params.page != 'settings'" style="margin-top: 20px; width: 100%!important; font-size: 20px; font-family: Roboto;">
                        <h5 style="color: #495057;">{{ Lang.dashboard.sidebar.service.manage }}</h5>

                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/overview'" v-if="this.$route.params.page2 == 'overview'">
                            <i class="fas fa-cube" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.overview }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/overview'" v-else>
                            <i class="fas fa-cube" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.overview }}
                        </router-link>

                        <span v-if="service.type != 'vm'">
                            <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/files'" v-if="this.$route.params.page2 == 'files' && (hasPermission('service.files.read') || hasPermission('*'))">
                                <i class="fas fa-folder" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.fileManager }}
                            </router-link>
                            <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/files'" v-else-if="(hasPermission('service.files.read') || hasPermission('*'))">
                                <i class="fas fa-folder" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.fileManager }}
                            </router-link>
                        </span>


                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/firewall'" v-if="this.$route.params.page2 == 'firewall'">
                            <i class="fas fa-network-wired" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.firewall }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/firewall'" v-else>
                            <i class="fas fa-network-wired" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.firewall }}
                        </router-link>


                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/history/1'" v-if="this.$route.params.page2 == 'history'">
                            <i class="fas fa-history" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.history }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/history/1'" v-else>
                            <i class="fas fa-history" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.history }}
                        </router-link>


                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/settings'" v-if="this.$route.params.page2 == 'settings' && (hasPermission('service.settings') || hasPermission('*'))">
                            <i class="fas fa-cog" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.settings }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/settings'" v-else-if="hasPermission('service.settings') || hasPermission('*')">
                            <i class="fas fa-cog" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.settings }}
                        </router-link>

                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/subusers'" v-if="this.$route.params.page2 == 'subusers'">
                            <i class="fas fa-user-friends" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.subusers }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/subusers'" v-else>
                            <i class="fas fa-user-friends" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.subusers }}
                        </router-link>

                        <h5 style="color: #495057; margin-top: 10px;">{{ Lang.dashboard.sidebar.service.control }}</h5>

                        <span v-if="service.type == 'vps'">
                            <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/ssh_vnc'" v-if="this.$route.params.page2 == 'ssh_vnc' && (hasPermission('service.console.read') || hasPermission('*') || hasPermission('service.console.command'))">
                                <i class="fas fa-desktop" style="color: #4886FD; margin-right: 10px;"></i>SSH & VNC
                            </router-link>
                            <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/ssh_vnc'" v-else-if="hasPermission('service.console.command') || hasPermission('*') || hasPermission('service.console.read')">
                                <i class="fas fa-desktop" style="color: #d6d6d6; margin-right: 10px;"></i>SSH & VNC
                            </router-link>
                        </span>
                        <span v-else-if="service.type == 'vm'">
                            <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/screen'" v-if="this.$route.params.page2 == 'screen' && (hasPermission('service.console.read') || hasPermission('*') || hasPermission('service.console.command'))">
                                <i class="fas fa-desktop" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.screen }}
                            </router-link>
                            <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/screen'" v-else-if="hasPermission('service.console.command') || hasPermission('*') || hasPermission('service.console.read')">
                                <i class="fas fa-desktop" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.screen }}
                            </router-link>
                        </span>
                        <span v-else>
                            <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/console'" v-if="this.$route.params.page2 == 'console' && (hasPermission('service.console.read') || hasPermission('*') || hasPermission('service.console.command'))">
                                <i class="fas fa-terminal" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.console }}
                            </router-link>
                            <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/console'" v-else-if="hasPermission('service.console.command') || hasPermission('*') || hasPermission('service.console.read')">
                                <i class="fas fa-terminal" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.console }}
                            </router-link>
                        </span>


                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/resize'" v-if="this.$route.params.page2 == 'resize'">
                            <i class="fas fa-layer-group" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.resize }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/resize'" v-else>
                            <i class="fas fa-layer-group" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.resize }}
                        </router-link>

                        <span v-if="service.type != 'vm'">
                            <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/versions'" v-if="this.$route.params.page2 == 'versions'">
                                <i class="fas fa-code-branch" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.version }}
                            </router-link>
                            <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/versions'" v-else>
                                <i class="fas fa-code-branch" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.version }}
                            </router-link>
                        </span>
                    </div>
                    <div v-else-if="this.$route.params.page != 'settings'" style="margin-top: 20px; width: 100%!important; font-size: 20px; font-family: Roboto; color: rgb(73, 80, 87);">
                        <hr>
                        <h4 style="text-align: center;">{{ Lang.dashboard.order.title }}</h4>
                        <hr>
                        <b-row>
                            <b-col>
                                <ul class="events">
                                    <li @click="clickStep(1)" style="cursor: pointer;">
                                        <div v-bind:class="{activeStep: newServiceStep >= 1}"></div> 
                                        <span v-bind:class="{activeStepspan: newServiceStep >= 1}">
                                            <strong>
                                                Step 1
                                            </strong>
                                            {{ Lang.dashboard.order.service }}
                                        </span>
                                    </li>
                                    <li @click="clickStep(2)" style="cursor: pointer;">
                                        <div v-bind:class="{activeStep: newServiceStep >= 2}"></div> 
                                        <span v-bind:class="{activeStepspan: newServiceStep >= 2}">
                                            <strong>
                                                Step 2
                                            </strong>
                                            {{ Lang.dashboard.order.version}}
                                        </span>
                                    </li>
                                    <li @click="clickStep(3)" style="cursor: pointer;">
                                        <div v-bind:class="{activeStep: newServiceStep >= 3}"></div> 
                                        <span v-bind:class="{activeStepspan: newServiceStep >= 3}">
                                            <strong>
                                                Step 3
                                            </strong>
                                            {{ Lang.dashboard.order.location }}
                                        </span>
                                    </li>
                                    <li @click="clickStep(4)" style="cursor: pointer;">
                                        <div v-bind:class="{activeStep: newServiceStep >= 4}"></div> 
                                        <span v-bind:class="{activeStepspan: newServiceStep >= 4}">
                                            <strong>
                                                Step 4
                                            </strong>
                                            {{ Lang.dashboard.order.nodes }}
                                        </span>
                                    </li>
                                    <li @click="clickStep(5)" style="cursor: pointer;">
                                        <div v-bind:class="{activeStep: newServiceStep >= 5}"></div> 
                                        <span v-bind:class="{activeStepspan: newServiceStep >= 5}">
                                            <strong>
                                                Step 5
                                            </strong>
                                            {{ Lang.dashboard.order.package }}
                                        </span>
                                    </li>
                                    <li @click="clickStep(6)" style="cursor: pointer;">
                                        <div v-bind:class="{activeStep: newServiceStep >= 6}"></div> 
                                        <span class="spanNoBefore" v-bind:class="{activeStepspan: newServiceStep >= 6}">
                                            <strong>
                                                Step 6
                                            </strong>
                                            {{ Lang.dashboard.order.ownership }}
                                        </span>
                                    </li>
                                </ul>
                            </b-col>
                        </b-row>
                        <span style="display: none;">
                            <b-row>
                                <b-col>
                                    <h5 class="mt-3">{{ Lang.dashboard.order.service }}</h5>
                                    <h5 class="mt-3" v-if="newService.type == 'minecraft'">{{ Lang.dashboard.order.platform }}</h5>
                                    <h5 class="mt-3">{{ Lang.dashboard.order.version }}</h5>
                                    <br>
                                    <h5 class="mt-3">{{ Lang.dashboard.order.location }}</h5>
                                    <h5 class="mt-3">{{ Lang.dashboard.order.ownership }}</h5>
                                    <h5 class="mt-3">{{ Lang.dashboard.order.paymentRate }}</h5>
                                    <br>
                                    <h5 class="mt-3" v-if="coupon.code != '' && coupon.package == newService.package">Coupon</h5>
                                    <h5 class="mt-3">{{ Lang.dashboard.order.estimatedTotal }}</h5>
                                </b-col>
                                <b-col style="font-size: 1.25rem; line-height: 1.2;">
                                    <p class="mt-3">
                                        {{ serviceNames[newService.type] }}
                                    </p>
                                    <p class="mt-3" style="text-transform: capitalize;" v-if="newService.type == 'minecraft'">
                                        {{ (newService.platform != "Unknown" ? newService.platform : Lang.dashboard.order.unknown) }}
                                    </p>
                                    <p class="mt-3" style="text-transform: capitalize;">
                                        {{ (newService.version != "Unknown" ? newService.version : Lang.dashboard.order.unknown) }}
                                    </p>
                                    <br>
                                    <p class="mt-3">
                                        {{ (newService.node.startsWith("aad")) ? "Aalborg" : (newService.node == 'fsn7') ? 'Falkenstein' : (newService.node == 'fsn8') ? 'Falkenstein' : (newService.node == 'fsn9') ? 'Falkenstein' : (newService.node == 'fsn10') ? 'Falkenstein' : (newService.node == 'eu-nbg1') ? 'Nuremberg' : (newService.node == 'eu-hel1') ? 'Helsinki' : (newService.node == 'us-la1') ? 'Los Angeles' : Lang.dashboard.order.invalid }}
                                    </p>
                                    <p class="mt-3">
                                        {{ (newService.ownership.type == 'personal') ? Lang.dashboard.order["personal"] : (newService.ownership.type == 'team' && newService.ownership.team != null) ? teams.find(team => team._id == newService.ownership.team).name : 'Invalid' }}
                                    </p>
                                    <p class="mt-3" style="text-transform: capitalize;">
                                        {{ Lang.dashboard.order[newService.paymentType.toLowerCase()] }}
                                    </p>
                                    <br>
                                    <p class="mt-3" v-if="coupon.code != '' && coupon.package == newService.package" style="text-overflow: ellipsis; overflow: hidden;" v-b-tooltip.hover v-bind:title="$route.query.coupon">
                                        {{ $route.query.coupon }}
                                    </p>
                                    <p class="mt-3" v-b-tooltip.hover v-bind:title="'$'+(newService.packageInfo.price / 270).toFixed(4)+' is consumed from your balance every hour while the service is activated.'">
                                        {{ Lang.dashboard.order.priceFormat.replace("$v", (newService.packageInfo.price * Lang.price).toFixed(2)) }}<small>{{ Lang.dashboard.order.mo }}</small>*
                                    </p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <div style="text-align: center!important;" v-if="newService.version != 'Unknown' && newService.platform != 'Unknown'" >
                                        <b-button variant="primary" @click="postService()" class="mt-3" style="width: 80%; line-height: 43px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                                            <i class="fas fa-shopping-cart" style="margin-right: 5px;"></i>
                                            {{ Lang.dashboard.order.placeOrder }}
                                        </b-button>
                                    </div>
                                    <div style="text-align: center!important;" v-else="">
                                        <b-button variant="primary" class="mt-3" style="background-color: #4886FD!important; border-color: #4886FD!important; cursor:default; opacity: 0.7; width: 80%; line-height: 43px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                                            <i class="fas fa-shopping-cart" style="margin-right: 5px;"></i>
                                            {{ Lang.dashboard.order.placeOrder }}
                                        </b-button>
                                    </div>
                                </b-col>
                            </b-row>
                        </span>
                    </div>
                    

                    <div id="overview" style="margin-top: 20px; width: 12.5%!important; position: fixed; bottom: 30px; left: 45px;">
                        <h6>{{ Lang.dashboard.sidebar.balance.title }}</h6>
                        <b-progress :value="usedBalance" :max="usedBalance + user.balance"></b-progress>
                        <p style="font-size: 12px; color: #acabab;">{{ Lang.dashboard.order.priceFormat.replace("$v", (usedBalance * Lang.price).toFixed(2)) }} {{ Lang.dashboard.sidebar.balance.of }} {{ Lang.dashboard.order.priceFormat.replace("$v", ((usedBalance + user.balance) * Lang.price).toFixed(2)) }} {{ Lang.dashboard.sidebar.balance.used }} <br><b-link :to="'/dashboard/settings#billing'">{{ Lang.dashboard.sidebar.balance.fillup }}</b-link></p>
                    </div>
                </b-col>
                <b-col>
                    <index v-if="!this.$route.params.page" :user="user" :folders="folders" :disks="disks" :services="services" :networks="networks"></index>
                    <shared v-else-if="this.$route.params.page == 'shared'"></shared>
                    <starred v-else-if="this.$route.params.page == 'starred'"></starred>
                    <teams v-else-if="this.$route.params.page == 'teams'" :teams="teams" :networks="networks" :services="services" :disks="disks"></teams>
                    <staff v-else-if="this.$route.params.page == 'staff'"></staff>
                    <newService v-else-if="this.$route.params.page == 'new'" :nextStep="nextStep" :newServiceStep="newServiceStep" :teams="teams" :newService="newService" ref="newServiceRef"></newService>
                    <networks v-else-if="this.$route.params.page == 'networks'" :teams="teams" :networks="networks" :folders="folders" :services="services"></networks>
                    <domains v-else-if="this.$route.params.page == 'domains'" :teams="teams" :services="services"></domains>
                    <nodes v-else-if="this.$route.params.page == 'nodes'" :teams="teams" :services="services"></nodes>
                    <settings v-else-if="this.$route.params.page == 'settings'" :profile="user" :usedBalance="usedBalance" :disks="disks" :services="services" :lastPayment="lastPayment"></settings>
                    <folder v-else-if="this.$route.params.page == 'folders' && this.$route.params.page2" :user="user" :folder="folders.find(f => f._id == this.$route.params.page2)" :folders="folders" :disks="disks" :services="services" :networks="networks"></folder>
                    <service v-else-if="this.$route.params.page" :key="$route.params.page" ref="serviceRef"></service>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

import index from "@/pages/dashboard/index.vue";
import shared from "@/pages/dashboard/shared.vue";
import starred from "@/pages/dashboard/starred.vue";
import service from "@/pages/dashboard/service.vue";
import networks from "@/pages/dashboard/networks.vue";
import domains from "@/pages/dashboard/domains.vue";
import nodes from "@/pages/dashboard/nodes.vue";
import newService from "@/pages/dashboard/newService.vue";
import settings from "@/pages/dashboard/settings.vue";
import folder from "@/pages/dashboard/folder.vue";
import teams from "@/pages/dashboard/teams.vue";

import staff from "@/pages/dashboard/staff.vue";

import folderCard from '@/components/folderCard.vue';

import VSwatches from 'vue-swatches'

// Import the styles too, typically in App.vue or main.js
import 'vue-swatches/dist/vue-swatches.css'

import logViewer from "@femessage/log-viewer"; 


const io = require("socket.io-client")

export default {
    name: "Dashboard",
    components: {
        index,
        shared,
        starred,
        service,
        newService,
        networks,
        domains,
        nodes,
        staff,
        settings,
        folder,
        folderCard,
        VSwatches,
        teams,
        logViewer
    },
    data: () => {
        return {
            newServiceStep: 1,
            newFolder: {
                name: "",
                services: [],
                networks: [],
                disks: [],
                color: {
                    top: 'rgb(98,168,255)',
                    bottom: 'rgb(22,110,220)'
                }
            },
            coupon: {
                code: "",
                package: "",
                price: 0.00,
            },
            token: "",
            user: {},
            serviceOptions: [
                
            ],
            promotion: {
                text: "",
                link: "",
                date: 0
            },
            usedBalance: 0,
            selected: null,
            access: {},
            teams: [],
            service: {},
            services: [],
            disks: [],
            networks: [],
            folders: [],
            activating: false,
            ActivateMessage: "",
            serviceNames: {
                "minecraft": "Minecraft",
                "csgo": "CS:GO",
                "nodejs": "Node.js",
                "nginx": "Nginx",
                "nginx_php": "Nginx PHP",
                "vps": "VPS",
                "mysql": "MySQL",
                "terraria": "Terraria",
                "deno": "Deno",
                "python": "Python",
                "mongodb": "MongoDB",
                "factorio": "Factorio"
            },
            nomoney: false,
            newService: {
                type: "",
                version: "",
                platform: "",
                node: "aad4",
                paymentType: "hourly",
                package: "",
                packageInfo: {
                    "price": 0,
                    "memory": "0",
                    "disk": 0
                },
                ownership: {
                    type: "personal",
                    team: null
                },
                lastPayment: {}
            },
            nodes: [],
            migrate: "",
            progress: 0,
            log: "",
            socket: null,
            serviceId: "",
            deleteServ: {
                id: "",
                name: ""
            },
            renameServicedata: {
                id: "",
                name: ""
            }
        }
    },
    mounted() {
        if(this.$route.query.auth){
            localStorage.setItem("user-token", this.$route.query.auth);
            this.$route.query.auth = "";
        }
        
        this.token = localStorage.getItem("user-token");

        if(!localStorage.getItem("user-token")){
            return window.location.replace("https://auth.stacket.net?redirect=https://stacket." + this.tld + "/dashboard&response_type=token");
        };

        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(async response => {
                if(response.body.state == "verified"){

                    //COUPON TIME
                    if(this.$route.params.page == "new" && this.$route.query.coupon){
                        this.$http.get(this.StacketConfig.api.services + "/deals/" + this.$route.query.coupon.toString().toLowerCase(), {"token": token}).then(async response => {
                            console.log(response.data)
                            if(response.data.price){
                                this.coupon.code = this.$route.query.coupon.toString();
                                this.coupon.price = response.data.price;
                                this.coupon.package = response.data.package;

                                this.newService.package = this.coupon.package.toString();
                                
                                if(response.data.platform){
                                    this.newService.platform = response.data.platform.toString()
                                }
                                if(response.data.version){
                                    this.newService.version = response.data.version.toString()
                                }
                                if(response.data.packageInfo){
                                    this.newService.packageInfo = response.data.packageInfo;
                                }
                            }

                        }).catch(e => {
                            if(e.data.error){
                                this.$notify({
                                    group: "notifications",
                                    type: 'error',
                                    title: "API ERROR",
                                    text: e.response.data.error,
                                })
                            }
                        });
                    }

                    this.getProfile();
                    this.getServices();
                    this.getFolders();
                    this.getPromotion();
                    this.getPayment();
                    this.getTeams();
                    this.getNetworks();
                } else {
                    localStorage.removeItem("user-token");
                    return window.location.replace("https://auth.stacket.net?redirect=https://stacket." + this.tld + "/dashboard&response_type=token");
                }
            }).catch(err => {
                console.log(err);
            });
        }

    },
    watch: {
        service: function(val) {
            let option = this.serviceOptions.find(option => option.value == val._id);
            option.text = val.name;
        }
    },
    methods: {
        clickStep(step){
            if(this.newServiceStep >= step) return this.newServiceStep = step;
            else return this.$notify({
                group: "notifications",
                type: 'error',
                title: "Error!",
                text: `Please complete the steps above`
            });
        },
        nextStep(){
            this.newServiceStep++;
        },
        deleteService(){
            this.$notify({
                group: "notifications",
                type: 'warning',
                title: "Deleting Service..",
                text: `Please wait`
            });
            this.$http.delete(this.StacketConfig.api.services + "/" + this.deleteServ.id, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Deleted",
                    text: `Successfully deleted service`
                });
                this.getServices();
                this.$router.replace("/dashboard");
            }).catch(err => {
                if(err.data && err.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Error",
                        text: err.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: `Could not delete service!`
                })
            });
        },
        deleteServiceModal(id, name){
            var serv = this.services.find(e => e._id.toString() == id.toString());
            this.deleteServ.id = serv._id;
            this.deleteServ.name = name;
            this.$bvModal.show('deleteService');

        },
        renameService(){
            this.$notify({
                group: "notifications",
                type: 'warning',
                title: "Renaming Service..",
                text: `Please wait`
            });
            this.$http.post(this.StacketConfig.api.services + "/" + this.renameServicedata.id, { name: this.renameServicedata.name }, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.getServices();
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success",
                    text: `Service renamed!`
                });
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: `Could not rename service!`
                })
            });
        },
        renameServiceModal(id){
            var serv = this.services.find(e => e._id.toString() == id.toString());
            this.renameServicedata.id = serv._id;
            this.renameServicedata.name = serv.name;
            this.$bvModal.show('renameService');

        },
        okMigrate(){
            if(this.progress != 100) return this.$bvModal.show('migrateConfirm');
        },
        migrateNode(){
            var Vue = this;
            this.$bvModal.show('migrating');
            this.$notify({
                group: "notifications",
                type: 'warning',
                title: "Migrating Service..",
                text: `Please wait`
            });
            this.$http.post(this.StacketConfig.api.services + "/" + this.serviceId + "/migrate", { node: this.migrate}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    this.$bvModal.hide('migrating');
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                Vue.socket = io(`https://console_devapi.stacket.net:2083/service?serviceId=${Vue.serviceId}&authorization=${Vue.token}`, {
                "transports": ["websocket"]
                });

                Vue.log = "";
                Vue.progress = 0;


                Vue.socket.connect();

                //socket io events
                Vue.log += "\n" + "Connecting to migration API..."
                Vue.socket.on('connect', function(){
                    console.log("Connected to migration api")
                    Vue.log += "\n" + "Connected to migration API"
                });
                Vue.socket.on('migrate', function(file, percentage) {
                    console.log("File: " + file + " - Percentage: " + percentage)
                    Vue.log += "\nUploaded " + file;
                    Vue.progress = percentage;
                });
                Vue.socket.on('disconnect', function(){
                    console.log("Disconnected from migration api")
                    Vue.log += "\n" + "Disconnected from migration API!"
                });
            }).catch(err => {
                this.$bvModal.hide('migrating');
                if(err.data && err.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Error",
                        text: err.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: `Could not migrate service!`
                })
            });
        },
        migrateStart(id){
            this.$http.get(this.StacketConfig.api.services + "/nodeInfo", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data){
                    this.serviceId = id;
                    this.nodes = [];
                    this.migrate = this.services.find(e => e._id.toString() == id);
                    for(var index in response.data){
                        this.nodes.push(index);
                    };
                    this.$bvModal.show('migrateConfirm');
                } else {
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch nodes.\nTry again later!"
                    })
                }
            });
        },
        deleteNewFolderThing(type, id){
            this.newFolder[type] = this.newFolder[type].filter(e => e !== id);
        },
        newFolderCreate(){
            this.$http.post(this.StacketConfig.api.profile + "/folders", this.newFolder, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success",
                    text: "Folder created!"
                });
                this.getFolders();
                this.$router.replace('/dashboard/folders/' + response.data._id);
                this.folders.push(response.data);
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not create folder!"
                })
            });
        },
        logout(){
            localStorage.removeItem("user-token");
            return window.location.replace("https://auth.stacket.net/logout");
        },
        changeService(newId){
            this.$router.push(`/dashboard/${newId}${(this.$route.params.page2 ? '/' + this.$route.params.page2 : '')}`);
        },
        postService(){
            let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
            let obj = this.newService;
            delete obj.packageInfo;

            obj.payment = obj.paymentType;
            delete obj.paymentType;

            if(obj.ownership.team) obj.team = obj.ownership.team;
            delete obj.ownership;

            obj.node = obj.node.replace("eu-", "").replace("us-", "");

            obj.type = obj.type.replace("nginx_php", "nginx-php")

            if(this.coupon.code != '' && this.coupon.package == this.newService.package){
                obj.coupon = this.coupon.code.toString();
            }

            this.$http.post(this.StacketConfig.api.services + "/", obj, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    loader.hide();
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                loader.hide();
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success",
                    text: "Service created!"
                });
                this.$router.replace('/dashboard');
                this.getServices();
                this.newServiceStep = 1;
                this.newService = {
                    type: "",
                    version: "",
                    platform: "",
                    node: "",
                    paymentType: "hourly",
                    package: "",
                    packageInfo: {
                        "price": 0,
                        "memory": "0",
                        "disk": 0
                    },
                    ownership: {
                        type: "personal",
                        team: null
                    },
                    lastPayment: {}
                }
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not create service!"
                })
            });
        },
        getPayment() {
            this.$http.get(this.StacketConfig.api.profile + "/payments/latest", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.lastPayment = response.data;
                this.usedBalance = 0.00;
                if(response.data.services){
                    for(var srv of response.data.services){
                        this.usedBalance += srv.amount;
                    }
                }
                if(response.data.networks){
                    for(var nwk of response.data.networks){
                        this.usedBalance += nwk.amount;
                    }
                }
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch balance!"
                })
            });
        },
        getPromotion () {
            this.$http.get(this.StacketConfig.api.news + "/promotion", {headers: {"authorization": this.token}}).then(async (response) => {
                this.promotion = response.data;
            }).catch(err => {
                this.promotion = {
                    text: "Unable to fetch promotion",
                    link: "/",
                    date: new Date().getTime()
                }
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch promotion!"
                })
            });
        },
        service (value) {
            this.$router.replace(`/dashboard/${value}${(this.$route.params.page2 ? '/' + this.$route.params.page2 : '')}`);
        },
        hasPermission (permission){
            if(!this.access) return false; 
            if(!this.access.permissions) return false;
            return this.access.permissions.includes(permission);
        },
        getNetworks() {
            this.$http.get(this.StacketConfig.api.networks + "/", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.networks = response.data;
                for(var network in this.networks){
                    if(!this.networks[network].team){
                        if(this.networks[network].subusers && this.networks[network].subusers.length != 0){
                            var resp = await this.$http.get(this.StacketConfig.api.networks + `/${this.networks[network]._id}/subusers`, {headers: {"authorization": this.token}});
                            this.networks[network].subusers = resp.data;    
                        }
                    } else {
                        if(this.networks[network].team.groups && this.networks[network].team.groups.length != 0){
                            var resp = await this.$http.get(this.StacketConfig.api.networks + `/${this.networks[network]._id}/groups`, {headers: {"authorization": this.token}});
                            this.networks[network].team.groups = resp.data;    
                        }
                    }
                }
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch networks!"
                })
            });
        },
        getTeams() {
            this.$http.get(this.StacketConfig.api.teams + "/", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.teams = response.data;
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch teams!"
                })
            });
        },
        async fixSubuser(service){
            this.$http.get(this.StacketConfig.api.services + `/${service._id}/subusers`, {headers: {"authorization": this.token}}).then(resp => {
                console.log(resp.data)
                service.subusers = resp.data; 
            }).catch(e => {
                console.error(e);
            })
        },
        async fixGroups(service){
            this.$http.get(this.StacketConfig.api.services + `/${service._id}/groups`, {headers: {"authorization": this.token}}).then(resp => {
                console.log(resp.data)
                service.team.groups = resp.data;
            }).catch(e => {
                console.error(e);
            })
        },
        async fixUsers(service){
            console.log("REE: " + service.name)
            if(!service.team){
                if(service.subusers && service.subusers.length != 0){
                    this.fixSubuser(service);
                }
            } else {
                if(service.team.groups && service.team.groups.length != 0){
                    this.fixGroups(service);
                }
            }
        },
        getServices() {
            this.$http.get(this.StacketConfig.api.services + "/", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.services = response.data;
                for(var _service in this.services){
                    this.serviceOptions.push({value: this.services[_service]._id, text: this.services[_service].name});
                    this.fixUsers(this.services[_service]);
                }
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch services!"
                })
            });
        },
        getFolders() {
            this.$http.get(this.StacketConfig.api.profile + "/folders", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.folders = response.data;
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch folders!"
                })
            });
        },
        getProfile() {
            this.$http.get(this.StacketConfig.api.profile + "/", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.user = response.data;
            });
        },
        activateService(){
            this.activating = true;
            var Vue = this;
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/control", {state: "activate"}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    this.activating = false;
                    this.ActivateMessage = response.data.error.message;
                    if(response.data.error == "Not enough balance to activate service"){
                        this.nomoney = true;
                    }
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                let interval = setInterval(async() => {
                    Vue.getAction(response.data._id, action => {
                        Vue.ActivateMessage = action.message;
                        if(action.status != "running"){
                            clearInterval(interval);
                            Vue.activating = false;
                            this.$refs.serviceRef.updateService();
                        }
                    });
                }, 1000);
            }).catch(err => {
                Vue.activating = false;
                Vue.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not activate service!"
                })
            });
        },
        getAction(id, callback){
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page + "/actions/" + id, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                return callback(response.data);
            }).catch(err => {
                return callback(null);
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch action!"
                })
            });
        },
    }
}
</script>

<style lang="scss">
.events li { 
  display: flex; 
}

.events div { 
  position: relative;
  color: #ccc;
}

.events div::after { 
   content: "";
   position: absolute;
   z-index: 2;
   right: 0;
   top: 0;
   transform: translateX(50%);
   border-radius: 50%;
   background: #fff;
   border: 1px #ccc solid;
   width: .8em;
   height: .8em;
}
.activeStep::after {
    background-color: #4886FD!important;
    border: 1px #4886FD solid;
    border-color: #4886FD!important;
}
.activeStepspan::before {
    border-color: #4886FD!important;
}

.spanNoBefore::before{
    border-left: 1px transparent solid!important;
}


.events span {
  padding: 0 1.5em 1.5em 1.5em;
  position: relative;
}

.events span::before {
   content: "";
   position: absolute;
   z-index: 1;
   left: 0;
   height: 100%;
   border-left: 1px #ccc solid;
}

.events strong {
   display: block;
   font-weight: bolder;
}

.events { margin: 1em; width: 100%; }
.events, 
.events *::before, 
.events *::after { box-sizing: border-box; font-family: arial; }

.folderButton {
    margin-right: 5px;
    font-size: 40px;
    color: rgb(214, 214, 214);
    margin-left: 10px;
    vertical-align: middle;
    cursor: pointer;
    transition: 0.20s;
}
.folderButton:hover {
    color: #4886FD;
}

.newFolderListItem {
    cursor: pointer;
    transition: 0.20s;
}

.newFolderListItem:hover {
    background-color: #dc3545!important;
    border-color: #dc3545!important;
    color: white;
}

.dropdown-toggle {
    background-color: transparent!important;
    border-color: transparent!important;
}

*:focus:not(a){
    outline: none!important;
    box-shadow: none!important;
}

.sidebarSubpageContainer, .sidebarSubpage {
    width: 100%!important;
}

#orgDropdownContainer {
    width: 100%;
}

#orgDropdown:hover {
    background-color: rgb(19, 63, 105)!important;
}

#orgDropdown {
    background-color: transparent!important;
    border-color: transparent!important;
    font-size: 25px!important;
    color: black!important;
    width: 100%;
}

.sidebarSubpage:hover {
    background-color: rgb(19, 63, 105)!important;
    cursor: pointer;
}

.orgDropdownOption, .sidebarSubpage {
    font-size: 17px!important;
    /*color: rgba(255, 255, 255, 0.781)!important;*/
    text-decoration: none!important;
}

.orgDropdownOption:hover, .sidebarSubpage:hover {
    color: black!important;
}

.shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
.shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}
.shadow-none {
    box-shadow: none;
}

.optionNotSelected:hover {
    color: rgb(176, 176, 176)!important;
    
    i {
        color: rgb(176, 176, 176)!important;
    }
}

.backToDashboard:hover {
    color: rgb(171, 171, 171)!important;

    i {
        color: rgb(171, 171, 171)!important;
    }
}

</style>