<template>
    <div style="height: 100%; background-color: #f6f7fb; ">
        <b-container fluid style="height: 100%;">
            <frontPageHeader></frontPageHeader>
            <b-row class="justify-content-center" style="background-image: linear-gradient(#026DC9, #5AADFC); min-height: 500px; padding-top: 100px; color: white;">
                <b-col cols="5" class="mt-5" style="min-width: 300px;">
                    <h2 class="textShadow">
                        {{ Lang.frontpage.title.announcement }}
                    </h2>
                    <h1 class="textShadow"><strong>
                        {{ Lang.frontpage.title.header }}
                    </strong></h1>
                    <h5 style="margin-top: 15px;" class="textShadow">
                        {{ Lang.frontpage.title.text }}
                    </h5>
                    <b-button class="mt-5 shadow-md" :to="'/dashboard'" variant="light" style="min-width: 150px; width: 30%; line-height: 43px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                        {{ Lang.frontpage.title.button }}
                    </b-button>
                </b-col>
                <b-col cols="3" class="mt-4" style="min-width: 300px;">
                    <img src="@/assets/images/stacketDashboard.png" style="width: auto; height: auto; max-width: 533px; max-height: 300px; border-radius: 3px;" v-b-tooltip.hover title="My Dashboard" class="shadow-xl">
                </b-col>
            </b-row>
            <b-row class="justify-content-center" style="margin-top: 50px;">
                <h1 class="mt-5">{{ Lang.frontpage.features.title }}</h1>
            </b-row>
            <b-row class="mt-5 mb-5 justify-content-center">
                <b-col cols="2" style="text-align: center; min-width: 300px; margin-bottom: 25px;">
                    <h3 style="color: #394047;">
                        <i class="fas fa-wallet" style="color: #3592E6;"></i>
                        {{ Lang.frontpage.features.payment.title }}
                    </h3>
                    <p style="font-size: 1.25rem;">
                        {{ Lang.frontpage.features.payment.text }}
                    </p>
                </b-col>
                <b-col cols="2" style="text-align: center; min-width: 300px; margin-bottom: 25px;">
                    <h3 style="color: #394047;">
                        <i class="fas fa-chart-line" style="color: #3592E6;"></i>
                        {{ Lang.frontpage.features.domore.title }}
                    </h3>
                    <p style="font-size: 1.25rem;">
                        {{ Lang.frontpage.features.domore.text }}
                    </p>
                </b-col>
                <b-col cols="2" style="text-align: center; min-width: 300px; margin-bottom: 25px;">
                    <h3 style="color: #394047;">
                        <i class="fas fa-cubes" style="color: #3592E6;"></i>
                        {{ Lang.frontpage.features.scaling.title }}
                    </h3>
                    <p style="font-size: 1.25rem;">
                        {{ Lang.frontpage.features.scaling.text }}
                    </p>
                </b-col>
                <b-col cols="2" style="text-align: center; min-width: 300px; margin-bottom: 25px;">
                    <h3 style="color: #394047;">
                        <i class="fas fa-stream" style="color: #3592E6;"></i>
                        {{ Lang.frontpage.features.easy.title }}
                    </h3>
                    <p style="font-size: 1.25rem;">
                        {{ Lang.frontpage.features.easy.text }}
                    </p>
                </b-col>
            </b-row>
            <howCustomersFeel style="margin-top: 25px;"></howCustomersFeel>
            <howWeStackUp v-if="Lang.howwestackup.enabled == true" style="margin-top: 100px;"></howWeStackUp>
            <b-row class="justify-content-center" style="margin-top: 100px;">
                <b-col cols="2" style="min-width: 300px;">
                    <p>
                        {{ Lang.frontpage.sellingpoints.updates.header }}
                    </p>
                    <h4>
                        {{ Lang.frontpage.sellingpoints.updates.title }}
                    </h4>
                    <br>
                    <h6 style="color: rgba(0, 0, 0, 0.712);">
                        {{ Lang.frontpage.sellingpoints.updates.text }}
                    </h6>
                </b-col>
                <b-col cols="5" style="min-width: 300px;">
                    <img class="shadow-lg" style="height: 350px; border-radius: 10px;" v-b-tooltip.hover title="Version Manager" src="@/assets/images/versions.png">
                </b-col>
            </b-row>
            <b-row class="justify-content-center" style="margin-top: 100px;">
                <b-col cols="5" style="min-width: 300px;">
                    <img class="shadow-lg" style="height: 350px; border-radius: 10px;" v-b-tooltip.hover title="File Manager" src="@/assets/images/ftp.png">
                </b-col>
                <b-col cols="2" style="min-width: 300px;">
                    <p>
                        {{ Lang.frontpage.sellingpoints.security.header }}
                    </p>
                    <h4>
                        {{ Lang.frontpage.sellingpoints.security.title }}
                    </h4>
                    <br>
                    <h6 style="color: rgba(0, 0, 0, 0.712);">
                        {{ Lang.frontpage.sellingpoints.security.text }}
                    </h6>
                </b-col>
            </b-row>
            <b-row class="justify-content-center" style="margin-top: 100px;">
                <b-col cols="2" style="min-width: 300px;">
                    <p>
                        {{ Lang.frontpage.sellingpoints.networking.header }}
                    </p>
                    <h4>
                        {{ Lang.frontpage.sellingpoints.networking.title }}
                    </h4>
                    <br>
                    <h6 style="color: rgba(0, 0, 0, 0.712);">
                        {{ Lang.frontpage.sellingpoints.networking.text }}
                    </h6>
                </b-col>
                <b-col cols="5" style="min-width: 300px;">
                    <img class="shadow-lg" style="height: 350px; border-radius: 10px;" v-b-tooltip.hover title="Private Networking" src="@/assets/images/networking.png">
                </b-col>
            </b-row>
            <b-row class="justify-content-center" style="margin-top: 100px; padding-top: 25px; background-color: #e8ecfa;">
                <b-col cols="4" style="text-align: center; min-width: 300px;">
                    <h2>
                        {{ Lang.frontpage.guides.title }}
                    </h2>
                    <p>
                        {{ Lang.frontpage.guides.text }}
                    </p>
                </b-col>
            </b-row>
            <b-row class="justify-content-center guide" style="padding-top: 50px; background-color: #e8ecfa; cursor: pointer;" @click="$router.push('/guides/' + Lang.frontpage.guides.guide.id )">
                <b-col cols="1"></b-col>
                <b-col cols="5" style="min-width: 300px;">
                    <h6 style="color:#026DC9;">
                        {{ Lang.frontpage.guides.guide.type }}
                    </h6>
                    <h3 style="margin-bottom: 25px;">
                        {{ Lang.frontpage.guides.guide.title }}
                    </h3>
                    <b-row style="margin-bottom: 10px; color: black;">
                        <b-col cols="1" style="min-width: 65px;">
                            <img alt="user" style="height: 50px; border-radius: 100%;" v-bind:src="Lang.frontpage.guides.guide.author.image">
                        </b-col>
                        <b-col>
                            <b>
                                {{ Lang.frontpage.guides.guide.author.name }}
                            </b>
                            <p>
                                {{ Lang.frontpage.guides.guide.author.role }}
                            </p>
                        </b-col>
                    </b-row>
                    <p style="color: black;">
                        {{ Lang.frontpage.guides.guide.text }}
                    </p>
                    <p style="color: black;">
                        <small>
                            {{ Lang.frontpage.guides.guide.updated }}
                        </small>
                    </p>
                </b-col>
            </b-row>
            <b-row class="justify-content-center" style="background-color: #e8ecfa;">
                <b-col cols="3" style="text-align: center; min-width: 300px;">
                    <p>
                        <router-link to="/guides" style="text-decoration: none;">{{ Lang.frontpage.guides.link }}</router-link>
                    </p>
                </b-col>
            </b-row>
            <frontPageFooter></frontPageFooter>
        </b-container>
    </div>
</template>

<style>
.shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.shadow-sm  {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
.shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}
.shadow-none {
    box-shadow: none;
}
</style>
<script>
    import howWeStackUp from "@/components/howWeStackUp.vue";
    import frontPageFooter from "@/pages/frontpage/footer.vue";
    import howCustomersFeel from "@/components/howOurCustomersfeel.vue";
    import frontPageHeader from "@/pages/frontpage/header.vue";

export default {
    name: "Frontpage",
    components: {
        howWeStackUp,
        frontPageFooter,
        frontPageHeader,
        howCustomersFeel
    },
    data: () => {
        return {
        }
    },
    metaInfo() {
        return {
            meta: [
                {
                    name: "description",
                    content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                },
                {
                    property: "title",
                    content: "Stacket | Hosting done the right way",
                },
                {
                    property: "keywords",
                    content: "Minecraft Hosting, Minecraft Server Hosting, Cheap, Dansk Minecraft Server Hosting, Billig Minecraft Hosting, Billig Minecraft Server Hosting, Dansk Minecraft Hosting, mc server, Minecraft Server, DDoS Protection, Stacket, ARK, Survival Evolved, Ark Survival Evolved, CSGO, CS:GO, Counter Strike, Global Offensive, Counter Strike Global Offensive, CSGO Server, CSGO Server Hosting, CS:GO hosting, CS:GO server, Hosting, Website hosting, Nginx Hosting, MongoDB hosting, MySQL Hosting, Factorio Hosting, Terraria Hosting, Deno Hosting, Deno Server Hosting, Python Hosting"
                },
                {
                    name: "og:url",
                    content: "https://stacket.net"
                },
                {
                    property: "og:title",
                    content: "Stacket | Hosting done the right way",
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    property: "og:description",
                    content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                },
                {
                    name: "robots",
                    content: "index, follow",
                }
            ]
        };
    },
    mounted() {
        if(this.$route.params.page.toLowerCase().includes("auth=") == true){
            var split = this.$route.params.page.toString().split("auth=");
            if(split[1]){
                this.$router.push("/dashboard?auth=" + split[1])
            }
        }
    },
    methods: {

    }
}
</script>

<style lang="scss">
#dashboardButton {
    a {
        color: rgb(230, 230, 230)!important;
    }
    a:hover {
        color: white!important;
    }
}

.textShadow {
    text-shadow: 0px 2px 10px rgba(0,0,0,.175)!important;
}
</style>