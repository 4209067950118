<template>
    <b-container fluid>
        <b-row style="margin-top: 50px; color: rgb(73, 80, 87)!important;">
            <b-col cols="1"></b-col>
            <b-col class="mt-4" style="min-width: 375px; margin-bottom: 25px;">
                <b-row>
                    <b-col cols="2">
                        <router-link to="/dashboard/networks" style="text-decoration: none;">
                            <h6 style="margin-top: 20px;"><i class="fas fa-chevron-left"></i> {{ Lang.dashboard.networks.networks }}</h6>
                        </router-link>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <h2 v-if="!networkEditing">{{ network.network.name }}</h2>
                        <h2 v-else><b-form-input v-model="network.network.name" placeholder="Enter network name" style="font-size: 2rem; font-weight: 500;"></b-form-input></h2>
                    </b-col>
                    <b-col>
                        <span @click="toggleNetworkEditing()" v-if="!networkEditing"><i class="fas fa-edit editIcon mt-2" style="color:lightgray; font-size: 1.5rem;"></i></span>
                        <span @click="toggleNetworkEditing()" v-if="networkEditing"><i class="fas fa-save saveIcon mt-2" style="color:lightgray; font-size: 1.5rem;"></i></span>
                        <span style="text-align: center;" @click="deleteNetwork()"><i class="fas fa-trash-alt trashIcon" style="margin-left: 15px; color:lightgray; font-size: 1.5rem"></i></span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p>{{ network.network._id }}</p>
                    </b-col>
                </b-row>
                <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px; min-height: 400px;">
                    <b-row>
                        <b-col cols="6">
                            <b-form-select style="width: 80%; margin-top: 5px; margin-right: 10px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;" v-model="connectId">
                                <template v-slot:first>
                                    <b-form-select-option :value="''" disabled>{{ Lang.dashboard.networks.selectService }}</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="service in services" v-bind:key="service._id" :value="service._id">
                                    {{ service.name }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-button variant="success" @click="connect(network.network._id, connectId)" style="margin-top: 5px;">{{ Lang.dashboard.networks.connect }}</b-button>
                        </b-col>
                    </b-row>
                    <b-row style="margin-top: 15px;">
                        <b-col cols="3" style="min-width: 375px; margin-bottom: 25px;" v-for="(service, index) of services.filter(service => service.networks.find(net => net.id == network.network._id))" v-bind:key="index">
                            <networkServiceCard :service="service" :network="network"></networkServiceCard>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">

</style>

<script>
    import networkServiceCard from "@/components/networkServiceCard.vue";

    export default {
        name: "network",
        components: {
            networkServiceCard
        },
        data: () => {
            return {
                token: "",
                network: {},
                connectId: "",
                networkEditing: false,
            }
        },
        props: [
            "teams",
            "networks",
            "services"
        ],
        mounted() {
            this.token = localStorage.getItem("user-token");
            if(localStorage.getItem("user-token")){
                const token = localStorage.getItem("user-token");

                this.getNetwork();
            }
        },
        methods: {
            getNetwork() {
                let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
                this.$http.get(this.StacketConfig.api.networks + "/" + this.$route.params.page2, {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        loader.hide();
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        })
                    }
                    this.network = response.data;
                    loader.hide();
                }).catch(err => {
                    loader.hide();
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch network!"
                    })
                });
            },
            deleteNetwork(){
                this.$http.delete(this.StacketConfig.api.networks + `/${this.network.network._id}`, {headers: {"authorization": this.token}}).then(response => {
                    if(response.data.error) {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        });
                    }
                    this.$parent.$parent.getNetworks();
                    this.$router.replace('/dashboard/networks');
                }).catch(err => {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not delete network!"
                    });
                })
            },
            connect(network, service) {
                console.log("Connecting " + service + ", please wait...");
                this.$http.get(this.StacketConfig.api.networks + `/${network}/connect/${service}`, {headers: {"authorization": this.token}}).then(response => {
                    if(response.data.error) {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        });
                    }
                    this.getNetwork();
                    this.$parent.$parent.getServices();
                    this.$parent.$parent.getNetworks();
                }).catch(err => {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not connect service!"
                    });
                })
            },
            disconnect(network, service) {
                console.log("Disconnecting " + service + ", please wait...");
                this.$http.get(this.StacketConfig.api.networks + `/${network}/disconnect/${service}`, {headers: {"authorization": this.token}}).then(response => {
                    if(response.data.error) {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        });
                    }
                    this.getNetwork();
                    this.$parent.$parent.getServices();
                    this.$parent.$parent.getNetworks();
                }).catch(err => {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not disconnect service!"
                    });
                })
            },
            toggleNetworkEditing() {
                if(this.networkEditing == true) {
                    this.networkEditing = !this.networkEditing;
                    this.$http.post(this.StacketConfig.api.networks + `/${this.network.network._id}`, {name: this.network.network.name}, {headers: {"authorization": this.token}}).then(response => {
                        if(response.data.error) {
                            this.$notify({
                                group: "notifications",
                                type: 'error',
                                title: "API ERROR",
                                text: response.data.error
                            });
                        }
                        this.getNetwork();
                        this.$parent.$parent.getServices();
                        this.$parent.$parent.getNetworks();
                    }).catch(err => {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: "Could not update network!"
                        });
                    });
                } else {
                    this.networkEditing = !this.networkEditing;
                }
            }
        }
    }
</script>