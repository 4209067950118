<template>
    <b-container fluid>
        <b-row style="margin-top: 50px;">
            <overview v-if="this.$route.params.page2 == 'overview' && service" :usage="usage" :network="network" :packages="packages" :key="service" :service="service"></overview>
            <files v-else-if="this.$route.params.page2 == 'files' && service" :network="network" :service="service"></files>
            <firewall v-else-if="this.$route.params.page2 == 'firewall' && service" :network="network" :service="service"></firewall>
            <history v-else-if="this.$route.params.page2 == 'history' && service" :service="service"></history>
            <settings v-else-if="this.$route.params.page2 == 'settings' && service" :service="service"></settings>
            <console v-else-if="this.$route.params.page2 == 'console' && service" :service="service"></console>
            <ssh_vnc v-else-if="this.$route.params.page2 == 'ssh_vnc' && service" :service="service"></ssh_vnc>
            <!-- Power -->
            <resize v-else-if="this.$route.params.page2 == 'resize' && service" :service="service"></resize>
            <versions v-else-if="this.$route.params.page2 == 'versions' && service" :service="service"></versions>
            <subusers v-else-if="this.$route.params.page2 == 'subusers' && service" :service="service"></subusers>
        </b-row>
    </b-container>
</template>
<script>

</script>

<style lang="scss">
    @-webkit-keyframes rotating /* Safari and Chrome */ {
        from {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    .spinnerAnim {
        -webkit-animation: rotating 1s linear infinite;
        -moz-animation: rotating 1s linear infinite;
        -ms-animation: rotating 1s linear infinite;
        -o-animation: rotating 1s linear infinite;
        animation: rotating 1s linear infinite;
    }
</style>

<script>
import overview from '@/pages/dashboard/service/overview.vue';
import files from '@/pages/dashboard/service/files.vue';
import firewall from '@/pages/dashboard/service/firewall.vue';
import history from '@/pages/dashboard/service/history.vue';
import settings from '@/pages/dashboard/service/settings.vue';
import console from '@/pages/dashboard/service/console.vue';
import ssh_vnc from '@/pages/dashboard/service/ssh_vnc.vue';
//Power
import resize from '@/pages/dashboard/service/resize.vue';
import versions from '@/pages/dashboard/service/versions.vue';
import subusers from '@/pages/dashboard/service/subusers.vue';

export default {
    name: "Service",
    components: {
        overview,
        files,
        firewall,
        history,
        settings,
        console,
        ssh_vnc,
        //Power
        resize,
        versions,
        subusers
    },
    data: () => {
        return {
            token: "",
            service: {},
            packages: {},
            history: [],
            usage: {
                cpu: {
                    usage: 0,
                },
                disk: {
                    usage: 0,
                    total: 1,
                },
                memory: {
                    usage: 0,
                    total: 1
                }
            },
            network: {
                "node": "aad",
                "ip": "127.0.0.1",
                "sftp": false
            }
        }
    },
    mounted() {
        this.$parent.service = null;
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.getService();
                    this.getPackages();
                    this.getNetwork();
                } else {
                    localStorage.removeItem("user-token");
                    return window.location.replace("https://auth.stacket.net?redirect=https://stacket." + this.tld + "/dashboard&response_type=token");
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    cron: {
        method: 'updateService',
        time: 10000
    },
    methods: {
        updateService(){
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.service = response.data;
                this.$parent.selected = this.service.service._id;
                this.$parent.access = this.service.access;
                this.$parent.service = this.service.service
                if(!this.service.service.team){
                    if(this.service.service.subusers && this.service.service.subusers.length != 0){
                        var resp = await this.$http.get(this.StacketConfig.api.services + `/${this.service.service._id}/subusers`, {headers: {"authorization": this.token}});
                        this.service.service.subusers = resp.data;    
                    }
                } else {
                    if(this.service.service.team.groups && this.service.service.team.groups.length != 0){
                        var resp = await this.$http.get(this.StacketConfig.api.services + `/${this.service.service._id}/groups`, {headers: {"authorization": this.token}});
                        this.service.service.team.groups = resp.data;    
                    }
                }
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch service!"
                })
            });
        },
        getUsage(){
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page + "/usage", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.usage = response.data;
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch resource usage!"
                })
            });
        },
        getAction(id){
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page + "/actions/" + id, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                return response.data;
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch action!"
                })
            });
        },
        getActions(){
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page + "/actions?limit=13", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                if(this.history != response.data) this.history = response.data;
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch history!"
                })
            });
        },
        getNetwork(){
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page + "/ip", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.network = response.data;
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch network information!"
                })
            });
        },
        getService(){
            var Vue = this;
            let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    loader.hide();
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.service = response.data;
                this.$parent.selected = this.service.service._id;
                this.$parent.access = this.service.access;
                this.$parent.service = this.service.service
                if(!this.service.service.team){
                    if(this.service.service.subusers && this.service.service.subusers.length != 0){
                        var resp = await this.$http.get(this.StacketConfig.api.services + `/${this.$route.params.page}/subusers`, {headers: {"authorization": this.token}});
                        this.service.service.subusers = resp.data;    
                    }
                } else {
                    if(this.service.service.team.groups && this.service.service.team.groups.length != 0){
                        var resp = await this.$http.get(this.StacketConfig.api.services + `/${this.$route.params.page}/groups`, {headers: {"authorization": this.token}});
                        this.service.service.team.groups = resp.data;    
                    }
                }
                
                loader.hide();

            }).catch(err => {
                loader.hide();
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch services!"
                })
            });
        },
        getPackages() {
            this.$http.get(this.StacketConfig.api.services + `/${this.$route.params.page}/packages`, {headers: {"authorization": this.token}}).then((response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.packages = response.data;
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch service packages!"
                })
            });
        },
        reactivateService(){
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/control", {state: "reactivate"}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.history.unshift(response.data);
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not reactivate service!"
                })
            });
        },
        startService(){
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/control", {state: "start"}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.history.unshift(response.data);
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not start service!"
                })
            });
        },
        stopService(){
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/control", {state: "stop"}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.history.unshift(response.data);
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not stop service!"
                })
            });
        },
        killService(){
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/control", {state: "kill"}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.history.unshift(response.data);
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not kill service!"
                })
            });
        },
        hibernateService(){
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/control", {state: "hibernate"}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.history.unshift(response.data);
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not hibernate service!"
                })
            });
        },
    }
}  
</script>