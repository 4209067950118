<template>
    <div style="height: 100%; background-color: #f6f7fb;">
        <b-container fluid style="height: 100%;">
            <frontPageHeader></frontPageHeader>
            <b-row class="justify-content-center" style="background-image: linear-gradient(#026DC9, #5AADFC); min-height: 500px; padding-top: 100px; color: white;">
                <b-col cols="5" class="mt-5">
                    <h1 class="textShadow"><strong>
                        {{ Lang.deals.title }}
                    </strong></h1>
                    <h5 style="margin-top: 15px;" class="textShadow">
                        {{ Lang.deals.text }}
                    </h5>
                </b-col>
                <b-col cols="3" class="mt-4">
                    <img src="@/assets/images/minecraftServer.png" height=300px width=533px style="border-radius: 3px;" v-b-tooltip.hover v-bind:title="Lang.deals.imageText" class="shadow-xl">
                </b-col>
            </b-row>
            <b-row class="justify-content-center" style="margin-top: 50px;">
                <h1 class="mt-5">{{ Lang.deals.subTitle }}</h1>
            </b-row>
            <b-row class="justify-content-center mt-5 mb-5">
                <b-col cols="6" style="background-color: white; border-style: solid; border-color: rgba(0,0,0,0.075); border-radius: 10px;">
                    <b-table striped hover :fields="fields" :items="deals">
                        <template v-slot:cell(coupon)="data">
                            <b-button target="_blank" href="https://discord.gg/dNVbXBK" v-if="data.value == 'Contact Staff'">
                                {{ data.value }}
                            </b-button>
                            <b-button target="_blank" v-bind:href="'/dashboard/new?coupon=' + data.value" variant="primary" v-else-if="isLoggedIn == true">
                                {{ data.value}}
                            </b-button>
                            <b-button target="#" v-b-tooltip.hover title="Login to continue" variant="primary" v-else>
                                {{ data.value}}
                            </b-button>
                        </template>
                        <template v-slot:cell(price)="data">
                            ${{ data.value }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <frontPageFooter style="margin-top: 100px;"></frontPageFooter>
        </b-container>
    </div>
</template>

<script>
import frontPageHeader from "@/pages/frontpage/header.vue";
import frontPageFooter from "@/pages/frontpage/footer.vue";
export default {
    name: "Deals",
    components: {
        frontPageFooter,
        frontPageHeader,
    },
    data: () => {
        return {
            isLoggedIn: false,
            fields: [
                {
                    key: 'type',
                    label: 'Product',
                    sortable: true
                },
                {
                    key: 'coupon',
                    label: 'Coupon',
                    sortable: false
                },,
                {
                    key: 'package',
                    label: 'Package',
                    sortable: false
                },,
                {
                    key: 'price',
                    label: 'Price',
                    sortable: true
                },,
                {
                    key: 'expires',
                    label: 'Expires',
                    sortable: false
                },
            ],
            deals: [
                /*{
                    type: "Minecraft",
                    coupon: 'MinecraftSummer',
                    package: '4 GB',
                    price: 4.99,
                    expires: "11. August"
                },
                {
                    type: "Private Node",
                    coupon: 'Contact Staff',
                    package: '32 GB',
                    price: 40.00
                }*/
            ]
        }
    },
    mounted() {
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.isLoggedIn = true;
                } else {
                    this.isLoggedIn = false;
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {

    }
}
</script>

<style lang="scss">
    @mixin disable-selection {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none;   /* Safari */
        -khtml-user-select: none;    /* Konqueror HTML */
        -moz-user-select: none;      /* Firefox */
        -ms-user-select: none;       /* Internet Explorer/Edge */
        user-select: none;           /* Non-prefixed version, currently supported by Chrome and Opera */
    }

    .pricingBox {
        @include disable-selection;
        display: inline-block;
        text-align: center;

        margin-bottom: 50px;
        width: 225px; 
        height: 185px; 
        border-radius: 3px; 
        background-color: white;
        padding: 12.5px;
        h5 {
            display: inline-block;
            margin-left: 15px;
        }
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: 2px solid white;
        transition: all .2s ease-in-out;
    }

    .pricingBox:hover{
        transform: scale(1.05);
    }

</style>