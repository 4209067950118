<template>
    <div>
        <b-row class="justify-content-center" style="margin-top: 50px;">
            <b-col cols="2" style="min-width: 300px;">
                <h2>
                    <b>{{ Lang.footer.company.name }}</b>
                </h2>
                <p>
                    <b>{{ Lang.footer.company.cvr }}: </b><a style="text-decoration: none; color: black;" rel="noreferrer" href="https://datacvr.virk.dk/data/index.php?enhedstype=virksomhed&id=41363142&soeg=41363142&q=visenhed" target="_blank">41363142</a>
                </p>
                <p>
                    {{ Lang.footer.company.address }}
                </p>
            </b-col>
            <b-col cols="5" style="text-align: center; min-width: 300px;">
                <h2>
                    {{ Lang.footer.getstarted.title }}
                </h2>
                <b-button class="mt-3 shadow-md" :to="($parent.isLoggedIn == true) ? '/dashboard' : '/register'" variant="primary" style="width: 40%; line-height: 43px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;" v-if="!this.$route.params.page || this.$route.params.page == 'starred' || this.$route.params.page == 'shared' || this.$route.params.page == 'teams' || this.$route.params.page == 'backups' || this.$route.params.page == 'support' || this.$route.params.page =='networks' && this.$route.params.page != 'new'">
                    {{ ($parent.isLoggedIn == true) ? Lang.footer.getstarted.button.loggedin : Lang.footer.getstarted.button.new }}
                </b-button>
            </b-col>
            <b-col cols="2" style="min-width: 300px;">
                <h2>
                    <b>{{ Lang.footer.links.title }}</b>
                </h2>
                <p>
                    <b>
                        -
                    </b>
                    <a style="text-decoration: none; color: #212529;" rel="noreferrer" href="/dashboard" target="_blank">
                        {{ Lang.footer.links.dashboard }}
                    </a>
                </p>
                <p>
                    <b>
                        -
                    </b>
                    <a style="text-decoration: none; color: #212529;" rel="noreferrer" href="/tos" target="_blank">
                        {{ Lang.footer.links.tos }}
                    </a>
                </p>
                <p>
                    <b>
                        -
                    </b>
                    <a style="text-decoration: none; color: #212529;" rel="noreferrer" href="https://discord.gg/dNVbXBK" target="_blank">
                        {{ Lang.footer.links.discord }}
                    </a>
                </p>
            </b-col>
        </b-row>
        <b-row class="mb-3">
            <b-col style="text-align: center;">
                {{ Lang.footer.copyright }}
            </b-col>
            <!--
                <b-col style="text-align: center;">
                    Copyright 2019-{{ new Date().getFullYear() }} © Stacket. All rights reserved.
                </b-col>
            -->
        </b-row>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
export default {
    name: "footer",
    components: {
        
    },
    data: () => {
        return {
            
        }
    },
    mounted() {
        
    },
    methods: {

    }
}
</script>