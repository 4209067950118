<template>
    <b-container fluid v-if="!this.$route.params.page3 && team.name">
        <b-row style="margin-top: 25px;">
            <b-col>
                <h3>
                    Team Information
                </h3>
                <p>
                    Here you can give your team a name and an icon.
                </p>
            </b-col>
        </b-row>
        <b-row style="margin-top: 25px;">
            <b-col>
                <h5>
                    Team Details
                </h5>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="2">
                <p>
                    Icon
                </p>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col>
                <p>
                    Name
                </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="2">
                <img v-bind:src="team.image" style="width: 100%;">
            </b-col>
            <b-col cols="1"></b-col>
            <b-col>
                <b-input-group size="md">
                    <b-form-input v-model="team.name" placeholder="Enter your team name"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary">Save</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row style="margin-top: 25px;">
            <b-col cols="2">
                <b-form-group style="z-index: 100;">
                    <b-form-file style="z-index: 100;" v-model="upload.image" accept="image/jpeg, image/png" size="md"></b-form-file>
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-button variant="primary" @click="uploadTeamImage()" style="z-index: 1000;">Upload</b-button>
            </b-col>
        </b-row>
    </b-container>
    <b-container fluid v-else-if="this.$route.params.page3 == 'members' && team.name">
        <b-row style="margin-top: 50px;">
            <b-col>
                <b-list-group>
                    <b-list-group-item v-for="(member, index) of team.members" v-bind:key="index"  class="d-flex justify-content-between align-items-center">
                        {{ member.fullname }}
                        <b-form-select @change="onGroupChange(false, member._id, $event)" v-model="member.group" :options="groups" class="col-2"></b-form-select>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
        </b-row>
        <b-row style="margin-top: 50px;">
            <b-col>
                <h5>
                    Add new user
                </h5>
            </b-col>
        </b-row>
        <b-row style="margin-top: 10px;">
            <b-col>
                <b-list-group>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                        <b-input-group size="md" class="col-3">
                            <b-form-input placeholder="User Id" v-model="newMember"></b-form-input>
                          </b-input-group>
                        <b-form-select v-if="newMember != ''" @change="onGroupChange(true, newMember, $event)" v-model="newGroup" :options="groups2" class="col-2"></b-form-select>
                        <b-form-select v-else disabled title="Enter User Id first" value="select" :options="groups3" class="col-2"></b-form-select>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
        </b-row>
    </b-container>
    <b-container fluid v-else-if="$route.params.page3 == 'groups' && team.name && !$route.params.page4">
        <b-row style="margin-top: 50px;">
            <b-col>
                <h5>
                    Groups
                </h5>
            </b-col>
        </b-row>
        <b-row style="margin-top: 15px;" v-if="groups">
            <b-col>
                <b-list-group>
                    <b-list-group-item v-for="group of team.groups" v-bind:key="group._id"  class="d-flex justify-content-between align-items-center">
                        {{ group.name }}
                        <b-button @click="deleteGroup(group._id)" variant="danger" class="col-2">
                            Delete
                        </b-button>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
        </b-row>
        <b-row style="margin-top: 50px;">
            <b-col>
                <h5>
                    Create Group
                </h5>
            </b-col>
        </b-row>
        <b-row style="margin-top: 10px;">
            <b-col>
                <b-list-group>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                        <b-input-group size="md" class="col-3">
                            <b-form-input placeholder="Group Name" v-model="newGroup"></b-form-input>
                        </b-input-group>
                        <b-button @click="createGroup()" variant="primary" v-if="newGroup != ''" class="col-2">
                            Create
                        </b-button>
                        <b-button variant="primary" v-else disabled class="col-2">
                            Create
                        </b-button>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
        </b-row>
    </b-container>
    <b-container fluid v-else="">
        <b-row style="margin-top: 25px;">
            <b-col>
                <b-alert variant="primary" show>
                    Not Finished
                </b-alert>
            </b-col>
        </b-row>
    </b-container>
</template>

<style>
    .copyLink {
        float:right;
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .copyLink:hover {
        color: #9c9c9c!important;
    }
    .productIcons {
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .productIcons:hover {
        color: #6b6b6b!important;
    }
    .serviceCard{
        transition: all .2s ease-in-out;
    }
    .serviceCard:hover {
        transform: scale(1.025);
    }
    .dropdown-toggle {
        background-color: blue !important;
        border-color: blue !important;
    }
</style>

<script>
//import networkCard from '@/components/networkCard.vue';
//import network from '@/pages/dashboard/network.vue';

export default {
    name: "Team",
    props: [
        "teams",
        "networks",
        "services",
        "disks"
    ],
    components: {},
    data: () => {
        return {
            token: "",
            team: {},
            groups: [],
            groups2: [],
            groups3: [
                {
                    value: "select",
                    text: "Enter User Id"
                }
            ],
            newMember: "",
            newGroup: null,
            upload: {
                image: null
            },
            newGroup: "",
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    //
                   // this.getIPs();
                   this.getTeam();
                } else {
                    localStorage.removeItem("user-token");
                    return window.location.replace("https://auth.stacket.net?redirect=https://stacket." + this.tld + "/dashboard&response_type=token");
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {
        getTeam() {
            let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
            this.$http.get(this.StacketConfig.api.teams + "/" + this.$route.params.page2, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    loader.hide();
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.team = response.data;
                loader.hide();

                this.groups = [];
                this.groups2 = [{ value: null, text: 'Select a group', disabled: true }]

                for(var group of this.team.groups){
                    this.groups.push({
                        value: group._id,
                        text: group.name
                    });
                    this.groups2.push({
                        value: group._id,
                        text: group.name
                    })
                }
                this.groups.push({ value: null, text: 'Remove Member' })
            }).catch(err => {
                loader.hide();
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch team!"
                })
            });
        },
        onGroupChange(isNew, member, newGroup){
            console.log(member, newGroup)
            if(isNew == false && newGroup == null){
                return this.$http.delete(this.StacketConfig.api.teams + "/" + this.$route.params.page2 + "/members/" + member,  {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        })
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Member Deleted",
                        text: response.data.messsage
                    });
                    this.getTeam();
                }).catch(err => {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not delete member!"
                    })
                });
            }
            this.$http.post(this.StacketConfig.api.teams + "/" + this.$route.params.page2 + "/members", {member: member, group: newGroup},  {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Member Updated",
                    text: response.data.messsage
                });
                if(isNew == true){
                    this.newGroup = null;
                    this.newMember = "";
                    this.getTeam();
                }
            }).catch(err => {
                if(err.body && err.body.error) return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: err.body.error
                })


                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not update member!"
                })
            });
        },
        uploadTeamImage(){
            const formData = new FormData();
            formData.append("image", this.upload.image);
            this.$http.post(this.StacketConfig.api.teams + "/" + this.$route.params.page2 + "/image", formData,  {headers: {'Content-Type': 'multipart/form-data', "authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Team image uploaded",
                    text: response.data.messsage
                });
                this.upload.image = null;
                this.getTeam();
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not upload image!"
                })
            });
        },
        createGroup() {
            if(this.newGroup == '') return;
            this.$http.post(this.StacketConfig.api.teams + "/" + this.$route.params.page2 + "/groups", { name: this.newGroup },  {headers: { "authorization": this.token }}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success",
                    text: "Created group!"
                });
                this.newGroup = "";
                this.getTeam();
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not create group!"
                })
            });
        },
        deleteGroup(id){
            this.$http.delete(this.StacketConfig.api.teams + "/" + this.$route.params.page2 + "/groups/" + id,  {headers: { "authorization": this.token }}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success",
                    text: "Group deleted!"
                });
                this.getTeam();
            }).catch(err => {
                if(err.body && err.body.error) return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: err.body.error
                })

                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not delete group!"
                })
            });  
        }
    }
}  
</script>