import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Frontpage from '@/views/frontpage.vue';
import Dashboard from '@/views/dashboard.vue';
import Guides from '@/views/guides.vue';
import Partners from '@/views/partners.vue';
import TermsOfService from '@/views/tos.vue';
import Ping from '@/pages/frontpage/ping.vue';
import Deals from '@/pages/frontpage/deals.vue';

import Minecraft from '@/pages/frontpage/minecraft.vue';
import Terraria from '@/pages/frontpage/terraria.vue';
import Factorio from '@/pages/frontpage/factorio.vue';
import CSGO from '@/pages/frontpage/csgo.vue';

import NODEJS from '@/pages/frontpage/nodejs.vue';
import Deno from '@/pages/frontpage/deno.vue';
import Python from '@/pages/frontpage/python.vue';
import NGINX from '@/pages/frontpage/nginx.vue';
import MYSQL from '@/pages/frontpage/mysql.vue';
import MONGODB from '@/pages/frontpage/mongodb.vue';

import PrivateNodes from '@/pages/frontpage/privatenodes.vue';
import VirtualDisks from '@/pages/frontpage/disks.vue';

import Status from '@/views/status.vue';

export default new Router({
    routes: [
        {
            name: 'Dashboard',
            component: Dashboard,
            path: '/dashboard/:page?/:page2?/:page3?/:page4?'
        },
        {
            name: 'Status',
            component: Status,
            path: '/status/:page?'
        },
        {
            name: 'Terms of Service',
            component: TermsOfService,
            path: '/tos/:page?'
        },
        {
            name: 'Guides',
            component: Guides,
            path: '/guides/:id?',
            meta: {
                title: "Stacket - Guides",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'Partners',
            component: Partners,
            path: '/partners',
            meta: {
                title: "Stacket - Partners",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'Minecraft Hosting',
            component: Minecraft,
            path: '/minecraft',
            meta: {
                title: "Stacket - Minecraft Hosting",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                    },
                    {
                        name: "og:url",
                        content: "https://stacket.net"
                    },
                    {
                        property: "og:title",
                        content: "Stacket - Minecraft Hosting",
                    },
                    {
                        property: "og:type",
                        content: "website",
                    },
                    {
                        property: "og:description",
                        content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'Deno Hosting',
            component: Deno,
            path: '/deno',
            meta: {
                title: "Stacket - Deno Hosting",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'Terraria Hosting',
            component: Terraria,
            path: '/terraria',
            meta: {
                title: "Stacket - Terraria Hosting",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'MySQL Hosting',
            component: MYSQL,
            path: '/mysql',
            meta: {
                title: "Stacket - MySQL Hosting",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'MongoDB Hosting',
            component: MONGODB,
            path: '/mongodb',
            meta: {
                title: "Stacket - MongoDB Hosting",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'Python Hosting',
            component: Python,
            path: '/python',
            meta: {
                title: "Stacket - Python Hosting",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'Factorio Hosting',
            component: Factorio,
            path: '/factorio',
            meta: {
                title: "Stacket - Factorio Hosting",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'CS:GO Hosting',
            component: CSGO,
            path: '/csgo',
            meta: {
                title: "Stacket - CS:GO Hosting",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'Node.js Hosting',
            component: NODEJS,
            path: '/nodejs',
            meta: {
                title: "Stacket - Node.js Hosting",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud provider. At Stacket we offer high quality production and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'Nginx Web Server Hosting',
            component: NGINX,
            path: '/nginx',
            meta: {
                title: "Stacket - Nginx Hosting",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud provider. At Stacket we offer high quality production and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'Private Nodes Hosting',
            component: PrivateNodes,
            path: '/privatenodes',
            meta: {
                title: "Stacket - Private Nodes",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud provider. At Stacket we offer high quality production and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'Virtual Disks Hosting',
            component: VirtualDisks,
            path: '/disks',
            meta: {
                title: "Stacket - Virtual Disks",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud provider. At Stacket we offer high quality production and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'Ping Tester',
            component: Ping,
            path: '/ping',
            meta: {
                title: "Stacket - Ping Tester",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud provider. At Stacket we offer high quality production and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'Deals',
            component: Deals,
            path: '/deals',
            meta: {
                title: "Stacket - Deals",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud provider. At Stacket we offer high quality production and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]
            }
        },
        {
            name: 'Home',
            component: Frontpage,
            path: '/:page?/:page2?/:page3?',
            meta: {
                title: "Stacket - Home",
                metaTags: [
                    {
                        name: "description",
                        content: "Stacket, your cloud gaming provider. At Stacket we offer high quality game and development services at low cost.",
                    },
                    {
                        name: "robots",
                        content: "index, follow",
                    }
                ]

            }
        }
    ],
    mode: 'history'
});
