<template>
    <div style="height: 100%; background-color: #f6f7fb;">
        <b-container fluid style="height: 100%;">
            <frontPageHeader></frontPageHeader>
            <b-row style="background-image: linear-gradient(#026DC9, #5AADFC); min-height: 375px; padding-top: 100px; color: white;">
                <b-col cols="2"></b-col>
                <b-col cols="5" class="mt-5">
                    <h1 class="textShadow">
                        <strong>
                            {{ Lang.partners.title }}
                        </strong>
                    </h1>
                    <h5 class="textShadow">
                        {{ Lang.partners.text }}
                    </h5>
                </b-col>
            </b-row>
            <b-row class="justify-content-center mt-5" style="color: #212529!important;">
                <b-col v-for="(partner, index) of partners" :key="index" cols="4" class="partnerCard shadow-md" style="margin-left: 25px; margin-right: 25px; margin-bottom: 50px; text-align: center; border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 40px; height: 275px;">
                    <b-link router-tag="div" :href="partner.url" target="_blank" style="text-decoration: none; color: #212529!important; ">
                        <b-row>
                            <b-col>
                                <img :src="partner.img" height=75px style="margin: auto;">
                                <hr style="width: 75%;">
                                <p>{{ partner.text }}</p>
                            </b-col>
                            <b-col>
                                <b-row>
                                    <b-col>
                                        “{{ partner.quote.text }}”
                                    </b-col>
                                </b-row>
                                <b-row style="text-align: left!important;" class="mt-3">
                                    <b-col cols="1" style="min-width: 55px;">
                                        <img style="height: 50px; border-radius: 100%;" :src="partner.quote.representative.img">
                                    </b-col>
                                    <b-col>
                                        <b>
                                            {{ partner.quote.representative.name }}
                                        </b>
                                        <p>
                                            {{ partner.quote.representative.role }}
                                        </p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-link>
                </b-col>
            </b-row>
            <frontPageFooter style="margin-top: 100px;"></frontPageFooter>
        </b-container>
    </div>
</template>

<script>
import frontPageHeader from "@/pages/frontpage/header.vue";
import frontPageFooter from "@/pages/frontpage/footer.vue";
export default {
    name: "Partners",
    components: {
        frontPageFooter,
        frontPageHeader,
    },
    data: () => {
        return {
            partners: [
                // {
                //     img: require("@/assets/images/partners/satellyte.png"),
                //     text: "Satellyte is creating a Minecraft server hosting network, built for communities.",
                //     url: "https://satellyte.net",
                //     quote: {
                //         text: "Stacket makes it easy for us to deploy servers onto our network and deliver a quality experience to our users.",
                //         representative: {
                //             name: "William Hayr",
                //             img: "https://cdn.discordapp.com/avatars/182292736790102017/a_650daaeab3f55c027cd8071d4240a5d0.gif",
                //             role: "Founder and Lead Developer at Satellyte.net"
                //         }
                //     }
                // },
                {
                    img: require("@/assets/images/partners/warmupzone.png"),
                    text: "Warmup.zone is a CS:GO community which aims for an eSports-oriented player base.",
                    url: "https://warmup.zone/",
                    quote: {
                        text: "Stacket.net gave us reliable servers for our CS:GO community. Very fast support, and a very easy to use webpanel.",
                        representative: {
                            name: "Nikolaj Danmark",
                            img: require("@/assets/images/partners/jealous.png"),
                            role: "Founder of Warmup.zone"
                        }
                    }
                },
            ],
            isLoggedIn: true,
        }
    },
    mounted() {
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.isLoggedIn = true;
                } else {
                    this.isLoggedIn = false;
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {

    }
}
</script>

<style lang="scss">
    #dashboardButton {
        a {
            color: rgb(230, 230, 230)!important;
        }
        a:hover {
            color: white!important;
        }
    }

    .textShadow {
        text-shadow: 0px 2px 10px rgba(0,0,0,.175)!important;
    }

    .partnerCard {
        transition: all .2s ease-in-out;
    }

    .partnerCard:hover {
        transform: scale(1.025);
        cursor: pointer;
    }
</style>